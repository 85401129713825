import {serializable, alias, primitive, list, object} from 'serializr';

export class ridePreferencesModel{
    @serializable(alias('id', primitive()))
    id?: string;
    @serializable(alias('user_id', primitive()))
    userId?: string;
    @serializable(alias('ride_type_id', primitive()))
    rideTypeId?: string;
    @serializable(alias('ride_type_name', primitive()))
    rideTypeName?: string;
}

export class mobilityPreferencesModel{
    @serializable(alias('id', primitive()))
    id?: string;
    @serializable(alias('user_id', primitive()))
    userId?: string;
    @serializable(alias('mobility_type_id', primitive()))
    mobilityTypeId?: string;
    @serializable(alias('mobility_type_name', primitive()))
    mobilityTypeName?: string;
}

export class foodPreferencesModel{
    @serializable(alias('id', primitive()))
    id?: string;
    @serializable(alias('user_id', primitive()))
    userId?: string;
    @serializable(alias('cuisine_id', primitive()))
    cuisineId?: string;
    @serializable(alias('cuisine_name', primitive()))
    cuisineName?: string;
}

export class User {
    @serializable(alias('id', primitive()))
    id?: string;
    @serializable(alias('email', primitive()))
    email?: string;
    @serializable(alias('uid', primitive()))
    uid?: string;
    @serializable(alias('first_name', primitive()))
    firstName?: string;
    @serializable(alias('last_name', primitive()))
    lastName?: string;
    @serializable(alias('username', primitive()))
    username?: string;
    @serializable(alias('otp', primitive()))
    otp?: string;
    @serializable(alias('password', primitive()))
    password?: string;
    @serializable(alias('password_confirmation', primitive()))
    passwordConfirmation?: string;
    @serializable(alias('bdd_id', primitive()))
    bddId?: string;
    @serializable(alias('name', primitive()))
    name?: string;
    @serializable(alias('invite_id', primitive()))
    inviteId?: string;
    @serializable(alias('number_of_plans', primitive()))
    numberOfPlans?: string;
    @serializable(alias('pro_enabled', primitive()))
    proEnabled?: string;
    @serializable(alias('height', primitive()))
    height?: string;
    @serializable(alias('ride_preferences', list(object(ridePreferencesModel))))
    ridePreferences?: Array<any>;
    @serializable(alias('cuisine_preferences', list(object(foodPreferencesModel))))
    foodPreferences?: Array<any>;
    @serializable(alias('mobility_preferences', list(object(mobilityPreferencesModel))))
    mobilityPreferences?: Array<any>;
    @serializable(alias('number_of_groups', primitive()))
    numberOfGroups?: string;
    @serializable(alias('default_location_id', primitive()))
    defaultLocationId?: string;
    @serializable(alias('default_plan_id', primitive()))
    defaultPlanId?: string;
    @serializable(alias('default_plan_name', primitive()))
    defaultPlanName?: string;
    @serializable(alias('profile_pic_url', primitive()))
    profilePicUrl?: string;
    @serializable(alias('profile_pic_thumb_url', primitive()))
    profilePicThumbUrl?: string;
}
