import React, { useEffect, useState } from "react";
import "../groups.scss";
import "./addMembersForm.scss";
import { Button, Checkbox, Slider, Upload, Drawer, Row, Col } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Formik, Form } from "formik";
import InputField from "../../../shared/components/InputField";
import DropdownField from "../../../shared/components/DropdownField";
import Notification from "../../../shared/components/Notification";
import { NotificationTypes } from "../../../enums/notificationTypes";
import GroupService from "../../../services/GroupService/group.service";
import {
  constructSelectOptions,
  fetchMetaValues,
} from "../../../shared/utils/fetchMetaValues";
import { convertJSONToFormData } from "../../../shared/utils/dataFormatConverter";
import { serialize } from "serializr";
import { GroupMemberFormModel } from "../../../models/group.model";
import { withRouter } from "react-router";
import profilePlaceholder from "../../../assets/profile-placeholder.png";
import { FC } from "react";
const PreferencesComponent: FC = () => {
  const [selectedItems, setSelectedItems] = useState<any>([]);
  return <div></div>;
};
const AddMembersForm = (props: any) => {
  const { user, handleUserUpdate, currentMember } = props;
  
  const [buttonLoader, setButtonLoader] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string>("");
  const [imageObject, setImageObject] = useState<any>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [rideTypes, setRideTypes] = useState<any>([]);
  const [rideMeta, setRideMeta] = useState<any>([]);
  const [foodTypes, setFoodTypes] = useState<any>([]);
  const [foodMeta, setFoodMeta] = useState<any>([]);
  const [mobilityTypes, setMobilityTypes] = useState<any>([]);
  const [mobilityMeta, setMobilityMeta] = useState<any>([]);
  const [isOver48, setOver48Inches] = useState(true);
  const [initialValues, setInitialValues] = useState<any>({});
  const [ridePreferences, setRidePreferences] = useState<any[]>([]);
  const [foodPreferences, setFoodPreferences] = useState<any>([]);
  const [mobilityPreferences, setMobilityPreferences] = useState<any>([]);

  const [showPreferences, setPreferencesVisibility] = useState<
    "ride" | "mobility" | "food" | boolean
  >(false);

  useEffect(() => {
    console.log(user);
    fetchMetaValues("ride_types", setRideTypes);
    fetchMetaValues("mobility_types", setMobilityTypes);
    fetchMetaValues("cuisines", setFoodTypes);
    if (user) setImageUrl(user?.profilePicUrl);
  }, [user]);

  useEffect(() => {
    constructSelectOptions(rideTypes, setRideMeta);
  }, [rideTypes]);

  useEffect(() => {
    constructSelectOptions(foodTypes, setFoodMeta);
  }, [foodTypes]);

  useEffect(() => {
    constructSelectOptions(mobilityTypes, setMobilityMeta);
  }, [mobilityTypes]);

  function getBase64(
    img: Blob,
    callback: {
      (imageUrl: any): void;
      (arg0: string | ArrayBuffer | null): any;
    }
  ) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const handleChange = (info: any) => {
    setLoading(true);
    setTimeout(() => {
      setImageObject(info.file.originFileObj);
      getBase64(info.file.originFileObj, (imageUrl: any) => {
        setLoading(false);
        setImageUrl(imageUrl);
      });
    }, 250);
  };

  const onSubmit = (values: any) => {
    setButtonLoader(true);
    if (user) {
      if (user?.userId) {
        let data = { user: serialize(GroupMemberFormModel, values) };
        data.user.profile_pic = imageObject;
        data.user.username = `${values.firstName} ${values.lastName}`;
        data.user.cuisine_ids = values?.foodPreferences
        let memberFormData = convertJSONToFormData(data);
        GroupService.updateMember(
          memberFormData,
          props?.groupId,
          user?.userId,

          () => {
            setButtonLoader(false);
            props.handleSuccess();
          },
          () => {
            setButtonLoader(false);
            Notification({
              message: "Error",
              description: "Unable to update the member",
              type: NotificationTypes.ERROR,
            });
          },
          () => {}
        ).then();
      } else {
        handleUserUpdate(values, setButtonLoader, imageObject);
      }
    } else {
      setButtonLoader(true);
      let data = { group_member: serialize(GroupMemberFormModel, values) };
      data.group_member.profile_pic = imageObject;
      data.group_member.username = `${values.firstName} ${values.lastName}`;
      let memberFormData = convertJSONToFormData(data);
      GroupService.addMember(
        memberFormData,
        props?.groupId,
        () => {
          setButtonLoader(false);
          props.handleSuccess();
        },
        () => {
          setButtonLoader(false);
          Notification({
            message: "Error",
            description: "Unable to add the member",
            type: NotificationTypes.ERROR,
          });
        },
        () => {}
      ).then();
    }
  };

  useEffect(() => {
    setInitialValues({
      firstName: user?.username?.split(" ")[0] || "",
      lastName:
        user?.username
          ?.substring(user?.username?.split(" ")[0].length)
          .trim() || "",
      height: user?.userHeight || 48,
      ridePreferences:
        user?.ridePreferences?.map((obj: any) => obj.rideTypeId) || [],
      foodPreferences:
        user?.foodPreferences?.map((obj: any) => obj.cuisineId) || [],
      mobilityPreferences:
        user?.mobilityPreferences?.map((obj: any) => obj.mobilityTypeId) || [],
      sendInvite: false,
      email: "",
    });
    setRidePreferences(
      user?.ridePreferences?.map((obj: any) => obj.rideTypeId) || []
    );
    setFoodPreferences(
      user?.foodPreferences?.map((obj: any) => obj.cuisineId) || []
    );
    setMobilityPreferences(
      user?.mobilityPreferences?.map((obj: any) => obj.mobilityTypeId) || []
    );
    if (isOver48) {
      setOver48Inches(
        user?.userHeight ? (user?.userHeight > 48 ? true : false) : true
      );
    }
  }, [user]);

  const uploadButton = (
    <div>
      {/* {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div className="ant-upload-text">Upload</div> */}
      <img
        src={profilePlaceholder}
        alt=""
        className="register__profile-placeholder"
      />
    </div>
  );

  const retakeButton = (
    <Button
      type="primary"
      key="upload"
      className="upload-button"
      onClick={() => setImageUrl("")}
    >
      Remove photo
    </Button>
  );

  return (
    <div className="member-form">
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ setFieldValue, values }) => {
          return (
            <>
              <Drawer
                title="Select Ride Preferences"
                className="preferences__drawer"
                placement="right"
                closable={false}
                visible={showPreferences == "ride"}
              >
                <div className="preferences__wrapper">
                  <Row gutter={[16, 16]}>
                    {rideMeta?.map((value: any, index: number) => (
                      <Col span={8}>
                        <div
                          className={`preferences__card ${
                            ridePreferences?.includes(value?.value) &&
                            "preferences-card__active"
                          }`}
                          onClick={() => {
                            if (ridePreferences?.includes(value?.value)) {
                              setRidePreferences((preferences: any) =>
                                preferences?.filter(
                                  (data: any) => data !== value?.value
                                )
                              );
                            } else {
                              let preferences = ridePreferences;
                              preferences?.push(value?.value);
                              setRidePreferences([...preferences]);
                            }
                          }}
                        >
                          <img src={value?.icon} />
                          <p>{value?.label}</p>
                        </div>
                      </Col>
                    ))}
                  </Row>
                  <Checkbox
                    className="select-all-rides__checkbox"
                    checked={ridePreferences.length == rideMeta?.length}
                    onChange={(e) => {
                      if (e.target.checked) {
                        let options = rideMeta.map(
                          (ride: any, index: number) => ride?.value
                        );
                        setRidePreferences(options);
                      } else {
                        setRidePreferences([]);
                      }
                    }}
                  >
                    Select all rides
                  </Checkbox>
                </div>
                <div className="preferences-btn__container">
                  <Button
                    className="preferences__save"
                    onClick={() => {
                      setFieldValue("ridePreferences", ridePreferences);
                      setPreferencesVisibility(false);
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    className="preferences__cancel"
                    onClick={() => {
                      setRidePreferences(values?.ridePreferences);
                      setPreferencesVisibility(false);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </Drawer>

              <Drawer
                title="Select Food Preferences"
                className="preferences__drawer"
                placement="right"
                closable={false}
                visible={showPreferences == "food"}
              >
                <div className="preferences__wrapper">
                  <Row gutter={[16, 16]}>
                    {foodMeta?.map((value: any, index: number) => (
                      <Col span={8}>
                        <div
                          className={`preferences__card ${
                            foodPreferences?.includes(value?.value) &&
                            "preferences-card__active"
                          }`}
                          onClick={() => {
                            if (foodPreferences?.includes(value?.value)) {
                              setFoodPreferences((preferences: any) =>
                                preferences?.filter(
                                  (data: any) => data !== value?.value
                                )
                              );
                            } else {
                              let preferences = foodPreferences;
                              preferences?.push(value?.value);
                              setFoodPreferences([...preferences]);
                            }
                          }}
                        >
                          <img src={value?.icon} />
                          <p>{value?.label}</p>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </div>
                <div className="preferences-btn__container">
                  <Button
                    className="preferences__save"
                    onClick={() => {
                      setFieldValue("foodPreferences", foodPreferences);
                      setPreferencesVisibility(false);
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    className="preferences__cancel"
                    onClick={() => {
                      setFoodPreferences(values?.foodPreferences);
                      setPreferencesVisibility(false);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </Drawer>
              <Drawer
                title="Select Mobility Preferences"
                className="preferences__drawer"
                placement="right"
                closable={false}
                visible={showPreferences == "mobility"}
              >
                <div className="preferences__wrapper">
                  <Row gutter={[16, 16]}>
                    {mobilityMeta?.map((value: any, index: number) => (
                      <Col span={24}>
                        <Checkbox
                          checked={mobilityPreferences?.includes(value?.value)}
                          onClick={(e) => {
                            if (mobilityPreferences?.includes(value?.value)) {
                              setMobilityPreferences((preferences: any) =>
                                preferences?.filter(
                                  (data: any) => data !== value?.value
                                )
                              );
                            } else {
                              let preferences = mobilityPreferences;
                              preferences?.push(value?.value);
                              setMobilityPreferences([...preferences]);
                            }
                          }}
                        >
                          {value?.label}
                        </Checkbox>
                      </Col>
                    ))}
                  </Row>
                </div>
                <div className="preferences-btn__container">
                  <Button
                    className="preferences__save"
                    onClick={() => {
                      setFieldValue("mobilityPreferences", mobilityPreferences);
                      setPreferencesVisibility(false);
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    className="preferences__cancel"
                    onClick={() => {
                      setMobilityPreferences(values?.mobilityPreferences);
                      setPreferencesVisibility(false);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </Drawer>
              <Form>
                <div className="member-form__title">
                  {user ? "Update" : "Add"}{" "}{user?.userId ? "member to your group" : "profile"}
                </div>
                {!imageUrl && (
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    onChange={handleChange}
                    accept="image/png,image/jpeg"
                    multiple={false}
                  >
                    {uploadButton}
                  </Upload>
                )}
                {imageUrl && (
                  <>
                    <img
                      src={imageUrl}
                      alt="avatar"
                      className="attached-image"
                    />
                    <div>{retakeButton}</div>
                  </>
                )}
                <div className="member-form__field">
                  <div className="label">First Name</div>
                  <InputField
                    type="text"
                    name="firstName"
                    placeholder="Enter first name"
                  />
                </div>
                <div className="member-form__field">
                  <div className="label">Ride preferences</div>
                  <DropdownField
                    open={false}
                    mode="multiple"
                    onFocus={() => {
                      setPreferencesVisibility("ride");
                    }}
                    name="ridePreferences"
                    options={rideMeta || []}
                    placeholder="select ride preferences"
                    onChange={(value: any) => {
                      setRidePreferences(value);
                      setFieldValue("ridePreferences", value);
                    }}
                  />
                </div>
                <div className="member-form__field">
                  <div className="label">Last Name</div>
                  <InputField
                    type="text"
                    name="lastName"
                    placeholder="Enter last name"
                  />
                </div>
                <div className="member-form__field">
                  <div className="label">Food preferences</div>

                  <DropdownField
                    onFocus={() => {
                      setPreferencesVisibility("food");
                    }}
                    open={false}
                    mode="multiple"
                    name="foodPreferences"
                    options={foodMeta || []}
                    placeholder="select food preferences"
                    onChange={(value: any) => {
                      setFoodPreferences(value);
                      setFieldValue("foodPreferences", value);
                    }}
                  />
                </div>

                <div></div>

                <div className="member-form__field height-field">
                  <Checkbox
                    name="sendInvite"
                    className="member-form__checkbox"
                    checked={isOver48}
                    onChange={(e) => {
                      setOver48Inches(e.target.checked);
                      setFieldValue("height", 48);
                    }}
                  >
                    Over 48 inches
                  </Checkbox>
                  {!isOver48 && (
                    <>
                      <div className="label">
                        Height in Inches (Min: 4, Max: 48)
                      </div>
                      <Slider
                        min={4}
                        max={48}
                        value={values?.height}
                        onChange={(value: any) =>
                          setFieldValue("height", value > 48 ? 48 : value)
                        }
                        className="height-slider"
                        step={1}
                      />
                      <InputField
                        type="number"
                        name="height"
                        placeholder="Enter height"
                        className="height-input"
                        onChange={(e: any) =>
                          setFieldValue(
                            "height",
                            e.target.value > 48
                              ? 48
                              : e.target.value < 4
                              ? 4
                              : Math.floor(e.target.value)
                          )
                        }
                      />
                    </>
                  )}
                </div>

                <div className="member-form__field">
                  <div className="label">Mobility preferences</div>
                  <DropdownField
                    onFocus={() => setPreferencesVisibility("mobility")}
                    open={false}
                    mode="multiple"
                    name="mobilityPreferences"
                    options={mobilityMeta || []}
                    placeholder="select mobility preferences"
                    onChange={(value: any) => {
                      setMobilityPreferences(value);
                      setFieldValue("mobilityPreferences", value);
                    }}
                  />
                </div>

                {!user && (
                  <div>
                    <Checkbox
                      name="sendInvite"
                      className="member-form__checkbox"
                      onChange={(e) => {
                        setFieldValue("sendInvite", e.target.checked);
                      }}
                    >
                      Send Invite
                    </Checkbox>
                  </div>
                )}
                {values.sendInvite && (
                  <div className="member-form__field">
                    <div className="label">Email</div>
                    <InputField
                      type="email"
                      name="email"
                      placeholder="Enter email"
                    />
                  </div>
                )}
                <div className="member-form__actions">
                  <Button
                    type="text"
                    key="cancel"
                    className="member-form__submit"
                    onClick={
                      user
                        ? () => props?.history.goBack()
                        : () => props.setShowForm(false)
                    }
                  >
                    Cancel
                  </Button>
                  <Button
                    htmlType="submit"
                    type="primary"
                    className="member-form__submit"
                    loading={buttonLoader}
                  >
                    Save
                  </Button>
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default withRouter(AddMembersForm);
