import axiosInstance from "../../interceptor/axiosInstance";
import {
    PARKS,
    RESTAURANTS,
} from "../../routes/routeConstants/apiRoutes";
import { deserialize } from "serializr";
import {RestaurantDetailsModel, RestaurantsListModel} from "../../models/restaurants.model";

export default class RestaurantService {

    //fetch restaurants
    static fetchRestaurants(
        locationId: string,
        filterQuery:string,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        let URL = filterQuery ? RESTAURANTS+`?location_id=${locationId}&preferred=false${filterQuery}`:
            RESTAURANTS+`?location_id=${locationId}&preferred=true`;

        return axiosInstance
            .get(URL)
            .then(response => {
                let restaurants = deserialize(RestaurantsListModel,response.data['restaurants']);
                onSuccess(restaurants);
            })
            .catch(error => {
                onError(error);
            })
            .finally(onFinal)
    }

    //fetch parks
    static fetchParks(
        locationId:string,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .get(PARKS+`?location_id=${locationId}`)
            .then(response => {
                onSuccess(response?.data['parks'] || []);
            })
            .catch(error => {
                onError(error);
            })
            .finally(onFinal)
    }

    //fetch park restaurants
    static fetchParkRestaurants(
        parkId: string,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .get(RESTAURANTS+`?park_id=${parkId}`)
            .then(response => {
                let restaurants = deserialize(RestaurantsListModel,response.data['restaurants']);
                onSuccess(restaurants);
            })
            .catch(error => {
                onError(error);
            })
            .finally(onFinal)
    }

    //fetch restaurant details
    static fetchRestaurantDetails(
        restaurantId: string,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .get(RESTAURANTS + `/${restaurantId}`)
            .then(response => {
                let restaurant = deserialize(RestaurantDetailsModel,response.data['restaurant']);
                onSuccess(restaurant);
            })
            .catch(error => {
                onError(error);
            })
            .finally(onFinal)
    }
}
