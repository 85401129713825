import React, {useEffect} from "react";
import './section1.scss';
import {Button} from "antd";
import section1Image from "../../../assets/landing-page-assets/section1.png";
import googleBadge from "../../../assets/landing-page-assets/google-play-badge.png";
import appleBadge from "../../../assets/landing-page-assets/apple-app-store.png";
import section1Icon1 from "../../../assets/landing-page-assets/section1-icon1.png";
import section1Icon2 from "../../../assets/landing-page-assets/section1-icon2.png";
import section1Icon3 from "../../../assets/landing-page-assets/section1-icon3.png";
import section1Icon4 from "../../../assets/landing-page-assets/section1-icon4.png";
import section1Icon5 from "../../../assets/landing-page-assets/section1-icon5.png";
import section1Icon6 from "../../../assets/landing-page-assets/section1-icon6.png";
import section1Icon7 from "../../../assets/landing-page-assets/section1-icon7.png";
import section1Icon8 from "../../../assets/landing-page-assets/section1-icon8.png";
import bddLogo from "../../../assets/bdd-logo.png";
import mobileLanding from "../../../assets/landing-page-assets/mobile/mobile-landing.png";
import * as appRoutes from "../../../routes/routeConstants/appRoutes";
import {withRouter} from "react-router-dom";

const Section1 = (props:any) => {

    const getOS= () => {
        let userAgent = window.navigator.userAgent,
            platform = window.navigator.platform,
            iosPlatforms = ['iPhone', 'iPad', 'iPod'],
            os = null;

        if (iosPlatforms.indexOf(platform) !== -1) {
            os = 'iOS';
        } else if (/Android/.test(userAgent)) {
            os = 'Android';
        }

        return os;
    }


    const {scrollRef} = props;

    return(
        <div className='landing__section-1' ref={scrollRef}>
            <div className='landing__section-1__mobile-logo'>
                <img src={bddLogo} alt=''/>
            </div>
            <div className='landing__section-1__title'>Build your Best Disney Day!</div>
            <div className='landing__section-1__tag'>Make your trip to Disney even more magical! We help create custom plans based on your preferences that maximize your fun!</div>
            <div className='landing__section-1__action'>
                <Button type='text' className='landing__section-1__get-started'
                 onClick={()=>props.history.push(appRoutes.SIGNUP)}
                        >
                    SIGN UP AND GET PLANNING
                </Button>
                {/* <Button type='primary' className='landing__section-1__explore'>
                    EXPLORE BEST DISNEY DAY
                </Button> */}
            </div>
            <div className='landing__section-1__action__mobile'>
                <Button type='text'
                        className='landing__section-1__get-started'
                        href='https://bestdisneyday.page.link/tobR'
                >
                    Download the App
                </Button>
            </div>
            <div className='landing__section-1__image'>
                <img src={section1Image} alt=''/>
            </div>
            <div className='landing__section-1__image__mobile'>
                <img src={mobileLanding} alt=''/>
            </div>
            <div className='landing__section-1__app-stores'>
                <a href="https://play.google.com/store/apps/details?id=com.bestdisneyday.mybdd&hl=en_US&gl=US" target="_blank">
                    <img src={googleBadge} alt='google-' className='landing__section-1__google-badge'/>
                </a>
                <a href="https://apps.apple.com/us/app/my-bdd/id1538454574" target="_blank">
                    <img src={appleBadge} alt='apple-' className='landing__section-1__apple-badge'/>
                </a>
            </div>
            <img src={section1Icon1} alt='' className='landing__section-1__icons landing__section-1__icon1'/>
            <img src={section1Icon2} alt='' className='landing__section-1__icons landing__section-1__icon2'/>
            <img src={section1Icon3} alt='' className='landing__section-1__icons landing__section-1__icon3'/>
            <img src={section1Icon4} alt='' className='landing__section-1__icons landing__section-1__icon4'/>
            <img src={section1Icon5} alt='' className='landing__section-1__icons landing__section-1__icon5'/>
            <img src={section1Icon6} alt='' className='landing__section-1__icons landing__section-1__icon6'/>
            <img src={section1Icon7} alt='' className='landing__section-1__icons landing__section-1__icon7'/>
            <img src={section1Icon8} alt='' className='landing__section-1__icons landing__section-1__icon8'/>
        </div>
    )
}

export default withRouter(Section1);
