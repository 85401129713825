import React, {useEffect} from "react";
import {AppleFilled, FacebookFilled, GoogleCircleFilled} from "@ant-design/icons";
import FacebookLogin from "react-facebook-login";
import GoogleLogin from "react-google-login";
import UserService from "../../../services/AuthService/auth.service";
import * as appRoutes from "../../../routes/routeConstants/appRoutes";
import Notification from "../../../shared/components/Notification";
import {NotificationTypes} from "../../../enums/notificationTypes";
import {withRouter} from 'react-router-dom';
import scriptJs from "scriptjs";

const SocialLogin = (props:any) => {

    useEffect(()=>{
        scriptJs.get('https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js', () => {
            const params = {
                clientId: 'com.bestdisneyday.mybdd.applesignin',
                redirectURI: 'https://app.bestdisneyday.com/auth/login',
                scope: 'name email',
                usePopup: true,
                state: 'secret-test',
            };
            // @ts-ignore
            window['AppleID'].auth.init(params);
        });
    },[])

    const handleAppleOauth = () =>{
        // @ts-ignore
        window['AppleID'].auth.signIn().then((response:any)=>{
            if(response.authorization){
                let data = {
                    user: {
                        'id_token': response.authorization.id_token || '',
                        'first_name': response.user?.name?.firstName || '',
                        'last_name': response.user?.name?.lastName || ''
                    }
                };

                localStorage.removeItem('user');

                UserService.appleOauth(data,
                    (user: any) => {
                        if (user?.defaultLocationId) {
                            props.history.push(appRoutes.HOME);
                        } else {
                            props.history.push(appRoutes.REGISTER);
                        }
                    },
                    () => {
                    },
                    () => {
                    },
                ).then()
            }
        }).catch((err:any)=>{
            Notification({message:'Failure',description:err.error,type:NotificationTypes.ERROR})
        })
    }

    const responseFacebook = (response: any) => {
        if (response?.accessToken) {
            let data = {user: {'access_token': response.accessToken}};

            localStorage.removeItem('user');
            UserService.fbOauth(data,
                (user: any) => {
                    if (user?.defaultLocationId) {
                        props.history.push(appRoutes.HOME);
                    } else {
                        props.history.push(appRoutes.REGISTER);
                    }
                },
                () => {
                },
                () => {
                },
            ).then()
        }
    }

    const responseGoogle = (response: any) => {
        if (response?.error === 'popup_closed_by_user') {
            Notification({
                message: "Popup blocked",
                description: "Please allow popups to continue",
                type: NotificationTypes.ERROR
            });
            return;
        }

        if (response?.accessToken) {
            let data = {user: {'access_token': response.accessToken}};

            localStorage.removeItem('user');
            UserService.googleOauth(data,
                (user: any) => {
                    if (user?.defaultLocationId) {
                        props.history.push(appRoutes.HOME);
                    } else {
                        props.history.push(appRoutes.REGISTER);
                    }
                },
                () => {
                },
                () => {
                },
            ).then()
        }
    }

    return(
        <>
            <div className='login-split-text'>or</div>
            <div className='social-login'>
                <AppleFilled onClick={handleAppleOauth}/>
                <FacebookLogin
                    appId="3431317413600158"
                    autoLoad={false}
                    callback={responseFacebook}
                    scope="public_profile,email"
                    textButton=''
                    icon={<FacebookFilled className='facebook-icon'/>}
                />
                <GoogleLogin
                    clientId="399273321301-t1lo2f7comvije6sqqufoclp94v9lu35.apps.googleusercontent.com"
                    render={renderProps =>
                        <GoogleCircleFilled className='google-icon'
                                            onClick={renderProps.onClick} disabled={renderProps.disabled}/>
                    }
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                    autoLoad={false}
                />
            </div>
        </>
    )
}

export default withRouter(SocialLogin);
