import { Button, Checkbox, Col, Collapse, Drawer, Row } from "antd";
import React, { FC } from "react";
import { useState } from "react";
import { generatePath, useHistory } from "react-router-dom";
import { ChecklistModel } from "../../../models/checklist.model";
import { CHECKLIST_DETAILS } from "../../../routes/routeConstants/appRoutes";
import ChecklistService from "../../../services/ChecklistService/checklist.service";
import "./existingChecklist.scss";
import RadioIcon from "../../../assets/radio.png";
const { Panel } = Collapse;
interface ExistingChecklistProps {
  name: string;
  planId?: any;
  visible: boolean;
  checklists: ChecklistModel[];
  refreshList: () => void;
  cancelHandler: () => void;
  successHandler: () => void;
}
export const ExistingChecklist: FC<ExistingChecklistProps> = ({
  visible,
  checklists,
  cancelHandler,
  successHandler,
  name,
  refreshList,
  planId,
}) => {
  const [selectedSub, setSelectedSub] = useState<any[]>([]);
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const history = useHistory();
  const handleSubmit = () => {
    setButtonLoading(true);
    let data: any = {
      checklist: {
        name: name,
        sub_heading_ids: selectedSub,
        item_ids: selectedItems,
      },
    };
    if (planId) data["checklist"]["planId"] = planId;

    ChecklistService.createChecklist(
      data,
      (response: ChecklistModel) => {
        refreshList();
        successHandler();
        history.push(
          generatePath(CHECKLIST_DETAILS, {
            id: response?.id,
          })
        );
      },
      () => {},
      () => {
        setButtonLoading(false);
      }
    ).then();
  };
  return (
    <Drawer
      visible={visible}
      title="Select from Existing Checklist"
      className="checklist__drawer"
      placement="right"
      closable={false}
    >
      <div className="checklist-drawer__content">
        {checklists?.map((checklist, index) => {
          if (checklist?.subHeadings?.length) {
            return (
              <Collapse
                bordered={false}
                key={index}
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <img src={RadioIcon} className="radio__icon" />
                  ) : (
                    <div className="radio-icon__inactive" />
                  )
                }
                className="site-collapse-custom-collapse"
                ghost
              >
                <Panel
                  disabled={!checklist?.subHeadings?.length}
                  className="checklist__panel"
                  header={checklist?.name}
                  key={index}
                >
                  {/*checklist*/}
                  {checklist?.subHeadings?.length ? (
                    <Row className="panel__wrapper">
                      {checklist?.subHeadings?.map((sub, index) => {
                        return (
                          <Col span={8}>
                            <Collapse
                              ghost
                              expandIcon={({ isActive }) => (
                                <Checkbox
                                  checked={selectedSub.includes(sub?.id)}
                                  onClick={(e) => e.stopPropagation()}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setSelectedSub((selectedSub) => [
                                        ...selectedSub,
                                        sub?.id,
                                      ]);
                                    } else {
                                      setSelectedSub((selectedSub) =>
                                        selectedSub.filter(
                                          (id) => id !== sub?.id
                                        )
                                      );
                                    }
                                  }}
                                />
                              )}
                            >
                              <Panel
                                key={index}
                                header={sub?.name}
                                className="subheading__panel"
                              >
                                {" "}
                                {/*subheading */}
                                {sub?.items?.length ? (
                                  <Collapse ghost>
                                    {sub?.items?.map((item, index) => {
                                      return (
                                        <Checkbox
                                          className="item__checkbox"
                                          checked={selectedItems.includes(
                                            item?.id
                                          )}
                                          onChange={(e) => {
                                            if (e.target.checked) {
                                              setSelectedItems(
                                                (selectedItems) => [
                                                  ...selectedItems,
                                                  item?.id,
                                                ]
                                              );
                                              if (
                                                !selectedSub.includes(sub?.id)
                                              ) {
                                                setSelectedSub(
                                                  (selectedSub) => [
                                                    ...selectedSub,
                                                    sub?.id,
                                                  ]
                                                );
                                              }
                                            } else {
                                              setSelectedItems(
                                                (selectedItems) =>
                                                  selectedItems.filter(
                                                    (id) => id !== item?.id
                                                  )
                                              );
                                            }
                                          }}
                                        >
                                          {item?.name}
                                        </Checkbox>
                                      );
                                    })}
                                  </Collapse>
                                ) : (
                                  ""
                                )}
                              </Panel>
                            </Collapse>
                          </Col>
                        );
                      })}
                    </Row>
                  ) : (
                    ""
                  )}
                </Panel>
              </Collapse>
            );
          }
        })}
      </div>
      <div className="existing-checklist-btn__container">
        <Button
          className="existing-checklist__save"
          onClick={() => {
            handleSubmit();
          }}
          loading={buttonLoading}
        >
          Save
        </Button>
        <Button
          className="existing-checklist__cancel"
          onClick={() => {
            cancelHandler();
          }}
        >
          Cancel
        </Button>
      </div>
    </Drawer>
  );
};
