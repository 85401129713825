import React, { useEffect, useState } from "react";
import { FC } from "react";
import { Modal, Button, Row, Col, DatePicker, Slider, Rate } from "antd";
import { CloseCircleFilled, CalendarFilled } from "@ant-design/icons";
import "./rideTrackerForm.scss";
import InputField from "../../../shared/components/InputField";
import DropdownField from "../../../shared/components/DropdownField";
import { Formik, Form } from "formik";
import {
  constructSelectOptions,
  fetchMetaValues,
} from "../../../shared/utils/fetchMetaValues";
import CommonService from "../../../services/CommonService/common.service";
import RestaurantService from "../../../services/RestaurantService/restaurant.service";
import { MetaModel } from "../../../models/common.model";
import { deserialize } from "serializr";
import RideService from "../../../services/RideService/ride.service";
import { RideMetaModel } from "../../../models/rides.model";
import RideTrackerService from "../../../services/RideTrackerService/rideTracker.service";
import Notification from "../../../shared/components/Notification";
import { NotificationTypes } from "../../../enums/notificationTypes";

interface RideTrackerFormProps {
  visible: boolean;
  closeHandler: () => void;
  refreshList: () => void;
}
export const RideTrackerForm: FC<RideTrackerFormProps> = ({
  visible,
  closeHandler,
  refreshList,
}) => {
  const [initialValues, setInitialValues] = useState<any>({});
  const [rides, setRides] = useState<any[]>([]);
  const [parks, setParks] = useState<any[]>([]);
  const [locations, setLocations] = useState<any[]>([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const onSubmit = (values: any) => {
    if (values?.rideId && values?.noOfTimes && values?.rating && values?.date) {
      const user = JSON.parse(localStorage.getItem("user") || "");
      setButtonLoading(true);
      let payload = {
        actual: {
          attraction_id: values?.rideId,
          attraction_type: "Ride",
          no_of_times: values?.noOfTimes,
          rating: values?.rating,
          date: values?.date,
          user_ids: [user?.userDetails?.id],
        },
      };
      RideTrackerService.createRide(
        payload,
        () => {
          refreshList();
          closeHandler();
        },
        () => {},
        () => {
          setButtonLoading(false);
        }
      );
    } else {
      Notification({
        type: NotificationTypes.WARNING,
        message: "Unable to submit",
        description: "All fields are required",
      });
    }
  };
  const fetchLocations = () => {
    CommonService.fetchMeta(
      "locations",
      (locations: any) => {
        constructSelectOptions(locations, setLocations);
      },
      () => {},
      () => {}
    );
  };
  const fetchParks = (locationId: any) => {
    RestaurantService.fetchParks(
      locationId,
      (parks: any) => {
        let response = deserialize(MetaModel, parks);
        constructSelectOptions(response, setParks);
      },
      () => {},
      () => {}
    );
  };
  const fetchRides = (locationId: any, parkId: any) => {
    RideService.fetchFilteredRides(
      locationId,
      `&park_id=${parkId}`,
      (rides: any) => {
        console.log(rides);
        let response: any = deserialize(RideMetaModel, rides);
        setRides(response);
      },
      () => {},
      () => {}
    );
  };
  useEffect(() => {
    fetchLocations();
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ setFieldValue, values, resetForm }) => {
        return (
          <Modal
            visible={visible}
            title={null}
            footer={null}
            centered
            closable={false}
            className="ride-tracker-form__container"
          >
            <Form>
              <div className="ride-tracker-form__content">
                <div className="ride-tracker-form__title">New Ride</div>

                <Row gutter={24}>
                  <Col span={12}>
                    <div className="ride-tracker-form__field">
                      <div className="label">Location</div>
                      <DropdownField
                        name="locationId"
                        options={locations}
                        placeholder="Select"
                        onChange={(value: any) => {
                          setFieldValue("locationId", value);
                          fetchParks(value);
                        }}
                      />
                    </div>
                  </Col>

                  <Col span={12}>
                    <div className="ride-tracker-form__field">
                      <div className="label">Park</div>
                      <DropdownField
                        name="parkId"
                        options={parks}
                        placeholder="Select"
                        onChange={(value: any) => {
                          setFieldValue("parkId", value);
                          fetchRides(values?.locationId, value);
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={12}>
                    <div className="ride-tracker-form__field">
                      <div className="label">Ride</div>
                      <DropdownField
                        name="rideId"
                        options={rides}
                        placeholder="Search"
                        onChange={(value: any) => {
                          setFieldValue("rideId", value);
                        }}
                      />
                    </div>
                  </Col>

                  <Col span={12}>
                    <div className="ride-tracker-form__field">
                      <div className="label">Date</div>

                      <DatePicker
                        name="date"
                        onChange={(date, dateString) => {
                          setFieldValue("date", dateString);
                        }}
                        className="ride-form__picker"
                        format={"MM-DD-YYYY"}
                        suffixIcon={<CalendarFilled />}
                      />
                    </div>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={12}>
                    <div className="ride-tracker-form__field">
                      <div className="label">
                        How many times did you taken this ride?
                      </div>
                      <Slider
                        min={1}
                        max={10}
                        value={values?.noOfTimes}
                        onChange={(value: any) =>
                          setFieldValue("noOfTimes", value)
                        }
                        className="no-of-times__slider"
                        step={1}
                      />
                    </div>
                  </Col>

                  <Col span={12}>
                    <div className="ride-tracker-form__field">
                      <div className="label">Rate this ride</div>
                      <Rate
                        className="ride-tracker__rating"
                        value={values?.rating}
                        key={1}
                        onChange={(value) => setFieldValue("rating", value)}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="ride-tracker-form__actions">
                <Button
                  type="text"
                  key="cancel"
                  className="ride-tracker-form__cancel"
                  onClick={() => {
                    resetForm(initialValues);
                    closeHandler();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  htmlType="submit"
                  type="primary"
                  className="ride-tracker-form__submit"
                  loading={buttonLoading}
                >
                  Submit
                </Button>
              </div>

              <CloseCircleFilled
                className="ride-tracker-form__close"
                onClick={closeHandler}
              />
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};
