import {Button, Modal} from "antd";
import React from "react";

interface DeleteConfirmationProps {
    deleteVisible: boolean;
    setDeleteVisible: Function;
    buttonLoading: boolean;
    text: string;
    handleDelete: Function;
}


export const DeleteConfirmation = (props:DeleteConfirmationProps) =>{

    const {deleteVisible,setDeleteVisible,buttonLoading,text,handleDelete } = props;

    return(
        <Modal
            title="Delete confirmation"
            centered
            onCancel={()=>setDeleteVisible(false)}
            visible={deleteVisible}
            footer={null}
            className='delete-modal'
        >
            <div className='delete-modal__text'>Are you sure you want to delete this {text}?</div>

            <div className="delete-modal__actions">
                <Button key="back" type="text" onClick={() => {setDeleteVisible(false)}}>Cancel</Button>
                <Button key="delete" type="primary" onClick={()=>handleDelete()}
                 loading={buttonLoading}>Delete</Button>
            </div>
        </Modal>
    )
}
