import React, {useEffect, useState} from "react";
import './groups.scss';
import {Button, Input, Modal, Tabs} from "antd";
import {CloseCircleFilled} from "@ant-design/icons";
import GroupService from "../../services/GroupService/group.service";
import InvitesReceived from "./InvitesReceived";
import InvitesSent from "./InvitesSent";
import GroupsList from "./GroupsList";

const {TabPane} = Tabs;

const Groups = () => {

    const [groups,setGroups] = useState<Array<any>>([]);
    const [showModal,setShowModal] = useState<boolean>(false);
    const [newGroupName,setNewGroupName] = useState<string>('');
    const [buttonLoading,setButtonLoading] = useState<boolean>(false);
    const [loading,setLoading] = useState<boolean>(false);

    useEffect(()=>{
        getGroupList();
    },[]);

    const getGroupList = () =>{
        setLoading(true)
        GroupService.fetchGroups(
            (groups: React.SetStateAction<any[]>)=>{
                setGroups(groups)
            },
            (e: any)=>{console.log(e)},
            ()=>{
                setLoading(false)
            },
        ).then()
    }

    const createNewGroup = () =>{
        setButtonLoading(true)
        let data = {group:{name:newGroupName}};

        GroupService.createGroup(data,
            ()=>{
                setButtonLoading(false)
                getGroupList();
                setShowModal(false)
                setNewGroupName('')
            },
            ()=>{
                setButtonLoading(false)
            },
            ()=>{},
        ).then()
    }

    const newGroupModal = (
        <Modal
            title={null}
            visible={showModal}
            footer={null}
            closable={false}
            className='new-group-modal'
        >
            <div className='new-group-modal__title'>New Group</div>
            <div className='label'>Name</div>
            <Input placeholder='Group name' type='text' className='new-group-modal__input' value={newGroupName}
                onChange={(event)=>{setNewGroupName(event.target.value)}}/>
            <Button key='save' type='primary' className='new-group-modal__action' disabled={!newGroupName}
                loading={buttonLoading} onClick={createNewGroup}>Save</Button>
            <CloseCircleFilled className='new-group-modal__close' onClick={()=>setShowModal(false)}/>
        </Modal>
    );

    return (
        <>
            <div className='user-component'>
                {newGroupModal}
                <div className='landing-card'>
                    <div className='landing-card__content'>
                        <div className='landing-card__content-title'>My Groups</div>
                        <div className='landing-card__content-details'>
                            Here you can create and manage groups of park goers. You will be able to create a new Group of park goers, see invitations you have received to join other groups, and view and manage invitations sent for groups you have created.
                        </div>
                    </div>
                    <div className='landing-card__action'>
                        <Button type='primary' key='add' className='landing-card__action--button'
                            onClick={()=>setShowModal(true)}>+ Add New</Button>
                    </div>
                </div>

                <div className='manage-groups'>
                    <Tabs defaultActiveKey="1" tabBarGutter={15}>
                        <TabPane tab="All Groups" key="1">
                            <GroupsList groups={groups} loading={loading} refreshList={getGroupList}/>
                        </TabPane>
                        <TabPane tab="Invites Received" key="2">
                            <InvitesReceived getGroupList={getGroupList}/>
                        </TabPane>
                        <TabPane tab="Invites Sent" key="3">
                            <InvitesSent/>
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        </>
    )
}

export default Groups;
