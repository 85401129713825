import {Form, Formik} from "formik";
import {forgotPasswordValidation} from "../LoginForm/LoginValidation";
import InputField from "../../../shared/components/InputField";
import {Button} from "antd";
import React, {useState} from "react";
import {withRouter} from 'react-router-dom'
import UserService from "../../../services/AuthService/auth.service";
import * as appRoutes from '../../../routes/routeConstants/appRoutes';

const forgotInitialValue = {
    email: "",
}

const ForgotPassword = (props:any) =>{

    const [loginLoader, setLoginLoader] = useState<boolean>(false);

    const onForgotSubmit = async(values: { email: Object; })=>{
        setLoginLoader(true)
        let data = {user: values}
        await UserService.submitEmailForOtp(data,
            ()=>{
                setLoginLoader(false)
                props.history.push(appRoutes.RESET_PASSWORD,{email:data?.user?.email})
            },
            ()=>{setLoginLoader(false)},
            ()=>{setLoginLoader(false)},
        )
    };

    return(
        <div className="login-form">
        <div className="login-form__title">Enter your email address.</div>
        <Formik
            initialValues={forgotInitialValue}
            onSubmit={onForgotSubmit}
            validationSchema={forgotPasswordValidation}
        >
            <Form>
                <div className='login-form__field'>
                    <div className="label">Email</div>
                    <InputField type="email" name="email" placeholder="Enter email" />
                </div>
                <Button htmlType="submit" className="login-form__submit" loading={loginLoader}>Submit</Button>
            </Form>
        </Formik>
    </div>
    )
}

export default withRouter(ForgotPassword);
