import React, { useEffect, useState } from "react";
import "./restaurantDetails.scss";
import { LeftCircleFilled } from "@ant-design/icons";
import { Button, Carousel, Divider, Rate } from "antd";
import "../../../assets/app-icons/style.css";
import placeholder from "../../../assets/blog1.jpg";
import RestaurantService from "../../../services/RestaurantService/restaurant.service";
import PlanService from "../../../services/PlanService/plan.service";
import AttractionLocation from "../../../shared/components/AttractionLocation";
import GlobalLoader from "../../../shared/components/GlobalLoader";
import UpdatePlanItem from "../../../shared/components/UpdatePlanItem";
import { RestaurantReservationForm } from "./RestaurantReservationForm";
import Notification from "../../../shared/components/Notification";
import { NotificationTypes } from "../../../enums/notificationTypes";

const RestaurantDetails = (props: any) => {
  const [restaurant, setRestaurant] = useState<any>(null);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [locationDetails, setLocationDetails] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [reservationVisible, setReservationVisible] = useState<boolean>(false);
  const [reservationLoading, setReservationLoading] = useState<boolean>(false);
  useEffect(() => {
    setLoading(true);
    const { id } = props.match.params;

    RestaurantService.fetchRestaurantDetails(
      id,
      (restaurant: any) => {
        setRestaurant(restaurant);
        setLocationDetails(
          restaurant?.locationDetails.length > 0
            ? restaurant?.locationDetails?.split(",")
            : []
        );
      },
      () => {},
      () => {
        setLoading(false);
      }
    ).then();
  }, []);

  const addPlanItem = (payload: any) => {
    PlanService.addPlanItem(
      { plan_item: payload },
      (planItem: any) => {
        if (planItem) {
          restaurant.planItemId = planItem?.id;
          setRestaurant(restaurant);
          setReservationVisible(false);
        } else {
          Notification({
            message: "Unable to add",
            description: "Due to conflict in your preferences!",
            type: NotificationTypes.ERROR,
          });
        }
      },
      () => {},
      () => {
        setReservationLoading(false);
        setButtonLoading(false);
      }
    ).then();
  };

  const handlePlanAdd = (payload: any) => {
    setButtonLoading(true);
    setReservationLoading(true);
    addPlanItem(payload);
  };

  const refreshRestaurant = (planItem: any) => {
    restaurant.defaultPlanName = planItem["plan_name"];
    setRestaurant({ ...restaurant });
  };

  const updatePlanItemProps = {
    planItemId: restaurant?.planItemId,
    refreshList: refreshRestaurant,
  };

  return (
    <div className="user-component">
      <RestaurantReservationForm
        data={restaurant}
        loading={reservationLoading}
        visible={reservationVisible}
        handleOk={(payload) => handlePlanAdd(payload)}
        closeHandler={() => setReservationVisible(false)}
      />
      {loading && <GlobalLoader />}
      {!loading && (
        <>
          <div className="ride-details">
            <div className="ride-details__basic">
              <div className="back-button">
                <LeftCircleFilled
                  onClick={() => {
                    props.history.goBack();
                  }}
                />
              </div>
              <div className="ride-details__header">
                <div className="ride-details__name">
                  {restaurant?.name || ""}
                </div>
                <div className="ride-details__wait">
                  {restaurant?.costCode === "expensive"
                    ? "$$$"
                    : restaurant?.costCode === "moderate"
                    ? "$$"
                    : "$"}
                </div>
              </div>
              <Rate
                className="restaurant-details__rating"
                value={restaurant?.overallRating || 0}
                disabled
                allowHalf
                key={1}
              />
              <div className="ride-details__tags">
                {restaurant?.cuisines?.map((cuisine: any, i: number) => (
                  <div className="ride-details__tag" key={i}>
                    <span className="icon-cusine restaurant-details__cuisine-icon" />{" "}
                    {cuisine.cuisineName}
                  </div>
                ))}
              </div>
              <div className="ride-details__description">
                {restaurant?.summary || ""}
              </div>
              <div className="restaurant-details__highlights">
                {(restaurant?.wineList || restaurant?.bar) && (
                  <div className="restaurant-details__highlight wine">
                    <span className="icon-location icon" />
                    {restaurant?.wineList && "Wine "}
                    {restaurant?.wineList && restaurant?.bar && "and "}
                    {restaurant?.bar && "Bar"}
                  </div>
                )}
                <div className="restaurant-details__highlight park">
                  <span className="icon-location icon" />{" "}
                  {restaurant?.parkName || "NA"}
                </div>
              </div>
              <div className="ride-details__action">
                {restaurant?.planItemId ? (
                  <div
                    onClick={(event) => {
                      event.stopPropagation();
                      setRestaurant(restaurant);
                    }}
                  >
                    <UpdatePlanItem {...updatePlanItemProps} />
                  </div>
                ) : (
                  <Button
                    type="primary"
                    loading={buttonLoading}
                    onClick={() => {
                      restaurant?.isQuickServiceAvailable
                        ? addPlanItem({
                            attraction_type: "Restaurant",
                            attraction_id: restaurant?.id,
                            override: true,
                          })
                        : setReservationVisible(true);
                    }}
                  >
                    Add to plan
                  </Button>
                )}
              </div>
              {restaurant?.planItemId && (
                <div className="default-plan-info">
                  This restaurant is added to plan{" "}
                  <b>{restaurant?.defaultPlanName}</b>
                </div>
              )}
            </div>
            <div className="ride-details__carousel">
              <Carousel>
                {restaurant?.attachments?.map((attachment: any, i: number) => (
                  <div key={i}>
                    <img src={attachment.imageUrl || placeholder} alt="" />
                  </div>
                ))}
                {restaurant?.attachments?.length === 0 && (
                  <div>
                    <img src={placeholder} alt="" />
                  </div>
                )}
              </Carousel>
            </div>
            <Divider />
          </div>
          <div className="restaurant-menu-nearby">
            <div className="restaurant-menu-nearby__block">
              <div className="restaurant__menu__title">Meals</div>
              {restaurant?.breakfastUrl && (
                <div className="restaurant__menu__list">
                  Breakfast
                  <Button
                    type="primary"
                    className="restaurant__menu__action"
                    onClick={() => {
                      window.open(`${restaurant?.breakfastUrl}`, "_blank");
                    }}
                  >
                    Check Menu
                  </Button>
                </div>
              )}
              {restaurant?.brunchUrl && (
                <div className="restaurant__menu__list">
                  Brunch
                  <Button
                    type="primary"
                    className="restaurant__menu__action"
                    onClick={() => {
                      window.open(`${restaurant?.brunchUrl}`, "_blank");
                    }}
                  >
                    Check Menu
                  </Button>
                </div>
              )}
              {restaurant?.lunchUrl && (
                <div className="restaurant__menu__list">
                  Lunch
                  <Button
                    type="primary"
                    className="restaurant__menu__action"
                    onClick={() => {
                      window.open(`${restaurant?.lunchUrl}`, "_blank");
                    }}
                  >
                    Check Menu
                  </Button>
                </div>
              )}
              {restaurant?.snacksUrl && (
                <div className="restaurant__menu__list">
                  Snacks
                  <Button
                    type="primary"
                    className="restaurant__menu__action"
                    onClick={() => {
                      window.open(`${restaurant?.snacksUrl}`, "_blank");
                    }}
                  >
                    Check Menu
                  </Button>
                </div>
              )}
              {restaurant?.dinnerUrl && (
                <div className="restaurant__menu__list">
                  Dinner
                  <Button
                    type="primary"
                    className="restaurant__menu__action"
                    onClick={() => {
                      window.open(`${restaurant?.dinnerUrl}`, "_blank");
                    }}
                  >
                    Check Menu
                  </Button>
                </div>
              )}
              {restaurant?.otherMenuUrl && (
                <div className="restaurant__menu__list">
                  Others
                  <Button
                    type="primary"
                    className="restaurant__menu__action"
                    onClick={() => {
                      window.open(`${restaurant?.otherMenuUrl}`, "_blank");
                    }}
                  >
                    Check Menu
                  </Button>
                </div>
              )}
              {!restaurant?.breakfastUrl &&
                !restaurant?.brunchUrl &&
                !restaurant?.lunchUrl &&
                !restaurant?.snacksUrl &&
                !restaurant?.dinnerUrl &&
                !restaurant?.otherMenuUrl && (
                  <div className="restaurant__placeholder_text">
                    List not available
                  </div>
                )}
            </div>
            <div className="restaurant-menu-nearby__block">
              {locationDetails.length > 0 && (
                <>
                  <div className="restaurant__menu__title">Nearby Places</div>
                  <div className="restaurant__menu__places">
                    <ul>
                      {locationDetails?.map((location: any, i: number) => (
                        <li key={i}>{location}</li>
                      ))}
                    </ul>
                  </div>
                </>
              )}
            </div>
            <Divider />
          </div>
          {restaurant?.lat && restaurant?.long && (
            <AttractionLocation attraction={restaurant} showTitle={true} />
          )}
        </>
      )}
    </div>
  );
};

export default RestaurantDetails;
