import React, {useEffect, useState} from "react";
import './rainGearShops.scss';
import {withRouter,generatePath} from 'react-router-dom';
import AuthContainer from "../../../store/container/AuthContainer";
import {GoogleMap, LoadScript, Marker} from '@react-google-maps/api';
import WeatherService from "../../../services/WeatherService/weather.service";
import * as appRoutes from "../../../routes/routeConstants/appRoutes";

const RainGearShops = (props: any) => {

    const [rainGearShops,setRainGearShops] = useState<any>([]);
    const [center,setCenter] = useState<any>({lat:0,lng:0})

    useEffect(()=>{
        WeatherService.fetchRainGearShops(
            (rainGearShops:any)=>{
                setRainGearShops([...rainGearShops])
                setCenter(rainGearShops.length > 0 ?
                    {
                        lat:parseFloat(rainGearShops[0].lat),
                        lng:parseFloat(rainGearShops[0].long)
                    }
                    : {lat:0,lng:0})

            },
            ()=>{},
            ()=>{},
        ).then()
    },[]);

    return (
        <div className='rain-gear-shops'>
            <div className="rain-gear-shops__title">Where to buy rain gear</div>
            <div className='attraction-map__view'>
                <LoadScript
                    googleMapsApiKey="AIzaSyD8kucJtISkkfj6NFAnhQUdZ7gU3aAdHH4"
                >
                    <GoogleMap key='rain-gear-view'
                        options={{
                            streetViewControl: true,
                            scaleControl: true,
                            mapTypeControl: false,
                            panControl: false,
                            zoomControl: true,
                            rotateControl: false,
                            fullscreenControl: false,
                        }}
                        mapContainerStyle={{
                            height: "500px", width: "100%", filter:"grayscale(1)"
                        }}
                        center={center}
                        zoom={20}
                    >
                        {rainGearShops.map((rainGearShop:any,i:number)=>
                        <Marker key={i}
                                position={{
                                    lat: parseFloat(rainGearShop.lat),
                                    lng: parseFloat(rainGearShop.long)
                                }} >
                            <div>{i+1}</div>
                        </Marker>
                        )}
                    </GoogleMap>
                </LoadScript>
            </div>
            <div className="rain-gear-shops__list">
                <div className="rain-gear-shops__list__title">View list of rain gear shops</div>
                {rainGearShops.map((rainGearShop:any,i:number)=>
                    <div key={i} className='rain-gear-shops__name'
                        onClick={()=>{props.history.push(
                            generatePath(appRoutes.RAIN_GEAR_SHOP,{id:rainGearShop.id})
                        )}}>
                        <span>{i+1}</span>{rainGearShop.name}
                    </div>
                )}
            </div>
        </div>
    )
}

export default withRouter(AuthContainer(RainGearShops));
