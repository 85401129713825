import {serializable, alias, primitive, list, object} from 'serializr';
import {AttachmentsModel} from "./common.model";

class MenuModel{
    @serializable(alias('id', primitive()))
    id?: string;
    @serializable(alias('name', primitive()))
    name?: string;
    @serializable(alias('url', primitive()))
    url?: string;
    @serializable(alias('restaurant_id', primitive()))
    restaurantId?: string;
}

class CuisinesModel{
    @serializable(alias('id', primitive()))
    id?: string;
    @serializable(alias('restaurant_id', primitive()))
    restaurantId?: string;
    @serializable(alias('cuisine_id', primitive()))
    cuisineId?: string;
    @serializable(alias('cuisine_name', primitive()))
    cuisineName?: string;
    @serializable(alias('cuisine_icon_url', primitive()))
    cuisineIconUrl?: string;
}

export class RestaurantsListModel {
    @serializable(alias('id', primitive()))
    id?: string;
    @serializable(alias('name', primitive()))
    name?: string;
    @serializable(alias('park_name', primitive()))
    parkName?: string;
    @serializable(alias('location_name', primitive()))
    locationName?: string;
    @serializable(alias('cuisine', list(primitive())))
    cuisine?: string;
    @serializable(alias('overall_rating', primitive()))
    overallRating?: string;
    @serializable(alias('cost_code', primitive()))
    costCode?: string;
    @serializable(alias('image_url', primitive()))
    imageUrl?: string;
    @serializable(alias('image_thumb_url', primitive()))
    imageThumbUrl?: string;
    @serializable(alias('restaurant_cuisine_maps', list(object(CuisinesModel))))
    cuisines?: string;
    @serializable(alias('default_plan_name', primitive()))
    defaultPlanName?: string;
    @serializable(alias('plan_item_id', primitive()))
    planItemId?: string;
}

export class RestaurantDetailsModel {
    @serializable(alias('id', primitive()))
    id?: string;
    @serializable(alias('name', primitive()))
    name?: string;
    @serializable(alias('park_id', primitive()))
    parkId?: string;
    @serializable(alias('park_name', primitive()))
    parkName?: string;
    @serializable(alias('location_name', primitive()))
    locationName?: string;
    @serializable(alias('cost_code', primitive()))
    costCode?: string;
    @serializable(alias('bar', primitive()))
    bar?: boolean;
    @serializable(alias('wine_list', primitive()))
    wineList?: boolean;
    @serializable(alias('breakfast_hours', primitive()))
    breakfastHours?: string;
    @serializable(alias('lunch_hours', primitive()))
    lunchHours?: string;
    @serializable(alias('dinner_hours', primitive()))
    dinnerHours?: string;
    @serializable(alias('overall_rating', primitive()))
    overallRating?: string;
    @serializable(alias('location_details', primitive()))
    locationDetails?: string;
    @serializable(alias('lat', primitive()))
    lat?: string;
    @serializable(alias('long', primitive()))
    long?: string;
    @serializable(alias('menus', list(object(MenuModel))))
    menus?: string;
    @serializable(alias('attachments', list(object(AttachmentsModel))))
    attachments?: string;
    @serializable(alias('summary', primitive()))
    summary?: string;
    @serializable(alias('restaurant_cuisine_maps', list(object(CuisinesModel))))
    cuisines?: string;
    @serializable(alias('default_plan_name', primitive()))
    defaultPlanName?: string;
    @serializable(alias('plan_item_id', primitive()))
    planItemId?: string;
    @serializable(alias('breakfast_url', primitive()))
    breakfastUrl?: string;
    @serializable(alias('brunch_url', primitive()))
    brunchUrl?: string;
    @serializable(alias('lunch_url', primitive()))
    lunchUrl?: string;
    @serializable(alias('snacks_url', primitive()))
    snacksUrl?: string;
    @serializable(alias('dinner_url', primitive()))
    dinnerUrl?: string;
    @serializable(alias('other_menu_url', primitive()))
    otherMenuUrl?: string;
    @serializable(alias('is_quick_service_available', primitive()))
    isQuickServiceAvailable?: boolean
}
