import React, {useState} from "react";
import './landingNavBar.scss';
import {Button, Drawer, Layout, Menu} from 'antd';
import logo from "../../../assets/bdd-logo.png";
import {MenuOutlined} from "@ant-design/icons";
import * as appRoutes from "../../../routes/routeConstants/appRoutes";
import {withRouter} from "react-router-dom";

const { Header } = Layout;

const LandingNavBar = (props:any) => {

    const {blogScroll, appScroll, faqScroll, aboutUsScroll} = props

    const [showDrawer,setShowDrawer] = useState<boolean>(false)

    const MenuItems = (
        <Menu mode="horizontal" defaultSelectedKeys={['1']}>
            <Menu.Item key="1" onClick={appScroll}>
                Apps
            </Menu.Item>
            <Menu.Item key="2" onClick={blogScroll}>
                Blog
            </Menu.Item>
            <Menu.Item key="3" onClick={faqScroll}>
                FAQ
            </Menu.Item>
            <Menu.Item key="4" onClick={aboutUsScroll}>
                About
            </Menu.Item>
            <Menu.Item key="5" onClick={()=>props.history.push(appRoutes.LOGIN)}>
                Sign in
            </Menu.Item>
        </Menu>
    )

    return(
        <nav>
            <Layout>
                <Header className="landing__header">
                    <div className="landing__logo">
                        <img src={logo} alt='logo'/>
                    </div>
                    <div className='landing__desktop-menu'>
                        {MenuItems}
                    </div>
                    <Button className="landing__mobile-menu" type="primary" onClick={()=>
                        setShowDrawer(!showDrawer)
                    }>
                        <MenuOutlined />
                    </Button>
                    <Drawer
                        title={null}
                        placement="right"
                        closable={false}
                        onClose={()=>setShowDrawer(false)}
                        visible={showDrawer}
                    >
                        {MenuItems}
                    </Drawer>
                </Header>
            </Layout>
        </nav>
    )
}

export default withRouter(LandingNavBar);
