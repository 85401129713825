import React, {useEffect, useState} from "react";
import {Rate, Tabs} from "antd";
import {generatePath} from "react-router-dom";
import * as appRoutes from "../../../routes/routeConstants/appRoutes";
import placeholder from "../../../assets/Disneyworld.png";
import RestaurantService from "../../../services/RestaurantService/restaurant.service";
import AuthContainer from "../../../store/container/AuthContainer";
import SkeletonLoader from "../../../shared/components/SkeletonLoader";
import {withRouter} from "react-router-dom";

const {TabPane} = Tabs;

const ParkRestaurants = (props:any) => {

    const {user} = props;

    const [parkRestaurants, setParkRestaurants] = useState<any>([]);
    const [showSpinner, setShowSpinner] = useState<boolean>(false);
    const [parks, setParks] = useState<any>([]);

    useEffect(()=>{
        RestaurantService.fetchParks(
            user?.defaultLocationId,
            (parks:any)=>{
                setParks(parks);
                if(parks.length > 0){
                    refreshRestaurant(0,parks)
                }
            },
            ()=>{},
            ()=>{
            },
        ).then()
    },[])

    const refreshRestaurant = (index:any,parks:any) =>{
        setShowSpinner(true)
        let parkId = parks[index].id;
        RestaurantService.fetchParkRestaurants(parkId,
            (restaurants:any)=>{
                setParkRestaurants([...restaurants])
            },
            ()=>{},
            ()=>{setShowSpinner(false)},
        ).then()
    }

    return(
        <div className="park-restaurants">
            <Tabs tabBarGutter={15} onChange={(e)=>{refreshRestaurant(e,parks)}}>
                {parks?.map((park:any,i:number)=>
                    <TabPane tab={park.name} key={i}>
                        {showSpinner &&
                            <SkeletonLoader count={3} className="park-restaurant__card"/>
                        }
                        <div>
                            {!showSpinner && parkRestaurants.map((restaurant:any,i:number)=>
                                <div className="park-restaurant__card" key={i}
                                     onClick={()=>{props.history.push(
                                         generatePath(appRoutes.RESTAURANT_DETAILS,{id:restaurant.id}))}}>
                                    <div className="park-restaurant__image">
                                        <img src={restaurant?.imageUrl || placeholder} alt=''/>
                                    </div>
                                    <div className='park-restaurant__details'>
                                        <div className="park-restaurant__title-cost">
                                            <div className="park-restaurant__title">
                                                {restaurant?.name}
                                            </div>
                                            <div className="park-restaurant__cost">
                                                {restaurant?.costCode === 'expensive' ? '$$$' :
                                                    restaurant?.costCode === 'moderate' ? '$$' : '$'
                                                }
                                            </div>
                                        </div>
                                        <Rate className="park-restaurant__rating" disabled allowHalf
                                              value={restaurant?.overallRating || 0} key={i}/>
                                        <div className="park-restaurant__cuisine">
                                            <span className='icon-cusine icon'/>
                                            {restaurant?.cuisines?.map((cuisine:any) =>cuisine.cuisineName).join(',') || 'NA'}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </TabPane>
                )}
            </Tabs>
        </div>
    )
}

export default withRouter(AuthContainer(ParkRestaurants));
