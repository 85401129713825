import React, { useEffect, useState } from "react";
import "./checklist.scss";
import { Dropdown, Menu } from "antd";
import * as appRoutes from "../../routes/routeConstants/appRoutes";
import { DeleteConfirmation } from "../../shared/components/DeleteConfirmation";
import "../../assets/app-icons/style.css";
import planIcon from "../../assets/my-plans-icon.png";
import dateIcon from "../../assets/checklist-date.png";
import { EllipsisOutlined } from "@ant-design/icons";
import ChecklistService from "../../services/ChecklistService/checklist.service";
import { generatePath, withRouter } from "react-router-dom";
import LandingCard from "../../shared/components/LandingCard";
import ChecklistForm from "./ChecklistForm";
import SkeletonLoader from "../../shared/components/SkeletonLoader";
import { ExistingChecklist } from "./ExistingChecklist";

const Checklist = (props: any) => {
  const [checklists, setChecklists] = useState<Array<any>>([]);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [deleteVisible, setDeleteVisible] = useState<boolean>(false);
  const [checklistId, setChecklistId] = useState<string>("");
  const [action, setAction] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [showForm, setFormVisibility] = useState<boolean>(false);

  const [showExistingChecklist, setExistingChecklistVisibility] =
    useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [planId, setPlanId] = useState<any>(null);
  useEffect(() => {
    fetchChecklists();
  }, []);

  const fetchChecklists = () => {
    setLoading(true);
    ChecklistService.fetchChecklists(
      (checklists: any) => {
        setChecklists(checklists);
      },
      () => {},
      () => {
        setLoading(false);
      }
    ).then();
  };

  const handlePlanAction = (action: string) => {
    setAction(action);
    if (action) {
      setFormVisibility(true);
    }
    if (action === "create") {
      setChecklistId("");
    }
  };

  const handleDelete = () => {
    setDeleteLoading(true);
    ChecklistService.deleteChecklist(
      checklistId,
      () => {
        fetchChecklists();
        setDeleteVisible(false);
      },
      () => {},
      () => {
        setDeleteLoading(false);
      }
    ).then();
  };

  const menu = (
    <Menu>
      <Menu.Item
        key="1"
        onClick={() => {
          props.history.push(
            generatePath(appRoutes.CHECKLIST_DETAILS, { id: checklistId })
          );
        }}
      >
        View
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={() => {
          handlePlanAction("edit");
        }}
      >
        Edit
      </Menu.Item>
      <Menu.Item
        key="3"
        onClick={() => {
          setDeleteVisible(true);
        }}
      >
        Remove
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="user-component">
      <ChecklistForm
        action={action}
        checklists={checklists}
        checklistId={checklistId}
        visible={showForm}
        closeHandler={() => setFormVisibility(false)}
        fetchChecklists={fetchChecklists}
        handlePlanAction={handlePlanAction}
        handleExistingAction={(name: string, plan: any) => {
          setName(name);
          setPlanId(plan);
          setExistingChecklistVisibility(true);
        }}
      />
      <DeleteConfirmation
        deleteVisible={deleteVisible}
        setDeleteVisible={setDeleteVisible}
        buttonLoading={deleteLoading}
        text="checklist"
        handleDelete={handleDelete}
      />
      <ExistingChecklist
        name={name}
        planId={planId}
        checklists={checklists}
        refreshList={fetchChecklists}
        visible={showExistingChecklist}
        cancelHandler={() => {
          setExistingChecklistVisibility(false);
        }}
        successHandler={() => {
          setFormVisibility(false);
          setExistingChecklistVisibility(false);
        }}
      />
      <LandingCard
        backButtonEnabled={false}
        name={"Checklists"}
        desc={
          "Let us help you plan for what to bring to the parks. Make a check, check it twice, and then have a ton of fun!"
        }
        landingCardAction={() => handlePlanAction("create")}
        buttonText="Checklist"
      />
      <div className="checklist-cards">
        {loading && (
          <SkeletonLoader count={3} className="checklist-cards__card" />
        )}
        {!loading &&
          checklists?.map((checklist: any, i: number) => (
            <div className="checklist-cards__card" key={i}>
              <Dropdown
                overlay={menu}
                placement="bottomCenter"
                trigger={["click"]}
                className="checklist-cards__card__action"
                onVisibleChange={(visible) => {
                  setChecklistId(visible ? checklist.id : "");
                }}
              >
                <EllipsisOutlined onClick={(e) => e.stopPropagation()} />
              </Dropdown>
              <div
                onClick={(e) => {
                  props.history.push(
                    generatePath(appRoutes.CHECKLIST_DETAILS, {
                      id: checklist?.id,
                    })
                  );
                  e.stopPropagation();
                }}
              >
                <div className="checklist-cards__name">{checklist.name}</div>
                <div className="checklist-cards__detail">
                  <img
                    className="checklist-cards__icon"
                    src={planIcon}
                    alt=""
                  />
                  <div className="checklist-cards__value">
                    {checklist.planName ?? "NA"}
                  </div>
                </div>
                <div className="checklist-cards__detail">
                  <img
                    className="checklist-cards__icon date-icon"
                    src={dateIcon}
                    alt=""
                  />
                  <div className="checklist-cards__value">
                    {checklist.planDate ?? "NA"}
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default withRouter(Checklist);
