import React, {FC, useState} from "react";
import './showPendingInvites.scss';
import {CloseCircleFilled} from "@ant-design/icons";
import {Button, Modal} from "antd";
import avatarPlaceholder from "../../../../assets/avatar_placeholder.png";
import GroupService from "../../../../services/GroupService/group.service";
import Notification from "../../../../shared/components/Notification";
import {NotificationTypes} from "../../../../enums/notificationTypes";
import * as appRoutes from "../../../../routes/routeConstants/appRoutes";
import {withRouter,RouteComponentProps} from "react-router-dom";

interface ShowPendingInviteProps extends RouteComponentProps{
    showModal: boolean;
    setShowModal: Function;
    invites:any;
    user:any;
}

const ShowPendingInvite:FC<ShowPendingInviteProps> = (props) => {

    const {showModal,setShowModal,invites,user} = props;

    const [acceptLoading,setAcceptLoading] = useState<boolean>(false)
    const [rejectLoading,setRejectLoading] = useState<boolean>(false)

    const handleInviteUpdate = (inviteId:string,status:string) =>{
        if(status==='accept') setAcceptLoading(true)
        else setRejectLoading(true)
        let data = {invite:{status: status}};

        GroupService.inviteUpdate(inviteId,data,
            ()=>{
                if(status==='accept') setAcceptLoading(false)
                else setRejectLoading(false)
                let successMessage = status === 'accept' ? 'Invitation has been accepted' : 'Invitation has been rejected'
                Notification({ message: "Success", description: successMessage,type: NotificationTypes.SUCCESS });
                handleClose()
            },
            ()=>{
                if(status==='accept') setAcceptLoading(false)
                else setRejectLoading(false)
                Notification({ message: "Failure", description: "Unable to process the request",type: NotificationTypes.ERROR });
            },
            ()=>{},
        ).then()
    }

    const handleClose = () => {
        setShowModal(false)
        props.history.push(appRoutes.HOME,{user:user})
    }

    return (
        <Modal
            title={null}
            visible={showModal}
            footer={null}
            closable={false}
            className='new-group-modal pending-invite__modal'
        >
            <div className='pending-invite__member'>
                <img src={ invites[0]?.createdByProfilePicThumbUrl || avatarPlaceholder} alt=''/>
            </div>
            <div className='pending-invite__name'>{invites[0]?.createdByName}</div>
            <div className='pending-invite__detail'>
                Invited you to join the group <span>{invites[0]?.groupName}</span>
            </div>
            <div className='pending-invite__action'>
                <Button type='primary' key='accept-pending-invite' onClick={()=>
                    handleInviteUpdate(invites[0]?.id,'accept')
                } loading={acceptLoading}>
                    Accept
                </Button>
                <Button type='text' key='reject-pending-invite' onClick={()=>
                    handleInviteUpdate(invites[0]?.id,'reject')
                } loading={rejectLoading}>
                    Reject
                </Button>
            </div>
            <CloseCircleFilled className='new-group-modal__close' onClick={handleClose}/>
        </Modal>
    )
}

export default withRouter(ShowPendingInvite);
