import {Form, Formik} from "formik";
import {resetPasswordValidation} from "../LoginForm/LoginValidation";
import InputField from "../../../shared/components/InputField";
import {Button} from "antd";
import React, {useState} from "react";
import Notification from "../../../shared/components/Notification";
import {NotificationTypes} from "../../../enums/notificationTypes";
import {serialize} from "serializr";
import {User} from "../../../models/user.model";
import UserService from "../../../services/AuthService/auth.service";
import * as appRoutes from '../../../routes/routeConstants/appRoutes';
import {withRouter} from 'react-router-dom';


const resetInitialValue = {
    otp: "",
    password: "",
    passwordConfirmation: "",
}

const ResetPassword = (props:any) =>{

    const [loginLoader,setLoginLoader] = useState<boolean>(false)

    const onResetSubmit = async(values:any)=>{
        if(values.password !== values.passwordConfirmation){
            Notification({ message: "Invalid request", description: "Passwords do not match",type: NotificationTypes.ERROR });
        }else {
            setLoginLoader(true)
            values.email = props.location?.state?.email || null;
            let data = {user: serialize(User,values)};
            //delete data.user.confirmPassword;
            await UserService.resetPassword(data,
                ()=>{
                    props.history.push(appRoutes.LOGIN)
                },
                ()=>{setLoginLoader(false)},
                ()=>{setLoginLoader(false)},
            )
        }
    };

    return(
        <div className="login-form">
            <div className="login-form__title">
                We just sent you a code to your email address.
                Enter the code and create a new password.
            </div>
            <Formik
                initialValues={resetInitialValue}
                onSubmit={onResetSubmit}
                validationSchema={resetPasswordValidation}
            >
                <Form>
                    <div className='login-form__field'>
                        <div className="label">Code</div>
                        <InputField type="text" name="otp" placeholder="Enter Code" />
                    </div>
                    <div className='login-form__field'>
                        <div className="label">Password</div>
                        <InputField type="password" name="password" placeholder="Enter New Password" />
                    </div>
                    <div className='login-form__field'>
                        <div className="label">Confirm Password</div>
                        <InputField type="password" name="passwordConfirmation" placeholder="Enter Password Again" />
                    </div>
                    <Button htmlType="submit" className="login-form__submit" loading={loginLoader}>Submit</Button>
                </Form>
            </Formik>
        </div>
    )
}

export default withRouter(ResetPassword);
