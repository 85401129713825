import { HAVE_FAST_PASS } from "../definitions/rideConstants";
import { CreateReducer } from "../../shared/utils/createReducer";

interface IRideState {
    haveFastPass: boolean;
    fastPassStartTime: string ;
}

const initState: IRideState = {
    haveFastPass: false,
    fastPassStartTime: ''
};

const rideReducer = CreateReducer(initState, {
    [HAVE_FAST_PASS](state: any, action: any) {
        const { haveFastPass, fastPassStartTime } = action.payload;
        return {
            ...state,
            haveFastPass: haveFastPass,
            fastPassStartTime: fastPassStartTime
        };
    },

});

export default rideReducer;



