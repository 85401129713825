import { combineReducers } from "redux";
import authReducer from "./authReducer";
import rideReducer from "./rideReducer";

const RootReducer = combineReducers({
    auth: authReducer,
    ride: rideReducer
});

export default RootReducer;
