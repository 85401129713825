import React, { useEffect, useState } from "react";
import "../auth.scss";
import registerProfileImage from "../../../assets/BDD_SetUpScreens_Profile.png";
import registerAttractionImage from "../../../assets/BDD_SetUpScreens_Rides.png";
import registerFoodImage from "../../../assets/BDD_SetUpScreens_Food.png";
import registerMobilityImage from "../../../assets/BDD_SetUpScreens_Mobility.png";
import profilePlaceholder from "../../../assets/profile-placeholder.png";
import { Button, Upload, Checkbox, Form, Input, Slider } from "antd";
import { LoadingOutlined, CheckCircleFilled } from "@ant-design/icons";
import _ from "lodash";
import Search from "antd/es/input/Search";
import * as appRoutes from "../../../routes/routeConstants/appRoutes";
import { withRouter } from "react-router-dom";
import { convertJSONToFormData } from "../../../shared/utils/dataFormatConverter";
import UserService from "../../../services/AuthService/auth.service";
import { fetchMetaValues } from "../../../shared/utils/fetchMetaValues";
import Notification from "../../../shared/components/Notification";
import { NotificationTypes } from "../../../enums/notificationTypes";
import AuthContainer from "../../../store/container/AuthContainer";
import "../../../assets/app-icons/style.css";
import LocationSelection from "./LocationSelection";
import GlobalLoader from "../../../shared/components/GlobalLoader";

const RegistrationForm = (props: any) => {
  useEffect(() => {
    if (props?.user?.defaultLocationId) {
      props.history.push(appRoutes.HOME);
    }
    setUserId(props?.user?.id);
    fetchMetaValues("ride_types", setRidePreferences);
    fetchMetaValues("mobility_types", setMobilityPreferences);
    fetchMetaValues("cuisines", setFoodPreferences);
  }, []);

  const next = () => {
    if (currentStep < 3) {
      setCurrentStep(currentStep + 1);
    }
  };

  function getBase64(
    img: Blob,
    callback: {
      (imageUrl: any): void;
      (arg0: string | ArrayBuffer | null): any;
    }
  ) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const onSubmit = () => {
    setSubmitLoading(true);

    let userData = {
      height: height,
      ride_type_ids: selectedRides,
      cuisine_ids: selectedCuisines,
      mobility_type_ids: selectedMobility,
    };

    let data = {
      user: userData,
      profile_pic: imageObject,
    };

    let userFormData = convertJSONToFormData(data);

    UserService.userUpdate(
      userId,
      userFormData,
      () => {
        setSubmitSuccess(true);
      },
      () => {},
      () => {
        setSubmitLoading(false);
      }
    ).then();
  };

  const handleChange = (info: any) => {
    setLoading(true);
    setTimeout(() => {
      setImageObject(info.file.originFileObj);
      getBase64(info.file.originFileObj, (imageUrl: any) => {
        setLoading(false);
        setImageUrl(imageUrl);
      });
    }, 250);
  };

  const handlePreferenceSelect = (
    index: number,
    data: any,
    initialValue: any,
    setState: Function
  ) => {
    let existingValue = _.indexOf(data, initialValue[index].id) !== -1;

    if (existingValue) {
      _.remove(data, (key) => key === initialValue[index].id);
      setState([...data]);
    } else {
      data.push(initialValue[index].id);
      setState([...data]);
    }
  };

  const handleSelectAll = (e: any) => {
    let selectedRides = e.target.checked
      ? ridePreferences.map((ride: any) => ride.id)
      : [];
    setSelectedRides([...selectedRides]);
  };

  const onSearch = (value: string) => {
    if (value.length >= 2)
      fetchMetaValues(`cuisines?search_text=${value}`, setFoodPreferences);
    if (!value) fetchMetaValues(`cuisines`, setFoodPreferences);
  };

  const [userId, setUserId] = useState<string>("");
  const [imageUrl, setImageUrl] = useState<string>();
  const [imageObject, setImageObject] = useState<any>();
  const [height, setHeight] = useState<number>(0);
  const [loading, setLoading] = useState<Boolean>(false);
  const [getHeight, setGetHeight] = useState<Boolean>(false);
  const [getMobility, setGetMobility] = useState<Boolean>(false);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [ridePreferences, setRidePreferences] = useState<any>();
  const [selectedRides, setSelectedRides] = useState<any>([]);
  const [foodPreferences, setFoodPreferences] = useState<any>();
  const [selectedCuisines, setSelectedCuisines] = useState<any>([]);
  const [mobilityPreferences, setMobilityPreferences] = useState<any>();
  const [selectedMobility, setSelectedMobility] = useState<any>([]);
  const [submitSuccess, setSubmitSuccess] = useState<Boolean>(false);
  const [submitLoading, setSubmitLoading] = useState<Boolean>(false);

  const uploadButton = (
    <div>
      {loading ? (
        <LoadingOutlined />
      ) : (
        <img
          src={profilePlaceholder}
          alt=""
          className="register__profile-placeholder"
        />
      )}
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  const retakeButton = (
    <Button
      type="primary"
      key="upload"
      className="upload-button"
      onClick={() => setImageUrl("")}
    >
      Remove photo
    </Button>
  );

  return (
    <>
      {submitLoading && <GlobalLoader />}
      {!submitLoading && !submitSuccess && (
        <div className="register">
          <div className="register-image">
            {currentStep === 0 && (
              <>
                <div className="register-image__text">
                  Let's set up your profile
                </div>
                <img src={registerProfileImage} alt="register" />
              </>
            )}
            {currentStep === 1 && (
              <>
                <div className="register-image__text">
                  We will use your selections below to customize your Best
                  Disney Day!
                </div>
                <img src={registerAttractionImage} alt="register" />
              </>
            )}
            {currentStep === 2 && (
              <>
                <div className="register-image__text">
                  Pick your favorite foods and we will help recommend places to
                  eat.
                </div>
                <img src={registerFoodImage} alt="register" />
              </>
            )}
            {currentStep === 3 && (
              <>
                <div className="register-image__text">
                  Do you have any Mobility Restrictions or require Other
                  Accommodations?
                </div>
                <img src={registerMobilityImage} alt="register" />
              </>
            )}
          </div>
          <Form>
            {currentStep === 0 && (
              <div className="register-form-carousel__step">
                <div className="inline-step">
                  <div className="title">Profile picture</div>
                  {!imageUrl && (
                    <Upload
                      name="avatar"
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      onChange={handleChange}
                      accept="image/png,image/jpeg"
                      multiple={false}
                    >
                      {uploadButton}
                    </Upload>
                  )}
                  {imageUrl && (
                    <>
                      <img
                        src={imageUrl}
                        alt="avatar"
                        className="attached-image"
                      />
                      <div>{retakeButton}</div>
                    </>
                  )}
                </div>
                <div className="inline-step">
                  <div className="title">Select height</div>
                  <div className="question">Are you taller than 48 inches?</div>
                  <div className="height-actions">
                    <Button
                      type={getHeight ? "primary" : "text"}
                      key="no"
                      onClick={() => setGetHeight(true)}
                    >
                      No
                    </Button>
                    <Button
                      type={getHeight ? "text" : "primary"}
                      key="yes"
                      onClick={() => setGetHeight(false)}
                    >
                      Yes
                    </Button>
                  </div>
                  {getHeight && (
                    <>
                      <div className="label">
                        Height in Inches (Min: 4, Max: 48)
                      </div>
                      <Slider
                        min={4}
                        max={48}
                        value={height}
                        onChange={(value: any) =>
                          setHeight(value > 48 ? 48 : value)
                        }
                        className="height-slider"
                        step={1}
                      />
                      <Input
                        type="number"
                        name="height"
                        placeholder="Enter height"
                        value={height}
                        className="height-input"
                        defaultValue={0}
                        onChange={(e: any) =>
                          setHeight(
                            e.target.value > 48
                              ? 48
                              : Math.floor(e.target.value)
                          )
                        }
                      />
                    </>
                  )}
                </div>
              </div>
            )}
            {currentStep === 1 && (
              <div className="register-form-carousel__step">
                <div className="title">Select ride preferences</div>
                {ridePreferences.length > 0 &&
                  ridePreferences.map((ride: any, i: number) => (
                    <div
                      className={`preference-card ${
                        _.indexOf(selectedRides, ride.id) !== -1 ? "active" : ""
                      }`}
                      key={ride.id}
                      onClick={() =>
                        handlePreferenceSelect(
                          i,
                          selectedRides,
                          ridePreferences,
                          setSelectedRides
                        )
                      }
                    >
                      {_.indexOf(selectedRides, ride.id) !== -1 && (
                        <CheckCircleFilled className="selected" />
                      )}
                      <img src={ride.iconUrl} alt="" />
                      <div className="type-label">{ride.name}</div>
                    </div>
                  ))}
                <div>
                  <Checkbox className="label" onChange={handleSelectAll}>
                    Select all rides
                  </Checkbox>
                </div>
              </div>
            )}
            {currentStep === 2 && (
              <div className="register-form-carousel__step">
                <div className="title">Select food preferences</div>
                <Search
                  placeholder="Search"
                  onChange={(e) => onSearch(e.target.value)}
                  enterButton
                />
                <div>
                  {foodPreferences.length > 0 &&
                    foodPreferences.map((cuisine: any, i: number) => (
                      <div
                        className={`preference-card ${
                          _.indexOf(selectedCuisines, cuisine.id) !== -1
                            ? "active"
                            : ""
                        }`}
                        key={cuisine.id}
                        onClick={() =>
                          handlePreferenceSelect(
                            i,
                            selectedCuisines,
                            foodPreferences,
                            setSelectedCuisines
                          )
                        }
                      >
                        {_.indexOf(selectedCuisines, cuisine.id) !== -1 && (
                          <CheckCircleFilled className="selected" />
                        )}
                        {cuisine?.iconUrl && (
                          <img src={cuisine.iconUrl} alt="" />
                        )}
                        {!cuisine?.iconUrl && <span className="icon-cusine" />}
                        <div className="type-label">{cuisine.name}</div>
                      </div>
                    ))}
                  {foodPreferences.length === 0 && <div> No items found </div>}
                </div>
              </div>
            )}

            {currentStep === 3 && (
              <div className="register-form-carousel__step">
                <div className="title">
                  Do you have any Mobility Restrictions or require Other
                  Accommodations?
                </div>
                <div className="height-actions">
                  <Button
                    type={getMobility ? "text" : "primary"}
                    key="no"
                    onClick={() => setGetMobility(false)}
                  >
                    No
                  </Button>
                  <Button
                    type={getMobility ? "primary" : "text"}
                    key="yes"
                    onClick={() => setGetMobility(true)}
                  >
                    Yes
                  </Button>
                </div>
                {getMobility && (
                  <>
                    <div className="question">
                      Mobility Restrictions or Other Accommodations
                    </div>
                    <div className="mobility-options">
                      {mobilityPreferences?.map((mobility: any, i: number) => (
                        <Checkbox
                          key={i}
                          onChange={() =>
                            handlePreferenceSelect(
                              i,
                              selectedMobility,
                              mobilityPreferences,
                              setSelectedMobility
                            )
                          }
                        >
                          {mobility.name}
                        </Checkbox>
                      ))}
                    </div>
                  </>
                )}
              </div>
            )}
            <div className="dots">
              <div
                className={currentStep === 0 ? "dot active" : "dot"}
                onClick={() => {
                  setCurrentStep(0);
                }}
              />
              <div
                className={currentStep === 1 ? "dot active" : "dot"}
                onClick={() => {
                  setCurrentStep(1);
                }}
              />
              <div
                className={currentStep === 2 ? "dot active" : "dot"}
                onClick={() => {
                  setCurrentStep(2);
                }}
              />
              <div
                className={currentStep === 3 ? "dot active" : "dot"}
                onClick={() => {
                  setCurrentStep(3);
                }}
              />
            </div>
            {currentStep < 3 && (
              <div className="carousel-controller">
                <Button type="text" key="skip" onClick={() => next()}>
                  Skip
                </Button>
                <Button type="primary" key="next" onClick={() => next()}>
                  Next
                </Button>
              </div>
            )}
            {currentStep === 3 && (
              <div className="carousel-controller">
                <Button
                  type="text"
                  key="skip"
                  onClick={() => {
                    setCurrentStep(0);
                  }}
                >
                  Cancel
                </Button>
                <Button key="approve" type="primary" onClick={() => onSubmit()}>
                  Save
                </Button>
              </div>
            )}
          </Form>
        </div>
      )}
      {!submitLoading && submitSuccess && <LocationSelection userId={userId} />}
    </>
  );
};

export default withRouter(AuthContainer(RegistrationForm));
