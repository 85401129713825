import axiosInstance from "../../interceptor/axiosInstance";
import { ACTUALS, ACTUALS_TRACK, ADHOC_RIDES } from "../../routes/routeConstants/apiRoutes";
import { deserialize } from "serializr";
import { generatePath } from "react-router-dom";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { RideDetailsModel, RidesListModel } from "../../models/rides.model";

export default class RideTrackerService {
  //fetch ride tracker list
  static fetchRideTracker(
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    return axiosInstance
      .get(ACTUALS)
      .then((response) => {
        let rides = deserialize(RidesListModel, response.data["rides"]);
        onSuccess(rides);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(onFinal);
  }

  //fetch ride tracker details
  static fetchRideTrackerDetails(
    rideId: any,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    return axiosInstance
      .get(ACTUALS_TRACK + `?ride_id=${rideId}`)
      .then((response) => {
        let rideDetails = deserialize(RideDetailsModel, response.data["ride"]);
        onSuccess(rideDetails);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(onFinal);
  }
  static createRide(
    data: any,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    return axiosInstance
    .post(ADHOC_RIDES, data)
    .then(() => {
      onSuccess()
    })
    .catch((error) => {
      onError(error);
    })
    .finally(onFinal);
  }
}
