import React, { useEffect, useState } from "react";
import "./rideTracker.scss";
import * as appRoutes from "../../routes/routeConstants/appRoutes";
import { generatePath } from "react-router-dom";
import ridePlaceholder from "../../assets/Astro Orbiter.png";
import RideTrackerService from "../../services/RideTrackerService/rideTracker.service";
import parkPlaceholder from "../../assets/hollywood-studio-icon.png";
import SkeletonLoader from "../../shared/components/SkeletonLoader";
import { Button } from "antd";
import { RideTrackerForm } from "./RideTrackerForm";

const RideTracker = (props: any) => {
  const [rides, setRides] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [showRideTrackerForm, setRideTrackerFormVisibility] =
    useState<boolean>(false);

  const fetchRideTrackerList = () => {
    setLoading(true);

    RideTrackerService.fetchRideTracker(
      (rides: any) => {
        setRides(rides);
      },
      () => {},
      () => {
        setLoading(false);
      }
    ).then();
  };
  useEffect(() => {
    fetchRideTrackerList();
  }, []);

  return (
    <div className="user-component">
      <RideTrackerForm
        visible={showRideTrackerForm}
        closeHandler={() => setRideTrackerFormVisibility(false)}
        refreshList={fetchRideTrackerList}
      />
      <div className="landing-card">
        <div className="landing-card__content ride-tracker__content">
          <div className="landing-card__content-title">Ride Tracker</div>
          <div className="landing-card__content-details">
            Keep track of the rides you're enjoying!
          </div>
          <div className="total-rides-count__container">
            <div className="total-rides-count__title">Total Rides</div>
            <div className="total-rides__count">{rides?.length}</div>
          </div>
          <Button className="add-completed-ride__btn"
          onClick={() => setRideTrackerFormVisibility(true)}
          >
            + Add Completed Ride
          </Button>
        </div>
      </div>
      <div className="ride-tracker">
        {loading && <SkeletonLoader count={3} className="ride-tracker__card" />}
        {!loading &&
          rides.map((ride: any, i: number) => (
            <div
              className="ride-tracker__card"
              key={i}
              onClick={() => {
                props.history.push(
                  generatePath(appRoutes.RIDE_TRACKER_DETAILS, { id: ride.id }),
                  { trackRecordCount: ride?.trackRecordCount }
                );
              }}
            >
              <div className="ride-tracker__image">
                <img src={ride?.imageUrl || ridePlaceholder} alt="" />
              </div>
              <div className="ride-tracker__details">
                <div className="ride-tracker__name">{ride?.shortName}</div>
                <div className="ride-tracker__location">
                  <span className="icon-location" />
                  {ride?.locationName}
                </div>
                <div className="ride-tracker__park">
                  <img src={ride?.parkIconUrl || parkPlaceholder} alt="" />
                  {ride?.parkName}
                </div>
              </div>
              <div className="ride-tracker__count">
                {ride?.trackRecordCount}
              </div>
            </div>
          ))}
        {!loading && rides.length === 0 && (
          <div className="ride-tracker__placeholder">
            <p>
              Once you complete a ride from your plan, it will allow you to rate
              it.
            </p>
            <p>
              Those ratings will be captured here so you can see what you did,
              when you did it and how you liked it.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default RideTracker;
