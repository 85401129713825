import React, { FC, useEffect, useState } from "react";
import PlanService from "../../../services/PlanService/plan.service";
import "./planForm.scss";
import { Button, Col, DatePicker, Drawer, Input, Row } from "antd";
import moment from "moment";
import { withRouter, RouteComponentProps } from "react-router-dom";
import PlanWeather from "../PlanWeather";
import PlanFormGroup from "./PlanFormGroup";
import PlanFormCrowdCalendar from "./PlanFormCrowdCalendar";
import AuthContainer from "../../../store/container/AuthContainer";
import UserService from "../../../services/AuthService/auth.service";

interface PlanFormProps extends RouteComponentProps {
  showDrawer: boolean;
  setShowDrawer: Function;
  plansListRefresh: Function;
}

const PlanForm: FC<PlanFormProps> = (props: any) => {
  const { showDrawer, setShowDrawer, plansListRefresh, user } = props;

  const [planName, setPlanName] = useState<string>("");
  const [date, setPlanDate] = useState<string>("");
  const [groupId, setGroupId] = useState<string>("");
  const [submitLoader, setSubmitLoader] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  useEffect(() => {
    setPlanDate(moment().format("MM-DD-YYYY"));
  }, []);

  const handlePlanCreation = () => {
    setSubmitLoader(true);
    let data = {
      plan: {
        location_id: user?.defaultLocationId,
        group_id: groupId ? groupId : null,
        name: planName,
        date: moment(date, "MM-DD-YYYY").format("DD-MM-YYYY"),
      },
    };
    console.log("While Submitting : ", data.plan.date);
    PlanService.createNewPlan(
      data,
      async (plan: any) => {
        let data = {
          user: {
            default_plan_id: plan.id,
          },
        };
        await UserService.userUpdate(
          user?.id,
          data,
          () => {},
          () => {},
          () => {}
        );
        plansListRefresh();
        setSuccess(true);
        setSubmitLoader(false);
      },
      () => {
        setSubmitLoader(false);
      },
      () => {}
    ).then();
  };

  const resetValues = () => {
    setPlanName("");
    setGroupId("");
    setSuccess(false);
    setShowDrawer(false);
  };

  return (
    <div className="plan-form">
      <Drawer
        className="plan-form-drawer"
        placement="right"
        closable={false}
        onClose={() => {
          resetValues();
        }}
        visible={showDrawer}
        key="plan-form-drawer"
      >
        {!success && (
          <div>
            <div className="plan-form__title">Plan Your Best Disney Day</div>
            <Row className="plan-form-inputs" gutter={[24, 24]}>
              <Col span={12}>
                <div className="plan-form__field">
                  <div className="plan-form__label">Plan Name</div>
                  <Input
                    name="planName"
                    type="text"
                    className="plan-form__input"
                    placeholder="Name your plan"
                    value={planName}
                    onChange={(e: any) => {
                      setPlanName(e.target.value);
                    }}
                  />
                </div>
              </Col>
              <Col span={12}>
                <div className="plan-form__field">
                  <div className="plan-form__label">Select Date</div>
                  <DatePicker
                    name="date"
                    onChange={(date, dateString) => {
                      setPlanDate(dateString);
                    }}
                    className="plan-form__picker"
                    format={"MM-DD-YYYY"}
                    defaultValue={moment()}
                  />
                </div>
              </Col>
              <Col span={24}>
                <PlanFormGroup setGroupId={setGroupId} />
              </Col>

              {/* {date && <PlanFormCrowdCalendar {...{date:date}}/>} */}
            </Row>
            <div className="plan-form__actions">
              <Button
                type="text"
                onClick={() => {
                  resetValues();
                }}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                loading={submitLoader}
                disabled={!planName}
                onClick={() => handlePlanCreation()}
              >
                Save
              </Button>
            </div>
          </div>
        )}
        {success && <PlanWeather />}
      </Drawer>
    </div>
  );
};

export default withRouter(AuthContainer(PlanForm));
