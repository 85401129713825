import * as Yup from  "yup";

export const validationSchema = Yup.object().shape({
    email: Yup.string()
        .email('E-mail is not valid!')
        .required('E-mail is required!'),
    password: Yup.string()
        .min(6, 'Password has to be longer than 6 characters!')
        .required('Password is required!')
});

export const forgotPasswordValidation = Yup.object().shape({
    email: Yup.string()
        .email('E-mail is not valid!')
        .required('E-mail is required!'),
});

export const resetPasswordValidation = Yup.object().shape({
    otp: Yup.string()
        .min(6, 'OTP has to be 6 characters!')
        .required('OTP is required!'),
    password: Yup.string()
        .min(6, 'Password has to be longer than 6 characters!')
        .required('Password is required!')
});
