import { Button, Row, Col, Checkbox } from "antd";
import React, { FC, useEffect } from "react";
import { useState } from "react";
import profilePlaceholder from "../../../assets/profile-placeholder.png";
import {
  GroupMemberFormModel,
  groupMembersModel,
} from "../../../models/group.model";
import GroupService from "../../../services/GroupService/group.service";
import avatarPlaceholder from "../../../assets/avatar_placeholder.png";

import "./existingGroupMembers.scss";
import { serialize } from "serializr";
import { convertJSONToFormData } from "../../../shared/utils/dataFormatConverter";
import Notification from "../../../shared/components/Notification";
import { NotificationTypes } from "../../../enums/notificationTypes";
interface ExistingGroupMembersProps {
  cancelHandler: () => void;
  addHandler: () => void;
  data: any;
}
export const ExistingGroupMembers: FC<ExistingGroupMembersProps> = ({
  cancelHandler,
  addHandler,
  data,
}) => {
  const [members, setMembers] = useState<groupMembersModel[]>([]);
  const [selectedMembers, setSelectedMembers] = useState<any[]>([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const fetchExistingContacts = () => {
    GroupService.fetchExistingContacts(
      (contacts: groupMembersModel[]) => {
         setMembers(contacts);
      },
      () => {},
      () => {}
    );
  };
  useEffect(() => {
    fetchExistingContacts();
  }, []);

  const handleSave = () => {
    if (selectedMembers.length) {
      setButtonLoading(true);

      selectedMembers.map((value, index) => {
        let memberFormData = convertJSONToFormData({
          group_member: { user_id: value },
        });

        GroupService.addMember(
          memberFormData,
          data?.id,
          () => {
            if (selectedMembers.length - 1 == index) {
              setButtonLoading(false);
              Notification({
                type: NotificationTypes.SUCCESS,
                message: "Success",
                description: "Invite sent successfully",
              });
              cancelHandler();
            }
          },
          () => {},
          () => {}
        );
      });
    } else {
      Notification({
        type: NotificationTypes.ERROR,
        message: "Unable to save",
        description: "Select atleast one member",
      });
    }
  };
  const handleCheck = (userId: any, checked: boolean) => {
    if (checked) {
      if (!selectedMembers.includes(userId)) {
        let options = selectedMembers;
        options.push(userId);
        setSelectedMembers([...options]);
      }
    } else {
      if (selectedMembers.includes(userId)) {
        setSelectedMembers((selectedMembers) =>
          selectedMembers.filter((id) => id !== userId)
        );
      }
    }
  };
  return (
    <div className="existing-group-members__container">
      <div className="existing-group-members__header">
        <p>Add existing contacts below</p>
        <Button
          onClick={addHandler}
          icon={
            <img className="profile__placeholder" src={profilePlaceholder} />
          }
        >
          Click here to create new contact
        </Button>
      </div>
      {
        members.length ? 
    <>
      <Row gutter={[16, 16]} className="existing-group-members__wrapper">
        {members?.map((value, index) => {
          return (
            <Col span={8}>
              <div className="existing-group-members__card">
                <div className="d-flex">
                  <img src={value?.profilePicThumbUrl ?? avatarPlaceholder} />
                  <div className="existing-group-members-name__wrapper">
                    <p className="existing-group-members__name">
                      {value?.username}
                    </p>
                    <p>
                      {value?.userHeight
                        ? parseInt(value?.userHeight) > 48
                          ? "Taller than 48 inches"
                          : `${value?.userHeight} inches`
                        : "0 inches"}
                    </p>
                  </div>
                </div>
                <Checkbox
                  className="existing-group-members__checkbox"
                  checked={selectedMembers?.includes(value?.id)}
                  onChange={(e) => handleCheck(value.id, e.target.checked)}
                />
              </div>
            </Col>
          );
        })}
      </Row>
      <div className="existing-group-members__controllers">
        <Button
          className="existing-group-members__cancel"
          onClick={cancelHandler}
        >
          Cancel
        </Button>
        <Button
          loading={buttonLoading}
          className="existing-group-members__save"
          onClick={handleSave}
        >
          Save
        </Button>
      </div>
      </>
      : 
      <div className="empty__text">No exisiting contacts!<br/>Proceed with creating new member</div>
        }
    </div>
  );
};
