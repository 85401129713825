import React, {useEffect, useState} from "react";
import './attractionLocation.scss';
import {Divider} from "antd";
import {GoogleMap, LoadScript, Marker} from '@react-google-maps/api';

interface AttractionLocationProps {
    attraction: any;
    showTitle: boolean;
}

const AttractionLocation = (props:AttractionLocationProps) => {

    const {attraction,showTitle} = props;

    const [center,setCenter] = useState<any>({})

    useEffect(()=>{
        if(attraction.lat && attraction.long){
            setCenter({
                lat: parseFloat(attraction.lat),
                lng: parseFloat(attraction.long)
            })
        }
    },[attraction])

    return (
        <div className='attraction-map'>
            {showTitle && <div className='attraction-map__title'>
                Location
            </div>}
            <div className='attraction-map__view'>
                    <LoadScript
                        googleMapsApiKey="AIzaSyD8kucJtISkkfj6NFAnhQUdZ7gU3aAdHH4"
                    >
                        <GoogleMap
                            options={{
                                streetViewControl: true,
                                scaleControl: true,
                                mapTypeControl: false,
                                panControl: false,
                                zoomControl: true,
                                rotateControl: false,
                                fullscreenControl: false,
                            }}
                            mapContainerStyle={{
                                height: "500px", width: "100%",
                            }}
                            center={center}
                            zoom={20}
                        >
                            <Marker position={center} />
                        </GoogleMap>
                    </LoadScript>
            </div>
            {showTitle && <Divider />}
        </div>
    )
}

export default AttractionLocation;
