import axiosInstance from "../../interceptor/axiosInstance";
import {
    CROWD_CALENDAR, PLAN_ITEMS,
    PLANS,
} from "../../routes/routeConstants/apiRoutes";
import { deserialize, serialize } from "serializr";
import {PlanCrowdModel, PlanDetailsModel, PlanListModel, PlanValidationModel} from "../../models/plan.model";
import Notification from "../../shared/components/Notification";
import {NotificationTypes} from "../../enums/notificationTypes";

export default class PlanService {

    //fetch plans
    static fetchPlans(
        type: string,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .get(PLANS+`?type=${type}`)
            .then(response => {
                const plans = deserialize(PlanListModel, response.data['plans'])
                onSuccess(plans);
            })
            .catch(error => {
                onError(error);
            })
            .finally(onFinal)
    }

    //delete plan
    static deletePlan(
        id: string,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .delete(PLANS+`/${id}`)
            .then(response => {
                Notification({ message: "Success", description: "Deleted successfully", type: NotificationTypes.SUCCESS });
                onSuccess();
            })
            .catch(error => {
                Notification({ message: "Failed", description: "Unable to delete",type: NotificationTypes.ERROR });
                onError(error);
            })
            .finally(onFinal)
    }

    //update plan 
    static updatePlan(
        id: string,
        data: any,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        const payload = {plan: serialize(PlanListModel, data)}
        return axiosInstance
            .put(PLANS+`/${id}`, payload)
            .then(response => {
                Notification({ message: "Success", description: "Updated successfully", type: NotificationTypes.SUCCESS });
                onSuccess();
            })
            .catch(error => {
                Notification({ message: "Failed", description: "Unable to update",type: NotificationTypes.ERROR });
                onError(error);
            })
            .finally(onFinal)
    }
    //plan details
    static getPlanDetails(
        id: string,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .get(PLANS+`/${id}`)
            .then(response => {
                let planDetails = deserialize(PlanDetailsModel,response.data['plan'])
                onSuccess(planDetails);
            })
            .catch(error => {
                onError(error);
            })
            .finally(onFinal)
    }

    //get crowd calendar
    static getCrowdCalendarDetails(
        locationId: number,
        date: string,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .get(CROWD_CALENDAR+`?date=${date}&location_id=${locationId}`)
            .then(response => {
                let crowdCalendar = deserialize(PlanCrowdModel,response.data['crowd_calendars'])
                onSuccess(crowdCalendar);
            })
            .catch(error => {
                onError(error);
            })
            .finally(onFinal)
    }

    //plan create
    static createNewPlan(
        data: any,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .post(PLANS,data)
            .then(response => {
                onSuccess(response.data['plan']);
                Notification({ message: "Success", description: "Plan created successfully", type: NotificationTypes.SUCCESS });
            })
            .catch(error => {
                onError(error);
                Notification({ message: "Failed", description: "Unable to create the plan",type: NotificationTypes.ERROR });
            })
            .finally(onFinal)
    }

    //add plan item
    static addPlanItem(
        data: any,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .post(PLAN_ITEMS,data)
            .then(response => {
                const validationResult = deserialize(PlanValidationModel,response.data['validation_result'])
                onSuccess(response.data['plan_item'],validationResult,response.data['rider_swap_available']);
            })
            .catch(error => {
                onError(error);
                Notification({ message: "Failed", description: error?.data?.errors[0],type: NotificationTypes.ERROR });
            })
            .finally(onFinal)
    }

    //delete plan item
    static deletePlanItem(
        planItemId: string,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .delete(PLAN_ITEMS+`/${planItemId}`)
            .then(response => {
                onSuccess()
                Notification({ message: "Success", description: "Plan item deleted successfully", type: NotificationTypes.SUCCESS });
            })
            .catch(error => {
                onError(error);
                Notification({ message: "Failed", description: error?.response?.data?.errors[0],type: NotificationTypes.ERROR });
            })
            .finally(onFinal)
    }

    //update plan item
    static updatePlanItem(
        planItemId: string,
        data:any,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .put(PLAN_ITEMS+`/${planItemId}`,data)
            .then(response => {
                onSuccess(response.data['plan_item'])
                Notification({ message: "Success", description: "Plan item updated successfully", type: NotificationTypes.SUCCESS });
            })
            .catch(error => {
                onError(error);
                Notification({ message: "Failed", description: error?.response?.data?.errors[0],type: NotificationTypes.ERROR });
            })
            .finally(onFinal)
    }
}
