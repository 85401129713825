import {serializable, alias, primitive, list, object} from 'serializr';
import {User} from "./user.model";

export class PlanListModel{
    @serializable(alias('id', primitive()))
    id?: string;
    @serializable(alias('name', primitive()))
    name?: string;
    @serializable(alias('ride_count', primitive()))
    rideCount?: string;
    @serializable(alias('restaurant_count', primitive()))
    restaurantCount?: string;
    @serializable(alias('date', primitive()))
    date?: string;
    @serializable(alias('group_name', primitive()))
    groupName?: string;
    @serializable(alias('location_id', primitive()))
    locationId?: string;
    @serializable(alias('location_name', primitive()))
    locationName?: string;
}

class PlanItemsModel{
    @serializable(alias('id', primitive()))
    id?: string;
    @serializable(alias('plan_name', primitive()))
    planName?: string;
    @serializable(alias('attraction_type', primitive()))
    attractionType?: string;
    @serializable(alias('attraction_id', primitive()))
    attractionId?: string;
    @serializable(alias('fast_pass_start_time', primitive()))
    fastPassStartTime?: string;
    @serializable(alias('fast_pass_end_time', primitive()))
    fastPassEndTime?: string;
    @serializable(alias('park_id', primitive()))
    parkId?: string;
    @serializable(alias('park_name', primitive()))
    parkName?: string;
    @serializable(alias('average_wait_time', primitive()))
    averageWaitTime?: string;
    @serializable(alias('current_wait_time', primitive()))
    currentWaitTime?: string;
    @serializable(alias('overall_rating', primitive()))
    overallRating?: string;
    @serializable(alias('attraction_name', primitive()))
    attractionName?: string;
    @serializable(alias('attraction_image_url', primitive()))
    attractionImageUrl?: string;
    @serializable(alias('attraction_image_thumb_url', primitive()))
    attractionImageThumbUrl?: string;
}

class PlanParksModel{
    @serializable(alias('id', primitive()))
    id?: string;
    @serializable(alias('name', primitive()))
    name?: string;
    @serializable(alias('icon_url', primitive()))
    iconUrl?: string;
    @serializable(alias('plan_items', list(object(PlanItemsModel))))
    planItems?: string;

}

export class PlanDetailsModel{
    @serializable(alias('id', primitive()))
    id?: string;
    @serializable(alias('name', primitive()))
    name?: string;
    @serializable(alias('ride_count', primitive()))
    rideCount?: string;
    @serializable(alias('restaurant_count', primitive()))
    restaurantCount?: string;
    @serializable(alias('date', primitive()))
    date?: string;
    @serializable(alias('group_name', primitive()))
    groupName?: string;
    @serializable(alias('location_id', primitive()))
    locationId?: string;
    @serializable(alias('location_name', primitive()))
    locationName?: string;
    @serializable(alias('parks', list(object(PlanParksModel))))
    parks?: PlanParksModel;
}

export class PlanCrowdModel{
    @serializable(alias('id', primitive()))
    id?: string;
    @serializable(alias('park_name', primitive()))
    parkName?: string;
    @serializable(alias('date', primitive()))
    date?: string;
    @serializable(alias('park_timing', primitive()))
    parkTiming?: string;
    @serializable(alias('expectation', primitive()))
    expectation?: string;
    @serializable(alias('expectation_string', primitive()))
    expectationString?: string;
    @serializable(alias('location_name', primitive()))
    locationName?: string;
    @serializable(alias('park_icon_url', primitive()))
    parkIconUrl?: string;
}

class PlanRestrictionModel{
    @serializable(alias('status', primitive()))
    status?: boolean;
    @serializable(alias('users', list(object(User))))
    users?: string;
}

export class PlanValidationModel{
    @serializable(alias('height', object(PlanRestrictionModel)))
    height?: string;
    @serializable(alias('mobility', object(PlanRestrictionModel)))
    mobility?: object;
    @serializable(alias('preference', object(PlanRestrictionModel)))
    preference?: object;
}
