import React from "react";
import './faq.scss';
import {Collapse} from "antd";
import {PlusCircleOutlined,MinusCircleOutlined} from "@ant-design/icons";

const { Panel } = Collapse;

const FAQ = (props:any) => {

    const {scrollRef} = props;

    const faqContent = [
        {
            question: 'Is MyBDD Free?',
            answer: 'MyBDD is free to use on the web to plan your ultimate day at Disney World or Disneyland themeparks! ' +
                'To execute the plan, you will need to have the full version of the app (Available on the iOS App Store or Google Play). ' +
                'To unlock all the premium features there is a one-time fee of $4.99. 25% of all the proceeds from MyBDD go to children’s health charities (Make-a-Wish Foundation and March of Dimes)'
        },
        {
            question: 'Can I use this app with my family or friends?',
            answer: 'Sure can! We make it easy to create a plan which includes kids, friends, or anyone you are traveling with. When adding rides or restaurants to the ' +
                'app, you will get alerts if you are adding something that people in your group don’t want to do or eat - or if their mobility or height restricts them ' +
                'from joining you on that attraction.'
        },
        {
            question: 'Why would I use MyBDD?',
            answer: 'MyBDD is the complete Disney park planning tool. We make it easy to fit more into your trip with alerts when rides you want to do are having a ' +
                'shorter than normal wait time, or taking everything in your wish list and organizing it to save you time based on park wait times or even proximity ' +
                'to save you tons of extra walking. We make your trip even more magical!'
        },
        {
            question: 'Any additional features other than planning tools and info on how to save time in the park?',
            answer: 'We have a ton of great features! From Checklists and reminders of what to take to the park to a suite of entertaining options like Disney-themed ' +
                'jokes, Trivia, and more! We even show you where the photos are taken on rides, have a ride tracker to keep track of all the rides you go on and ' +
                'the ratings that you give them! We also monitor the weather and make recommendations for when it’s hot or rainy on what to go and do next.'
        },
        {
            question: 'Is MyBDD an official Disney Product?',
            answer: 'Best Disney Day by MyBDD is not affiliated with or sponsored by the Walt Disney corporation, Disneyland or Walt Disney World.'
        }
    ]

    return(
        <div className='faq' ref={scrollRef}>
            <div className='faq__title'>Frequently Asked Questions</div>
            <Collapse
                bordered={false}
                defaultActiveKey={['0']}
                expandIcon={({ isActive }) =>
                    isActive ? <MinusCircleOutlined /> : <PlusCircleOutlined />}
                className="site-collapse-custom-collapse"
                expandIconPosition={"right"}
            >
                {faqContent.map((faq:any,i:number)=>
                    <Panel header={faq.question} key={i} className="site-collapse-custom-panel">
                        <p>{faq.answer}</p>
                    </Panel>
                )}
            </Collapse>
        </div>
    )
}

export default FAQ;
