import {Button, Modal, Radio} from "antd";
import React, {FC, useEffect, useState} from "react";
import PlanService from "../../../services/PlanService/plan.service";
import {constructSelectOptions} from "../../utils/fetchMetaValues";
import './upcomingPlansModal.scss';
import AuthContainer from "../../../store/container/AuthContainer";

interface UpcomingPlansModalProps {
    handleUpdate: Function;
    visible: boolean;
    setVisible: Function;
    buttonLoading: boolean;
}

interface AuthProps{
    user:any
}

const UpcomingPlansModal:FC<UpcomingPlansModalProps&AuthProps> = (props) =>{

    const {handleUpdate,visible,setVisible,buttonLoading,user} = props;

    const [plansMeta,setPlansMeta] = useState<any>([]);
    const [planId,setPlanId] = useState<any>('');

    useEffect(()=>{
        PlanService.fetchPlans('upcoming',
            (plans:any)=>{
                constructSelectOptions(plans,setPlansMeta);
            },
            ()=>{},
            ()=>{},
        ).then()
        setPlanId(user?.defaultPlanId)
    },[])

    useEffect(()=>{
        setPlanId(user?.defaultPlanId)
    },[user])

    return(
        <Modal
            title={null}
            centered
            onCancel={()=>setVisible(false)}
            visible={visible}
            footer={null}
            className='update-default-plan-modal'
        >
            <div className='update-default-plan__text'>Upcoming Plans</div>

            <div className='update-default-plan__select'>
                {plansMeta.map((plan:any,i:number)=>
                    <Radio key={i} checked={plan.value === planId}
                           onClick={(event:any)=>{
                               setPlanId(event.target.checked ? plan.value : '')
                           }} className='update-default-plan__select__name'>
                        {plan.label}
                    </Radio>
                )}
                {plansMeta.length === 0 &&
                <div>
                    There are no upcoming plans.
                </div>}
            </div>

            {plansMeta.length > 0 && <div className="update-default-plan__actions">
                <Button key="update" type="primary" onClick={()=>handleUpdate(planId)}
                        disabled={!planId} loading={buttonLoading}>Update</Button>
            </div>}
        </Modal>
    )
}

export default AuthContainer(UpcomingPlansModal);
