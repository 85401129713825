import axiosInstance from "../../interceptor/axiosInstance";
import { WEATHERS, RAIN_GEAR_SHOPS } from "../../routes/routeConstants/apiRoutes";
import { deserialize } from "serializr";
import {RainGearShopsModel, WeathersModel} from "../../models/weathers.model";

export default class WeatherService {

    //get weather
    static fetchWeather(
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .get(WEATHERS)
            .then(response => {
                const weathers = deserialize(WeathersModel,response.data['weathers'])
                onSuccess(weathers);
            })
            .catch(error => {
                onError(error);
            })
            .finally(onFinal)
    }

    //get rain gear shops
    static fetchRainGearShops(
       // locationId:string,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .get(RAIN_GEAR_SHOPS)
            .then(response => {
                const rainGearShops = deserialize(RainGearShopsModel,response.data['rain_gear_shops'])
                onSuccess(rainGearShops);
            })
            .catch(error => {
                onError(error);
            })
            .finally(onFinal)
    }

    //get rain gear shop
    static fetchRainGearShop(
        id:string,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .get(RAIN_GEAR_SHOPS+`/${id}`)
            .then(response => {
                const rainGearShop = deserialize(RainGearShopsModel,response.data['rain_gear_shop'])
                onSuccess(rainGearShop);
            })
            .catch(error => {
                onError(error);
            })
            .finally(onFinal)
    }

}
