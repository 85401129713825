import {Button, Modal, Radio} from "antd";
import React, {FC, useEffect, useState} from "react";
import PlanService from "../../../services/PlanService/plan.service";
import {constructSelectOptions} from "../../utils/fetchMetaValues";
import './updateDefaultPlan.scss';
import UserService from "../../../services/AuthService/auth.service";
import AuthContainer from "../../../store/container/AuthContainer";
import UpcomingPlansModal from "../UpcomingPlansModal";

interface UpdateDefaultPlanProps {
    refreshList: Function
}

interface AuthProps{
    user: any;
    updateUser:Function
}

const UpdateDefaultPlan:FC<UpdateDefaultPlanProps&AuthProps> = (props) =>{

    const {user, refreshList} = props;

    const [buttonLoading,setButtonLoading] = useState<boolean>(false);
    const [visible,setVisible] = useState<boolean>(false)
    const [defaultPlanName,setDefaultPlanName] = useState<any>('')

    useEffect(()=>{
        setDefaultPlanName(user?.defaultPlanName)
    },[])

    useEffect(()=>{
        refreshList()
        setDefaultPlanName(user?.defaultPlanName)
    },[user])

    const handleUpdate = (planId:any) => {
        setButtonLoading(true);
        let data = {
            user:{
                default_plan_id: planId
            }
        }

        UserService.userUpdate(user?.id,data,
            (updatedUser:any)=>{
                refreshList()
                setVisible(false)
                setDefaultPlanName(updatedUser?.defaultPlanName)
                props.updateUser(updatedUser)
            },
            ()=>{},
            ()=>{setButtonLoading(false)},
        ).then()
    }

    const modalProps = {
        handleUpdate:handleUpdate,
        visible:visible,
        setVisible:setVisible,
        buttonLoading:buttonLoading
    }

    return(
        <>
            <div className='results__current-plan'>
                Current Plan: <b>{defaultPlanName || 'NA'}</b>
                <Button type='primary' className='results__current-plan__action'
                        onClick={()=>setVisible(true)}>Update</Button>
            </div>
            <UpcomingPlansModal {...modalProps}/>
        </>
    )
}

export default AuthContainer(UpdateDefaultPlan)
