import {
  DeleteOutlined,
  EllipsisOutlined,
  LeftCircleFilled,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import PlanService from "../../../services/PlanService/plan.service";
import "./planDetails.scss";
import * as appRoutes from "../../../routes/routeConstants/appRoutes";
import astroOrbiter from "../../../assets/Astro Orbiter.png";
import moment from "moment";
import GlobalLoader from "../../../shared/components/GlobalLoader";
import { Dropdown, Menu } from "antd";
import { DeleteConfirmation } from "../../../shared/components/DeleteConfirmation";
import { PlanEditForm } from "./PlanEditForm";
import EditIcon from "../../../assets/edit.svg";
const PlanDetails = (props: any) => {
  const { id } = props.match.params;

  const [planDetails, setPlanDetails] = useState<any>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteVisible, setDeleteVisible] = useState<boolean>(false);
  const [editVisible, setEditVisible] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [planItemId, setPlanItemId] = useState<string>("");

  useEffect(() => {
    fetchPlanDetails();
  }, []);

  const fetchPlanDetails = () => {
    setLoading(true);
    PlanService.getPlanDetails(
      id,
      (data: any) => {
        setPlanDetails(data);
      },
      () => {},
      () => {
        setLoading(false);
      }
    ).then();
  };

  const menu = (
    <Menu>
      <Menu.Item
        key="1"
        onClick={() => {
          setDeleteVisible(true);
        }}
      >
        <DeleteOutlined />
        Delete
      </Menu.Item>
    </Menu>
  );

  const handleDelete = () => {
    setDeleteLoading(true);
    PlanService.deletePlanItem(
      planItemId,
      () => {
        fetchPlanDetails();
        setDeleteVisible(false);
      },
      () => {},
      () => {
        setDeleteLoading(false);
      }
    ).then();
  };

  return (
    <div className="user-component">
      <DeleteConfirmation
        deleteVisible={deleteVisible}
        setDeleteVisible={setDeleteVisible}
        buttonLoading={deleteLoading}
        text="plan item"
        handleDelete={handleDelete}
      />
      <PlanEditForm
        data={planDetails}
        visible={editVisible}
        refreshList={fetchPlanDetails}
        closeHandler={() => {
          setEditVisible(false);
        }}
      />
      {loading && <GlobalLoader />}
      {!loading && (
        <>
          <div className="landing-card">
            <div className="landing-card__content">
              <div
                className="landing-card__content-back"
                onClick={() => {
                  props.history.push(appRoutes.PLANS);
                }}
              >
                <LeftCircleFilled />
              </div>
              <div className="plan-edit__wrapper">
                <div className="landing-card__content-title">
                  {planDetails?.name}
                </div>
                <div className="plan-edit__icon" onClick={() => setEditVisible(true)}>
                  <img src={EditIcon} />
                </div>
              </div>
              <div className="landing-card__content-details">
                {planDetails?.rideCount} rides
              </div>
            </div>
            <div className="landing-card__action" />
          </div>
          <div className="plan-parks">
            {planDetails.parks?.length > 0 ? (
              planDetails.parks?.map((park: any, i: number) => (
                <div key={i}>
                  <div className="plan-parks__name">
                    <img src={park.iconUrl} alt="" /> {park.name}
                  </div>
                  <div>
                    {park.planItems?.map((planItem: any, i: number) => (
                      <div className="plan-items" key={i}>
                        <Dropdown
                          overlay={menu}
                          placement="bottomCenter"
                          trigger={["click"]}
                          className="manage-plans-card__action"
                          onVisibleChange={(visible) => {
                            setPlanItemId(visible ? planItem.id : "");
                          }}
                        >
                          <EllipsisOutlined />
                        </Dropdown>
                        <div className="plan-items__image">
                          <img
                            src={
                              planItem.attractionImageUrl
                                ? planItem.attractionImageUrl
                                : astroOrbiter
                            }
                            alt=""
                          />
                        </div>
                        <div className="plan-items__detail">
                          <div className="plan-items__detail__name">
                            {planItem.attractionName}
                          </div>
                          {(planItem.averageWaitTime || planItem.currentWaitTime) && (
                            <>
                              Current Wait Time:
                              <span className={`plan-items__detail__wait ${(!planItem?.currentWaitTime || !planItem?.averageWaitTime) ? "wait__blue" : planItem?.currentWaitTime > planItem?.averageWaitTime ? "wait__red" :  "wait__green"}`}>
                                {planItem?.currentWaitTime ?? planItem?.averageWaitTime} min wait
                              </span>
                            </>
                          )}
                          {planItem.fastPassStartTime &&
                            planItem.fastPassEndTime && (
                              <div className="plan-items__detail__fastpass">
                                {`FastPass ${moment(planItem?.fastPassStartTime)
                                  .utc()
                                  .format("hh:mm a")} - ${moment(
                                  planItem?.fastPassEndTime
                                )
                                  .utc()
                                  .format("hh:mm a")}`}
                              </div>
                            )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))
            ) : (
              <div className="plan-parks__empty">
                <p className="plan-parks-empty__info">
                  No rides or restaurants added yet
                </p>
                <div
                  className="plan-weather__action plan-parks_empty__explore plan-details-empty-btn__size"
                  onClick={() =>
                    props.history.push(appRoutes.SEARCH_RESULTS, {
                      parkFilter: "filter",
                    })
                  }
                >
                  Explore the parks
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default PlanDetails;
