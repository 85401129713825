import {serializable, alias, primitive, list} from 'serializr';

export class MetaModel {
    @serializable(alias('id', primitive()))
    id?: string;
    @serializable(alias('name', primitive()))
    name?: string;
    @serializable(alias('icon_url', primitive()))
    iconUrl?: string;
    @serializable(alias('icon_thumb_url', primitive()))
    iconThumbUrl?: string;
    @serializable(alias('color_code', primitive()))
    colorCode?: string;
    @serializable(alias('desc', primitive()))
    desc?: string;
    @serializable(alias('category', primitive()))
    category?: string;
}


export class AttachmentsModel{
    @serializable(alias('id', primitive()))
    id?: string;
    @serializable(alias('image_url', primitive()))
    imageUrl?: string;
    @serializable(alias('image_thumb_url', primitive()))
    imageThumbUrl?: string;
}
