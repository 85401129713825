import React, {useEffect, useState} from "react";
import './planCategories.scss';
import {Tabs} from "antd";
import CommonService from "../../../services/CommonService/common.service";
import awesomeSnacksIcon from "../../../assets/Awesome-snacks.png";
import allRestaurantsIcon from "../../../assets/All-restaurants.png";
import allQuickService from "../../../assets/All-quick-service.png";
import wineBeer from "../../../assets/wine-beer.png";
import {fetchMetaValues} from "../../../shared/utils/fetchMetaValues";
import MetaListing from "./MetaListing";

const {TabPane} = Tabs;

const PlanCategories = () => {

    const [themes,setThemes] = useState<any>([]);
    const [experiences,setExperiences] = useState<any>([]);
    const [shows,setShows] = useState<any>([]);
    const [rideTypes,setRideTypes] = useState<any>([]);
    const [loading,setLoading] = useState<boolean>(false);

    useEffect(()=>{
        setLoading(true)
        CommonService.fetchMeta('themes',
            (metaData:any)=>{
                setThemes(metaData.filter((item:any) => item.category === 'themes'))
                setExperiences(metaData.filter((item:any) => item.category === 'experiences'))
                setShows(metaData.filter((item:any) => item.category === 'shows'))
            },
            ()=>{},
            ()=>{setLoading(false)},
        ).then();
        fetchMetaValues('ride_types',setRideTypes)
    },[]);

    const food = [
        {
            id:1,
            name: 'Awesome Snacks',
            iconUrl:awesomeSnacksIcon,
            colorCode:"#F2709C,#FF9472",
            desc: "Dole Whip! Churros! Mickey Ice Cream Bars! Check out some of the most delicious and iconic treats in all the parks!"
        },
        {
            id:2,
            name: 'Wine / Beer',
            iconUrl:wineBeer,
            colorCode:"#8E2DE2,#4A00E0",
            desc: "Looking for an adult beverage with your meals? Only some locations offer alcoholic beverages in Disney, so check out some of the spots with the coolest cocktails."
        },
        {
            id:3,
            name: 'All Restaurants',
            iconUrl:allRestaurantsIcon,
            colorCode: "#1C37E4,#1CE4C9",
            desc: "Grab a delicious meal at one of the many magical restaurants around the parks! With so many choices, there is something for everyone."
        },
        {
            id:4,
            name: 'All Quick service options',
            iconUrl:allQuickService,
            desc: "Looking to dine and dash?! These restaurants or take away counters have some great food that won’t take you out of the park game for long."
        },
    ]

    return (
        <div className='user-component'>
            <div className='landing-card'>
                <div className='landing-card__content plan-categories__content'>
                    <div className='landing-card__content-title'>Create your Best Disney Day!</div>
                    <div className='landing-card__content-count'> Select the types of rides, experiences, and food you enjoy the most and we will help you to build the perfect trip to the park!</div>
                </div>
            </div>
            <div className='plan-categories__meta'>
                <Tabs defaultActiveKey="1" tabBarGutter={15}>
                    <TabPane tab="Themes" key="themes">
                        <MetaListing meta={themes} type='theme' loading={loading}/>
                    </TabPane>
                    <TabPane tab="Experiences" key="experiences">
                        <MetaListing meta={experiences} type='theme' loading={loading}/>
                    </TabPane>
                    <TabPane tab="Shows & Parades" key="shows">
                        <MetaListing meta={shows} type='theme' loading={loading}/>
                    </TabPane>
                    <TabPane tab="Ride Types" key="rideTypes">
                        <MetaListing meta={rideTypes} type='ride' loading={loading}/>
                    </TabPane>
                    <TabPane tab="Food" key="food">
                        <MetaListing meta={food} type='food' loading={loading}/>
                    </TabPane>
                </Tabs>
            </div>
        </div>
    )
}

export default PlanCategories;


