import React, {useEffect, useState} from "react";
import './rainGearShop.scss';
import '../../../assets/app-icons/style.css';
import {LeftCircleFilled} from "@ant-design/icons";
import WeatherService from "../../../services/WeatherService/weather.service";
import AttractionLocation from "../../../shared/components/AttractionLocation";
import GlobalLoader from "../../../shared/components/GlobalLoader";

const RainGearShop = (props: any) => {

    const [rainGearShop,setRainGearShop] = useState<any>([]);
    const [loading,setLoading] = useState<boolean>(false);

    useEffect(()=>{
        setLoading(true)
        let {id} = props.match.params;
        if(id){
            WeatherService.fetchRainGearShop(id,
                (rainGearShop:any)=>{
                    setRainGearShop(rainGearShop)
                },
                ()=>{},
                ()=>{setLoading(false)},
            ).then()
        }
    },[]);

    return (
        <div className='user-component'>
            {loading && <GlobalLoader/>}
            {!loading && <div className='rain-gear-shop'>
                <div className='rain-gear-shop__section'>
                    <div className='rain-gear-shop__back'>
                        <LeftCircleFilled onClick={()=>{props.history.goBack()}}/>
                    </div>
                    <div className="rain-gear-shop__name">{rainGearShop?.name}</div>
                    <div className="rain-gear-shop__location">
                        <span className="icon-location"/> {rainGearShop?.locationName}
                    </div>
                    <div className="rain-gear-shop__desc">{rainGearShop?.desc}</div>
                    <div className="rain-gear-shop__items__title">Items available for purchase</div>
                    <div className="rain-gear-shop__items__list">
                        <ul>
                            {rainGearShop?.purchasableItems?.map((item:any,i:number)=>
                                <li key={i}>{item}</li>
                            )}
                        </ul>
                    </div>
                </div>
                <div className='rain-gear-shop__section'>
                    <AttractionLocation attraction={rainGearShop} showTitle={false}/>
                </div>
            </div>}
        </div>
    )
}

export default RainGearShop;
