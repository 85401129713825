
/* Auth Routes */
export const AUTH = "/auth"
export const REGISTER = "/register";
export const LOGIN = AUTH + "/login";
export const SIGNUP = AUTH + "/signup";
export const FORGOT_PASSWORD = AUTH + "/forgot-password";
export const RESET_PASSWORD = AUTH + "/reset-password";

/* Home Route */
export const HOME = "/home"

/* Group */
export const GROUP = "/groups"
export const GROUP_DETAILS = "/groups/:id"

/* Plans */
export const PLANS = "/plans"
export const PLAN_DETAILS = "/plans/:id"
export const PLAN_CATEGORIES = "/categories";
export const PLAN_CATEGORY_OPTIONS = "/categories/:type/:id";

/*Search*/
export const SEARCH_RESULTS = "/search"

/*rides*/
export const RIDE_DETAILS = "/rides/:id"
export const RIDES_INDOOR = "/rides-indoor"

/*ride trackers*/
export const RIDE_TRACKER = '/ride-tracker';
export const RIDE_TRACKER_DETAILS = '/ride-tracker/:id';

/*restaurants*/
export const RESTAURANTS = "/restaurants"
export const RESTAURANT_DETAILS = "/restaurants/:id";

/*checklists*/
export const CHECKLISTS = "/checklists";
export const CHECKLIST_DETAILS = "/checklists/:id";

/*weather*/
export const WEATHER = "/weather";
export const RAIN_GEAR_SHOP = "/rain-gear-shop/:id";

/*blogs*/
export const BLOGS = "/blogs";
export const BLOGS_FILTER = "/blogs/filtered/:tag";
export const BLOG_DETAIL = "/blogs/:id";

/*landing*/
export const LANDING = "/get-started";

/*privacy policy*/
export const PRIVACY_POLICY = "/privacy-policy";
export const TERMS_AND_CONDITIONS = "/terms-and-conditions";

/*user update*/
export const USER_UPDATE = "/edit-profile";
