import {Button, Modal, Radio} from "antd";
import React, {FC, useEffect, useState} from "react";
import PlanService from "../../../services/PlanService/plan.service";
import {constructSelectOptions} from "../../utils/fetchMetaValues";
import '../UpdateDefaultPlan/updateDefaultPlan.scss';
import AuthContainer from "../../../store/container/AuthContainer";
import UpcomingPlansModal from "../UpcomingPlansModal";

interface UpdatePlanItemProps {
    planItemId: string;
    refreshList: Function;
}

interface AuthProps{
    user: any;
    updateUser: Function;
}

const UpdatePlanItem:FC<UpdatePlanItemProps&AuthProps> = (props) =>{

    const {user, refreshList, planItemId} = props;

    const [buttonLoading,setButtonLoading] = useState<boolean>(false);
    const [visible,setVisible] = useState<boolean>(false)

    const handlePlanItemUpdate = (planId:any) => {
        setButtonLoading(true);
        let data = {
            plan_item:{
                new_plan_id: planId
            }
        }

        PlanService.updatePlanItem(planItemId,data,
            (planItem:any)=>{
                user.defaultPlanName = planItem['plan_name']
                user.defaultPlanId = planId
                props.updateUser({...user})
                refreshList(planItem,'planName')
                setVisible(false)
            },
            ()=>{},
            ()=>{
                setButtonLoading(false)
            }
        ).then()
    }

    const modalProps = {
        handleUpdate:handlePlanItemUpdate,
        visible:visible,
        setVisible:setVisible,
        buttonLoading:buttonLoading
    }

    return(
        <>
            <Button type='text' className='change-plan-button'
                onClick={()=>{
                    setVisible(true);
                }}>
                Change
            </Button>
            <UpcomingPlansModal {...modalProps}/>
        </>
    )
}

export default AuthContainer(UpdatePlanItem);
