import React, {FC,useEffect, useState} from "react";
import '../groups.scss';
import {Button} from "antd";
import avatarPlaceholder from "../../../assets/avatar_placeholder.png";
import GroupService from "../../../services/GroupService/group.service";
import Notification from "../../../shared/components/Notification";
import {NotificationTypes} from "../../../enums/notificationTypes";
import SkeletonLoader from "../../../shared/components/SkeletonLoader";

interface InvitesReceivedProps{
    getGroupList: Function
}

const InvitesReceived:FC<InvitesReceivedProps> = (props) => {

    const {getGroupList} = props;

    const [invitesReceived,setInvitesReceived] = useState<Array<any>>([]);
    const [loading,setLoading] = useState<boolean>(false)

    useEffect(()=>{
        fetchInvitesReceived()
    },[]);

    const fetchInvitesReceived = () => {
        setLoading(true)
        GroupService.fetchInvites('received',
    (invites: React.SetStateAction<any[]>)=>{
                setInvitesReceived(invites)
            },
            ()=>{},
            ()=>{setLoading(false)},
        ).then()
    }

    const handleInviteUpdate = (inviteId:string,status:string) =>{

        let data = {invite:{status: status}};

        GroupService.inviteUpdate(inviteId,data,
            ()=>{
                fetchInvitesReceived();
                getGroupList();
                let successMessage = status === 'accept' ? 'Invitation has been accepted' : 'Invitation has been rejected'
                Notification({ message: "Success", description: successMessage,type: NotificationTypes.SUCCESS });
            },
            ()=>{
                Notification({ message: "Failure", description: "Unable to process the request",type: NotificationTypes.ERROR });
            },
            ()=>{},
        ).then()
    }


    return (
        <>
            {loading && <SkeletonLoader count={3} className='manage-groups__invites'/>}
            {!loading && invitesReceived.map((invite:any,i) =>
                <div className='manage-groups__invites' key={i}>
                    <img src={invite.userProfilePicThumbUrl || avatarPlaceholder}
                         alt='' className='manage-groups__invites--image'/>
                    <div className='manage-groups__invites--user-details'>
                        <div className='name'>{invite.userName}</div>
                        <div className='description'>{invite.createdByName} - {invite.groupName}</div>
                    </div>
                    <div className='manage-groups__invites--actions'>
                        <Button type='text' key='reject' onClick={()=>{handleInviteUpdate(invite.id,'reject')}}>
                            Reject
                        </Button>
                        <Button type='primary' key='accept' onClick={()=>{handleInviteUpdate(invite.id,'accept')}}>
                            Accept
                        </Button>
                    </div>
                </div>
            )}
        </>
    )
}

export default InvitesReceived;
