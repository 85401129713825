import axiosInstance from "../../interceptor/axiosInstance";
import {RIDES} from "../../routes/routeConstants/apiRoutes";
import { deserialize } from "serializr";
import {RideDetailsModel, RidesListModel} from "../../models/rides.model";

export default class RideService {

    //fetch ride details
    static fetchRideDetails(
        rideId: string,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .get(RIDES + `/${rideId}`)
            .then(response => {
                let rideDetails = deserialize(RideDetailsModel,response.data['ride'])
                onSuccess(rideDetails);
            })
            .catch(error => {
                onError(error);
            })
            .finally(onFinal)
    }

    //fetch filtered rides
    static fetchFilteredRides(
        locationId:string,
        filterQuery: string,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        let URL = filterQuery ? RIDES+`?location_id=${locationId}${filterQuery}`:
            RIDES+`?location_id=${locationId}`;

        return axiosInstance
            .get(URL)
            .then(response => {
                let rides = deserialize(RidesListModel,response.data['rides'])
                onSuccess(rides);
            })
            .catch(error => {
                onError(error);
            })
            .finally(onFinal)
    }
}
