import {alias, primitive, serializable, object,list} from "serializr";

export class SubHeadingsTemplateModel{
    @serializable(alias('id', primitive()))
    id?: string;
    @serializable(alias('name', primitive()))
    name?: string;
    @serializable(alias('items', list(primitive())))
    items?: string;
}


class ItemsModel{
    @serializable(alias('id', primitive()))
    id?: string;
    @serializable(alias('sub_heading_id', primitive()))
    subHeadingId?: string;
    @serializable(alias('name', primitive()))
    name?: string;
    @serializable(alias('is_checked', primitive()))
    isChecked?: boolean;
}

export class SubHeadingsModel{
    @serializable(alias('id', primitive()))
    id?: string;
    @serializable(alias('name', primitive()))
    name?: string;
    @serializable(alias('items', list(object(ItemsModel))))
    items?: ItemsModel[] = [];
}

export class ChecklistModel{
    @serializable(alias('id', primitive()))
    id?: string;
    @serializable(alias('name', primitive()))
    name?: string;
    @serializable(alias('plan_id', primitive()))
    planId?: string;
    @serializable(alias('plan_name', primitive()))
    planName?: string;
    @serializable(alias('plan_date', primitive()))
    planDate?: string;
    @serializable(alias('sub_headings', list(object(SubHeadingsModel))))
    subHeadings?: SubHeadingsModel[] = [];
}
