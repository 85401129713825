import React from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import * as appRoutes from "./routeConstants/appRoutes";
import AuthWrapper from "../views/Auth/AuthWrapper"
import requiredAuth from "../shared/components/HOC/requireAuth";
import Home from "../views/Home"
import Groups from "../views/Groups";
import Navbar from "../shared/components/Navbar";
import GroupDetails from "../views/Groups/GroupDetails";
import Plans from "../views/Plans";
import PlanDetails from "../views/Plans/PlanDetails";
import SearchResults from "../views/SearchResults";
import RideDetails from "../views/Rides/RideDetails";
import RideTracker from "../views/RideTracker";
import RideTrackerDetails from "../views/RideTracker/RideTrackerDetails";
import Restaurants from "../views/Restaurants";
import RestaurantDetails from "../views/Restaurants/RestaurantDetails";
import PlanCategories from "../views/Plans/PlanCategories";
import PlanOptions from "../views/Plans/PlanOptions";
import RegisterForm from "../views/Auth/RegistrationForm";
import Checklist from "../views/Checklist";
import ChecklistDetails from "../views/Checklist/ChecklistDetails";
import Weather from "../views/Weather";
import RainGearShop from "../views/Weather/RainGearShop";
import RidesIndoor from "../views/Rides/RidesIndoor";
import Blogs from "../views/Blogs";
import BlogsFilter from "../views/Blogs/BlogsFilter";
import BlogDetail from "../views/Blogs/BlogDetail";
import Landing from "../views/Landing";
import PrivacyPolicy from "../views/PrivacyPolicy";
import TermsAndConditions from "../views/TermsAndConditions";
import UserUpdate from "../views/UserUpdate";

export const appHistory = createBrowserHistory();

const AppRoutes = () => {

    const isAuthenticated = (component: any) => {
        return requiredAuth(component);
    }

    const routes = [
        { exact: false, path: appRoutes.AUTH, component: AuthWrapper },
        { exact: true, path: appRoutes.REGISTER, component: RegisterForm },
        { exact: true, path: appRoutes.HOME, component: isAuthenticated(Home) },
        { exact: true, path: appRoutes.GROUP, component: isAuthenticated(Groups) },
        { exact: true, path: appRoutes.GROUP_DETAILS, component: isAuthenticated(GroupDetails) },
        { exact: true, path: appRoutes.PLANS, component: isAuthenticated(Plans) },
        { exact: true, path: appRoutes.PLAN_DETAILS, component: isAuthenticated(PlanDetails) },
        { exact: true, path: appRoutes.SEARCH_RESULTS, component: isAuthenticated(SearchResults) },
        { exact: true, path: appRoutes.RIDE_DETAILS, component: isAuthenticated(RideDetails) },
        { exact: true, path: appRoutes.RIDE_TRACKER, component: isAuthenticated(RideTracker) },
        { exact: true, path: appRoutes.RIDE_TRACKER_DETAILS, component: isAuthenticated(RideTrackerDetails) },
        { exact: true, path: appRoutes.RESTAURANTS, component: isAuthenticated(Restaurants) },
        { exact: true, path: appRoutes.RESTAURANT_DETAILS, component: isAuthenticated(RestaurantDetails) },
        { exact: true, path: appRoutes.PLAN_CATEGORIES, component: isAuthenticated(PlanCategories) },
        { exact: true, path: appRoutes.PLAN_CATEGORY_OPTIONS, component: isAuthenticated(PlanOptions) },
        { exact: true, path: appRoutes.CHECKLISTS, component: isAuthenticated(Checklist) },
        { exact: true, path: appRoutes.CHECKLIST_DETAILS, component: isAuthenticated(ChecklistDetails) },
        { exact: true, path: appRoutes.WEATHER, component: isAuthenticated(Weather) },
        { exact: true, path: appRoutes.RAIN_GEAR_SHOP, component: isAuthenticated(RainGearShop) },
        { exact: true, path: appRoutes.RIDES_INDOOR, component: isAuthenticated(RidesIndoor) },
        { exact: true, path: appRoutes.BLOGS, component: isAuthenticated(Blogs) },
        { exact: true, path: appRoutes.BLOGS_FILTER, component: isAuthenticated(BlogsFilter) },
        { exact: true, path: appRoutes.BLOG_DETAIL, component: BlogDetail },
        { exact: true, path: appRoutes.LANDING, component: Landing },
        { exact: true, path: appRoutes.PRIVACY_POLICY, component: PrivacyPolicy },
        { exact: true, path: appRoutes.TERMS_AND_CONDITIONS, component: TermsAndConditions },
        { exact: true, path: appRoutes.USER_UPDATE, component: isAuthenticated(UserUpdate)},
    ]

    return (
        <div>
            <Router history={appHistory}>
                <Navbar/>
                <Switch>
                    {
                        routes.map((route, index) => {
                            return <Route
                                key={index}
                                {...route}
                                component={route.component}
                            />
                        })
                    }
                    <Route path="*" render={() => <Redirect to={appRoutes.LANDING} />} />
                </Switch>
            </Router>
        </div>
    )
}

export default AppRoutes;
