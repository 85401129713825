import React from "react";
import './blogsFilter.scss'
import {withRouter} from "react-router-dom"
import {LeftCircleFilled} from "@ant-design/icons";
import BlogsList from "../BlogsList";

const BlogsFilter = (props:any) => {

    const {tagData} = props?.location?.state;
    const {tag} = props?.match?.params;

    return(
        <div className='user-component'>
            <div className='blog-filter__header'>
                <LeftCircleFilled className='blog-filter__back-button'
                                  onClick={()=>props.history.goBack()}/>
                <div className={'blog-filter__card '+tagData?.className}>
                    <img src={tagData?.icon} alt=''/>
                    <div className='blog-filter__title'>{tagData?.name}</div>
                </div>
            </div>
            <BlogsList tag={tag}/>
        </div>
    )
}

export default withRouter(BlogsFilter);
