import axiosInstance from "../../interceptor/axiosInstance";
import {
    ATTRACTIONS,
    RIDES,
} from "../../routes/routeConstants/apiRoutes";
import { deserialize } from "serializr";
import {generatePath} from 'react-router-dom';
import Notification from "../../shared/components/Notification";
import {NotificationTypes} from "../../enums/notificationTypes";
import {RidesListModel} from "../../models/rides.model";
import {RestaurantsListModel} from "../../models/restaurants.model";

export default class SearchService {

    //search attractions
    static searchAttractions(
        name: string,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .get(ATTRACTIONS + `?name=${name}`)
            .then(response => {
                let rides = deserialize(RidesListModel,response.data['rides'])
                let restaurants = deserialize(RestaurantsListModel,response.data['restaurants'])
                onSuccess(rides,restaurants);
            })
            .catch(error => {
                onError(error);
            })
            .finally(onFinal)
    }

}
