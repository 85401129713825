import React from "react";
import './blogs.scss'
import createACharacterIcon from "../../assets/create-a-character.png";
import designCastleIcon from "../../assets/design-your-dream-castle.png";
import rideDesignerIcon from "../../assets/ride-designer.png";
import BlogsList from "./BlogsList";
import {withRouter,generatePath} from "react-router-dom";
import * as appRoutes from "../../routes/routeConstants/appRoutes";

const Blogs = (props:any) => {

    const tags = [
        {icon: createACharacterIcon, name: 'Create a Character', className: 'create-a-character'},
        {icon: designCastleIcon, name: 'Design Your Dream Castle', className: 'design-castle'},
        {icon: rideDesignerIcon, name: 'Ride Designer', className: 'ride-designer'},
    ]

    return (
        <div className='user-component'>
            <div className="blogs__landing-card">
                <div className="blogs__landing-card__title">
                    Making Magic <span>Awesome disney themed craft ideas</span>
                </div>
                <div>
                    {tags.map((tag:any,i:number)=>
                        <div className={"blogs__landing-card__types "+tag.className} key={i}
                            onClick={()=>props.history.push(
                                generatePath(appRoutes.BLOGS_FILTER,{tag:tag.name}),{tagData:tag}
                            )}>
                            <img src={tag.icon} alt=''/>
                            <div className='blogs__landing-card__tag-name'>{tag.name}</div>
                        </div>
                    )}
                </div>
            </div>
            <BlogsList title='All blogs'/>
        </div>
    )
}

export default withRouter(Blogs);
