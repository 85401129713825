import { Button, Modal } from "antd";
import { CloseCircleFilled } from "@ant-design/icons";
import React, { FC, useEffect, useState } from "react";
import ChecklistService from "../../../services/ChecklistService/checklist.service";
import PlanService from "../../../services/PlanService/plan.service";
import { constructSelectOptions } from "../../../shared/utils/fetchMetaValues";
import { validationSchema } from "./validationSchema";
import { Form, Formik } from "formik";
import InputField from "../../../shared/components/InputField";
import DropdownField from "../../../shared/components/DropdownField";
import { ChecklistModel } from "../../../models/checklist.model";
import { generatePath, useHistory } from "react-router-dom";
import { CHECKLIST_DETAILS } from "../../../routes/routeConstants/appRoutes";

interface ChecklistFormProps {
  action: string;
  checklists: any;
  checklistId: string;
  fetchChecklists: Function;
  handlePlanAction: Function;
  handleExistingAction: Function;
  visible: boolean;
  closeHandler: () => void;
}

const ChecklistForm: FC<ChecklistFormProps> = (props) => {
  let formInitial = {
    name: "",
    plan: undefined,
  };

  const {
    action,
    checklists,
    checklistId,
    fetchChecklists,
    handlePlanAction,
    handleExistingAction,
    visible,
    closeHandler,
  } = props;

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [plans, setPlans] = useState<Array<any>>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<any>(formInitial);
  const history = useHistory();
  useEffect(() => {
    PlanService.fetchPlans(
      "upcoming",
      (plans: any) => {
        constructSelectOptions(plans, setPlans);
      },
      () => {},
      () => {}
    ).then();
  }, []);

  useEffect(() => {
    if (action === "create") {
      setInitialValues(formInitial);
      setShowModal(true);
    } else {
      let filteredChecklist = checklists.filter(
        (checklist: any) => checklist.id === checklistId
      );
      if (filteredChecklist.length > 0) {
        setInitialValues({
          name: filteredChecklist[0].name,
          plan: filteredChecklist[0].planId,
        });
        setShowModal(true);
      }
    }
  }, [action]);

  // useEffect(() => {
  //   handlePlanAction(null);
  // }, [showModal]);

  const createChecklist = (values: any, resetForm: Function) => {
    setButtonLoading(true);
    let data = {
      checklist: {
        name: values.name,
        plan_id: values.plan,
      },
    };

    if (!checklistId) {
      ChecklistService.createChecklist(
        data,
        (response: ChecklistModel) => {
          fetchChecklists();
          setShowModal(false);
          resetForm(formInitial);
          closeHandler();
          history.push(
            generatePath(CHECKLIST_DETAILS, {
              id: response?.id,
            })
          );
        },
        () => {},
        () => {
          setButtonLoading(false);
        }
      ).then();
    } else {
      ChecklistService.updateChecklist(
        checklistId,
        data,
        () => {
          fetchChecklists();
          setShowModal(false);
          resetForm(formInitial);
          closeHandler();
        },
        () => {},
        () => {
          setButtonLoading(false);
        }
      ).then();
    }
  };

  return (
    <Modal
      title={null}
      visible={visible}
      footer={null}
      closable={false}
      className="new-group-modal"
    >
      <div className="new-group-modal__title new-checklist-modal__title">
        {`${action == "edit" ? "Update" : "New"} Checklist`}
      </div>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { resetForm }) => createChecklist(values, resetForm)}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ setFieldValue, values, isValid }) => {
          return (
            <Form>
              <div>
                <div className="label checklist-label">Checklist Name</div>
                <InputField
                  type="text"
                  name="name"
                  placeholder="Enter Name"
                  className="new-group-modal__input"
                />
              </div>
              <div>
                <div className="label checklist-label">
                  Select Plan (optional)
                </div>
                <DropdownField
                  mode="single"
                  name="plan"
                  options={plans || []}
                  placeholder="Select Plan"
                  onChange={(value: any) => {
                    setFieldValue("plan", value);
                  }}
                  value={values?.plan ?? undefined}
                  className="plan-form__group-selector checklist-plan__select"
                />
              </div>
              <div className="checklist-form-actions__container">
                <Button
                  key="save"
                  type="primary"
                  className="existing__btn"
                  disabled={
                    !values?.name || !checklists?.length || action == "edit"
                  }
                  onClick={() =>
                    isValid && handleExistingAction(values?.name, values?.plan)
                  }
                >
                  Select from Existing Checklist
                </Button>
                <Button
                  key="save"
                  type="primary"
                  htmlType="submit"
                  className="new__btn"
                  loading={buttonLoading}
                >
                  {`${action == "edit" ? "Update" : "Create"} New Checklist`}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
      <CloseCircleFilled
        className="new-group-modal__close"
        onClick={() => closeHandler()}
      />
    </Modal>
  );
};

export default ChecklistForm;
