import React, {FC} from "react";
import SkeletonLoader from "../../../../shared/components/SkeletonLoader";
import {generatePath} from "react-router-dom";
import * as appRoutes from "../../../../routes/routeConstants/appRoutes";
import {withRouter,RouteComponentProps} from "react-router-dom";

interface MetaListingProps extends RouteComponentProps{
    meta: Array<any>
    type: string
    loading:boolean
}

const MetaListing:FC<MetaListingProps> = (props) =>{
    const {meta,type,loading} = props;
    return(
        <div className='plan-categories__cards'>
            {loading && <SkeletonLoader count={6} className='plan-categories__card'/>}
            {!loading && meta?.map((metaItem:any,i:number)=>
                <div className='plan-categories__card' key={i}
                     onClick={()=>{
                         props.history.push(generatePath(appRoutes.PLAN_CATEGORY_OPTIONS,
                             {type: type,id: metaItem.id}),
                             {name:metaItem.name,colorCode:metaItem.colorCode,
                                 desc:metaItem.desc})
                     }}>
                    <img src={metaItem.iconUrl} alt=''/>
                    <div className='plan-categories__name'>{metaItem.name}</div>
                </div>
            )}
        </div>
    )
}

export default withRouter(MetaListing);
