import React, {useEffect, useState} from "react";
import './weather.scss';
import * as appRoutes from "../../routes/routeConstants/appRoutes";
import {Button} from "antd";
import weatherPlaceholder from "../../assets/weather-placeholder-icon.png";
import WeatherService from "../../services/WeatherService/weather.service";
import AuthContainer from "../../store/container/AuthContainer";
import moment from 'moment';
import RainGearShops from "./RainGearShopsList";
import GlobalLoader from "../../shared/components/GlobalLoader";

const Weather = (props: any) => {

    const [forecast,setForecast] = useState<any>([]);
    const [currentWeather,setCurrentWeather] = useState<any>('');
    const [loading,setLoading] = useState<boolean>(false);

    useEffect(()=>{
        setLoading(true)
        WeatherService.fetchWeather(
            (weathers:any)=>{
                setForecast(weathers.filter((obj:any) =>
                    obj.date !== moment().format('YYYY-MM-DD')
                ))
                let currentWeather = weathers.filter((obj:any) =>
                    obj.date === moment().format('YYYY-MM-DD')
                )
                setCurrentWeather(currentWeather.length > 0 ? currentWeather[0]: '')
            },
            ()=>{},
            ()=>{setLoading(false)}
        ).then()
    },[]);

    return (
        <div className='user-component'>
            {loading && <GlobalLoader/>}
            {!loading && <div className='weather'>
                        <div className='weather__actions'>
                            <Button type='primary' className='weather__actions__indoor'
                                    onClick={() => props.history.push(appRoutes.RIDES_INDOOR)}>
                                View list of indoor attractions
                            </Button>
                            <Button type='primary' className='weather__actions__plan'
                                    onClick={() => props.history.push(appRoutes.PLANS)}>+ Add plan</Button>
                        </div>
                        <div className='weather__landing-card'>
                            <div className='weather__landing-card__section'>
                                <div className='weather__landing-card__title'>Weather</div>
                                <div className='weather__landing-card__image'>
                                    <img src={currentWeather?.iconUrl || weatherPlaceholder} alt=''/>
                                </div>
                                <div className='weather__landing-card__detail'>
                                    <div>Wind</div>
                                    <div>
                                        <span
                                            className='weather__landing-card__detail--data'>{currentWeather?.windSpeed || '-'}</span>
                                        <span className='weather__landing-card__detail--unit'>mph</span>
                                    </div>
                                </div>
                                <div className='weather__landing-card__detail'>
                                    <div>Rain</div>
                                    <div>
                                        <span
                                            className='weather__landing-card__detail--data'>{currentWeather?.rain || '-'}</span>
                                        <span className='weather__landing-card__detail--unit'>%</span>
                                    </div>
                                </div>
                            </div>
                            <div className='weather__landing-card__temp'>
                                <span>{currentWeather?.temp || '-'}</span>
                                <sup>.</sup>
                                {currentWeather?.desc || 'Not available'}
                            </div>
                            <div>
                                <div className='weather__landing-card__location'>
                                    <div
                                        className='weather__landing-card__place'>{currentWeather?.locationName || '-'}</div>
                                    <div
                                        className='weather__landing-card__date'>{moment().format("dddd - MMMM Do, YYYY")}</div>
                                </div>
                                <div className='weather__landing-card__forecasts'>
                                    <div className='weather__forecasts--title'>This week's forecast</div>
                                    <div className='weather__forecasts'>
                                        {Array(7).fill('').map((_: any, i: any) =>
                                            <div className='weather__forecast' key={i}>
                                                <div className='weather__forecast--icon'>
                                                    <img src={forecast[i]?.iconUrl || weatherPlaceholder} alt=''/>
                                                </div>
                                                <div
                                                    className='weather__forecast--day'>{moment().add(i + 1, 'd').format("ddd")}</div>
                                                <div className='weather__forecast--temp'>{forecast[i]?.temp || '-'}</div>
                                            </div>)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
            <RainGearShops/>
        </div>
    )
}

export default AuthContainer(Weather);
