import React, { FC, useState } from "react";
import "./restaurantsList.scss";
import { Button, Rate } from "antd";
import "../../../assets/app-icons/style.css";
import {
  withRouter,
  generatePath,
  RouteComponentProps,
} from "react-router-dom";
import * as appRoutes from "../../../routes/routeConstants/appRoutes";
import PlanService from "../../../services/PlanService/plan.service";
import placeholder from "../../../assets/Disneyworld.png";
import SkeletonLoader from "../../../shared/components/SkeletonLoader";
import UpdatePlanItem from "../../../shared/components/UpdatePlanItem";
import { RestaurantReservationForm } from "../RestaurantDetails/RestaurantReservationForm";
import Notification from "../../../shared/components/Notification";
import { NotificationTypes } from "../../../enums/notificationTypes";

interface RestaurantsListProps extends RouteComponentProps {
  restaurants: Array<any>;
  loading: boolean;
  successHandler: Function;
}

const RestaurantsList: FC<RestaurantsListProps> = (props) => {
  const { restaurants, loading, successHandler } = props;

  const [addLoading, setAddLoading] = useState<boolean>(false);
  const [restaurantId, setRestaurantId] = useState<any>(null);
  const [restaurant, setRestaurant] = useState<any>("");
  const [reservationVisible, setReservationVisible] = useState<boolean>(false);
  const [reservationLoading, setReservationLoading] = useState<boolean>(false);
  const addPlanItem = (payload: any) => {
    PlanService.addPlanItem(
      { plan_item: payload },
      (planItem: any) => {
        if (planItem) {
          restaurant.planItemId = planItem?.id;
          successHandler(restaurant);
          setReservationVisible(false);
        } else {
          Notification({
            message: "Unable to add",
            description: "Due to conflict in your preferences!",
            type: NotificationTypes.ERROR,
          });
        }
      },
      () => {},
      () => {
        setReservationLoading(false);
        setAddLoading(false);
        setRestaurantId(null);
      }
    ).then();
  };
  const handlePlanAdd = (payload: any) => {
    setReservationLoading(true);
    addPlanItem(payload);
  };

  const refreshRestaurantList = (planItem: any) => {
    restaurant.defaultPlanName = planItem["plan_name"];
    successHandler({ ...restaurant });
  };

  const updatePlanItemProps = {
    planItemId: restaurant?.planItemId,
    refreshList: refreshRestaurantList,
  };

  return (
    <div>
      <RestaurantReservationForm
        data={restaurant}
        loading={reservationLoading}
        visible={reservationVisible}
        handleOk={(payload) => handlePlanAdd(payload)}
        closeHandler={() => {
          setAddLoading(false);
          setReservationVisible(false);
        }}
      />
      {loading && (
        <SkeletonLoader
          count={3}
          className="preferred-restaurant__card search-result__card"
        />
      )}
      {!loading &&
        restaurants?.map((restaurant: any, i: number) => (
          <div
            className="preferred-restaurant__card search-result__card"
            key={i}
            onClick={() => {
              props.history.push(
                generatePath(appRoutes.RESTAURANT_DETAILS, {
                  id: restaurant.id,
                })
              );
            }}
          >
            <div className="preferred-restaurant__image">
              <img src={restaurant?.imageUrl || placeholder} alt="" />
            </div>
            <div className="preferred-restaurant__title-cost">
              <div
                className="preferred-restaurant__title"
                title={restaurant?.name}
              >
                {restaurant?.name || "NA"}
              </div>
              <div className="preferred-restaurant__cost">
                {restaurant?.costCode === "expensive"
                  ? "$$$"
                  : restaurant?.costCode === "moderate"
                  ? "$$"
                  : "$"}
              </div>
            </div>
            <Rate
              className="preferred-restaurant__rating"
              disabled
              allowHalf
              value={restaurant?.overallRating || 0}
              key={i}
            />
            <div className="preferred-restaurant__park">
              <span className="icon-location icon" />{" "}
              {restaurant?.parkName || "NA"}
            </div>
            <div className="rides__card__footer">
              <div className="preferred-restaurant__cuisine search-card__cuisine">
                <span className="icon-cusine icon" />
                {restaurant?.cuisines
                  ?.map((cuisine: any) => cuisine.cuisineName)
                  .join(",") || "NA"}
              </div>
              <div className="rides__card__action">
                {restaurant?.planItemId ? (
                  <div
                    onClick={(event) => {
                      event.stopPropagation();
                      setRestaurant(restaurant);
                    }}
                  >
                    <UpdatePlanItem {...updatePlanItemProps} />
                  </div>
                ) : (
                  <Button
                    type="primary"
                    loading={addLoading && restaurant?.id === restaurantId}
                    onClick={(event) => {
                      setRestaurantId(restaurant?.id);
                      setRestaurant(restaurant);
                      setAddLoading(true);
                      restaurant?.isQuickServiceAvailable
                        ? addPlanItem({
                            attraction_type: "Restaurant",
                            attraction_id: restaurant?.id,
                            override: true,
                          })
                        : setReservationVisible(true);
                      event.stopPropagation();
                    }}
                  >
                    Add
                  </Button>
                )}
              </div>
              {restaurant?.planItemId && (
                <div className="default-plan-info">
                  This restaurant is added to plan{" "}
                  <b>{restaurant?.defaultPlanName}</b>
                </div>
              )}
            </div>
          </div>
        ))}
    </div>
  );
};

export default withRouter(RestaurantsList);
