import React, { FC } from "react";
import { Field, ErrorMessage } from "formik";
import { Select } from "antd";
import Error from "../Error";

interface DropdownFieldProps {
  onChange: Function;
  placeholder: string;
  options: Array<{ value: React.ReactText; label: React.ReactNode }>;
  name: string;
  mode?: any;
  className?: string | null;
  open?: boolean;
  onFocus?: Function;
  value?: any;
}

const DropdownField: FC<DropdownFieldProps> = (props) => {
  const { name } = props;
  return (
    <div className="cursor-pointer">
      <Field as={Select} {...props} />
      <ErrorMessage name={name}>
        {(message: string) => <Error message={message} />}
      </ErrorMessage>
    </div>
  );
};

export default DropdownField;
