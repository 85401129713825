import React from "react";
import AddMembersForm from "../Groups/AddMemberForm";
import UserService from "../../services/AuthService/auth.service";
import AuthContainer from "../../store/container/AuthContainer";
import { serialize } from "serializr";
import { User } from "../../models/user.model";
import { withRouter } from "react-router";
import { GroupMemberFormModel } from "../../models/group.model";
import { convertJSONToFormData } from "../../shared/utils/dataFormatConverter";

const UserUpdate = (props: any) => {
  console.log(props);
  const handleUserUpdate = (
    values: any,
    loaderFn: Function,
    imageObject: any
  ) => {
    loaderFn(true);
    const userData = serialize(GroupMemberFormModel, values);
    userData.username = `${values.firstName} ${values.lastName}`;
    userData.cuisine_ids = userData.cuisines;
    delete userData?.email;
    delete userData?.cuisines;

    let data = {
      user: userData,
      profile_pic: imageObject,
    };

    if (!imageObject) delete data.profile_pic;
    let userFormData = convertJSONToFormData(data);

    UserService.userUpdate(
      props?.user?.id,
      userFormData,
      () => {
        props?.history.goBack();
      },
      () => {},
      () => {
        loaderFn(false);
      }
    ).then();
  };

  return (
    <div className="user-component">
      <AddMembersForm user={props?.user} handleUserUpdate={handleUserUpdate} />
    </div>
  );
};

export default withRouter(AuthContainer(UserUpdate));
