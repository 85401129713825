import {alias, primitive, serializable} from "serializr";

export class BlogsModel{
    @serializable(alias('id',primitive()))
    id?: string
    @serializable(alias('title',primitive()))
    title?: string
    @serializable(alias('author',primitive()))
    author?: string
    @serializable(alias('status',primitive()))
    status?: string
    @serializable(alias('created_at',primitive()))
    createdAt?: string
    @serializable(alias('content',primitive()))
    content?: string
    @serializable(alias('image_url',primitive()))
    imageUrl?: string
    @serializable(alias('image_thumb_url',primitive()))
    imageThumbUrl?: string
    @serializable(alias('preview_text',primitive()))
    previewText?: string
}
