import { serializable, alias, primitive, list, object } from "serializr";
export class RidePreferencesModel {
  @serializable(alias("id", primitive()))
  id?: string;
  @serializable(alias("ride_type_id", primitive()))
  rideTypeId?: string;
  @serializable(alias("ride_type_name", primitive()))
  rideTypeName?: string;
  @serializable(alias("user_id", primitive()))
  userId?: string;
}
export class MobilityPreferencesModel {
  @serializable(alias("id", primitive()))
  id?: string;
  @serializable(alias("mobility_type_id", primitive()))
  mobilityTypeId?: string;
  @serializable(alias("mobility_type_name", primitive()))
  mobilityTypeName?: string;
  @serializable(alias("user_id", primitive()))
  userId?: string;
}

export class FoodPreferencesModel {
  @serializable(alias("id", primitive()))
  id?: string;
  @serializable(alias("food_type_id", primitive()))
  foodTypeId?: string;
  @serializable(alias("food_type_name", primitive()))
  foodTypeName?: string;
  @serializable(alias("user_id", primitive()))
  userId?: string;
}
export class groupMembersModel {
  @serializable(alias("id", primitive()))
  id?: string;
  @serializable(alias("profile_pic_thumb_url", primitive()))
  profilePicThumbUrl?: string;
  @serializable(alias("user_name", primitive()))
  username?: string;
  @serializable(alias("user_height", primitive()))
  userHeight?: string;
  @serializable(alias("user_id", primitive()))
  userId?: string;
  @serializable(alias("member_update", primitive()))
  memberUpdate?: string;
  @serializable(alias("ride_preferences", list(object(RidePreferencesModel))))
  ridePreferences?: RidePreferencesModel[] = [];
  @serializable(alias("mobility_preferences", list(object(MobilityPreferencesModel))))
  mobilityPreferences?: MobilityPreferencesModel[] = [];
  @serializable(alias("food_preferences", list(object(FoodPreferencesModel))))
  foodPreferences?: FoodPreferencesModel[] = [];
}

export class groupModel {
  @serializable(alias("id", primitive()))
  id?: string;
  @serializable(alias("name", primitive()))
  name?: string;
  @serializable(alias("created_at", primitive()))
  createdAt?: string;
  @serializable(alias("group_member_count", primitive()))
  groupMemberCount?: string;
  @serializable(alias("group_members", list(object(groupMembersModel))))
  groupMembers?: Array<any>;
}

export class invitesModel {
  @serializable(alias("id", primitive()))
  id?: string;
  @serializable(alias("group_id", primitive()))
  groupId?: string;
  @serializable(alias("user_id", primitive()))
  userId?: string;
  @serializable(alias("created_by_id", primitive()))
  createdById?: string;
  @serializable(alias("status", primitive()))
  status?: string;
  @serializable(alias("group_name", primitive()))
  groupName?: string;
  @serializable(alias("user_name", primitive()))
  userName?: string;
  @serializable(alias("created_by_name", primitive()))
  createdByName?: string;
  @serializable(alias("user_profile_pic_thumb_url", primitive()))
  userProfilePicThumbUrl?: string;
  @serializable(alias("created_by_profile_pic_thumb_url", primitive()))
  createdByProfilePicThumbUrl?: string;
}

export class GroupMemberFormModel {
  @serializable(alias("first_name", primitive()))
  firstName?: string;
  @serializable(alias("last_name", primitive()))
  lastName?: string;
  @serializable(alias("height", primitive()))
  height?: string;
  @serializable(alias("send_invite", primitive()))
  sendInvite?: string;
  @serializable(alias("email", primitive()))
  email?: string;
  @serializable(alias("ride_type_ids", list(primitive())))
  ridePreferences?: string;
  @serializable(alias("mobility_type_ids", list(primitive())))
  mobilityPreferences?: string;
  @serializable(alias("cuisines", list(primitive())))
  foodPreferences?: string;
}
