import {Form, Formik} from "formik";
import {validationSchema} from "../LoginForm/LoginValidation";
import InputField from "../../../shared/components/InputField";
import {Button} from "antd";
import React, {useState} from "react";
import {User} from "../../../models/user.model";
import {serialize} from "serializr";
import Notification from "../../../shared/components/Notification";
import {NotificationTypes} from "../../../enums/notificationTypes";
import UserService from "../../../services/AuthService/auth.service";
import * as appRoutes from "../../../routes/routeConstants/appRoutes";
import SocialLogin from "../SocialLogin";
import {withRouter} from 'react-router-dom';

const signUpInitialValue = {
    username: "",
    email: "",
    password: "",
    passwordConfirmation: "",
}

const SignUpForm = (props:any) =>{

    const [loginLoader,setLoginLoader] = useState<boolean>(false)

    const onSignupSubmit = async(user: User) => {
        setLoginLoader(true);

        let userCredentials = {user: serialize(User,user)};

        if(user.password !== user.passwordConfirmation){
            Notification({ message: "Invalid request", description: "Passwords do not match",type: NotificationTypes.ERROR });
            setLoginLoader(false);
        }else {
            await UserService.signupUser(userCredentials,
                (user: any) => {
                    return props.history.push(appRoutes.REGISTER);
                }, (error: Error) => {
                    console.log(error.message);
                }, () => {
                    setLoginLoader(false)
                });
        }
    }

    return(
        <div className="signup-form">
            <div className="auth-switch">
                Already have an account? <span className="auth-switch__action" onClick={()=>{
                    props.history.push(appRoutes.LOGIN)
                }}>Sign in</span>
            </div>
            <div className="login-form__title">Sign Up</div>
            <Formik
                initialValues={signUpInitialValue}
                onSubmit={onSignupSubmit}
                validationSchema={validationSchema}
            >
                <Form>
                    <div className='login-form__field'>
                        <div className="label">Name</div>
                        <InputField type="text" name="username" placeholder="Enter name" />
                    </div>
                    <div className='login-form__field'>
                        <div className="label">Email</div>
                        <InputField type="email" name="email" placeholder="Enter email" />
                    </div>
                    <div className="login-form__field">
                        <div className="label">Password</div>
                        <InputField type="password" name="password" placeholder="Enter password" />
                    </div>
                    <div className="login-form__field">
                        <div className="label">Confirm Password</div>
                        <InputField type="password" name="passwordConfirmation" placeholder="Enter password again" />
                    </div>
                    <Button htmlType="submit" className="login-form__submit" loading={loginLoader}>Sign Up</Button>
                </Form>
            </Formik>
            <SocialLogin/>
        </div>
    )
}

export default withRouter(SignUpForm);
