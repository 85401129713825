import React from "react";
import './aboutUs.scss';
import AboutUsImage from "../../../assets/landing-page-assets/BDDFounders.png"

const AboutUs = (props:any) =>{

    const {scrollRef} = props;

    return(
        <div className='about-us' ref={scrollRef}>
            <div className='about-us__column'>
                <div className='about-us__title'>About Best Disney Day</div>
                <img src={AboutUsImage} alt='about-us-' className='about-us__image__mobile'/>
                <div className='about-us__content'>
                    <p>
                        Best Disney Day was started by two brothers, Reid (9) and Hunter (12).
                    </p>
                    <p>
                        We have been lucky enough to travel to Disney Parks many times with our family.
                        When our schools shut down in early 2020 we decided to take our Disney love and
                        expertise and get to work building a tool to help people get the most from their trips!
                    </p>
                    <p>
                        We designed the BDD app to be helpful for people who are traveling
                        to Disney World and Disneyland parks! The app will help you plan an
                        amazing trip and save you lots of time and and make your trip even
                        more magical when you’re in the parks!
                    </p>
                    <p>
                        25% of the proceeds from our in-app purchase is donated to charities that are near and
                        dear to our family, March of Dimes and the Make-a-Wish Foundation.
                    </p>
                </div>
            </div>
            <div className='about-us__column'>
                <img src={AboutUsImage} alt='about-us-' className='about-us__image'/>
            </div>
        </div>
    )
}

export default AboutUs;
