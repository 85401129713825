import {generatePath} from "react-router-dom";
import * as appRoutes from "../../../routes/routeConstants/appRoutes";
import placeholder from "../../../assets/Disneyworld.png";
import {Rate} from "antd";
import React, {FC} from "react";
import {withRouter,RouteComponentProps} from "react-router-dom";
import SkeletonLoader from "../../../shared/components/SkeletonLoader";

interface PreferredRestaurantProps extends RouteComponentProps{
    preferredRestaurants: Array<any>
    loading: boolean
}

const PreferredRestaurants:FC<PreferredRestaurantProps> = (props) =>{

    const {preferredRestaurants,loading} = props;

    return(
        <div className="preferred-restaurant__cards">
            {loading && <SkeletonLoader count={4}
                                        className="preferred-restaurant__card"/>
            }
            {!loading && preferredRestaurants?.map((restaurant:any,i:number)=>
                <div className="preferred-restaurant__card" key={i}
                     onClick={()=>{props.history.push(
                         generatePath(appRoutes.RESTAURANT_DETAILS,{id:restaurant.id}))}}>
                    <div className="preferred-restaurant__image">
                        <img src={restaurant?.imageUrl || placeholder} alt=''/>
                    </div>
                    <div className="preferred-restaurant__title-cost">
                        <div className="preferred-restaurant__title" title={restaurant?.name}>
                            {restaurant?.name || 'NA'}
                        </div>
                        <div className="preferred-restaurant__cost">
                            {restaurant?.costCode === 'expensive' ? '$$$' :
                                restaurant?.costCode === 'moderate' ? '$$' : '$'
                            }
                        </div>
                    </div>
                    <Rate className="preferred-restaurant__rating" disabled allowHalf
                          value={restaurant?.overallRating || 0} key={i}/>
                    <div className="preferred-restaurant__park">
                        <span className='icon-location icon'/> {restaurant?.parkName || 'NA'}
                    </div>
                    <div className="preferred-restaurant__cuisine">
                        <span className='icon-cusine icon'/>
                        {restaurant?.cuisines?.map((cuisine:any) =>cuisine.cuisineName).join(',') || 'NA'}
                    </div>
                </div>
            )}
        </div>
    )
}

export default withRouter(PreferredRestaurants);
