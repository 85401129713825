import React, { FC, useState } from "react";
import SkeletonLoader from "../../../shared/components/SkeletonLoader";
import { Dropdown, Menu } from "antd";
import {
  DeleteOutlined,
  EllipsisOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import avatarPlaceholder from "../../../assets/avatar_placeholder.png";
import { generatePath } from "react-router-dom";
import * as appRoutes from "../../../routes/routeConstants/appRoutes";
import { DeleteConfirmation } from "../../../shared/components/DeleteConfirmation";
import GroupService from "../../../services/GroupService/group.service";
import { withRouter, RouteComponentProps } from "react-router-dom";

interface GroupsListProps extends RouteComponentProps {
  groups: Array<any>;
  loading: boolean;
  refreshList: Function;
}

const GroupsList: FC<GroupsListProps> = (props) => {
  const { groups, loading, refreshList } = props;

  const [deleteVisible, setDeleteVisible] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [groupId, setGroupId] = useState<string>("");

  const handleDelete = () => {
    setButtonLoading(true);
    GroupService.removeGroup(
      groupId,
      () => {
        setDeleteVisible(false);
        refreshList();
        setButtonLoading(false);
      },
      () => {
        setButtonLoading(false);
      },
      () => {}
    ).then();
  };

  const menu = (
    <Menu>
      <Menu.Item
        key="1"
        onClick={() =>
          props.history.push(
            generatePath(appRoutes.GROUP_DETAILS, { id: groupId })
          )
        }
      >
        <EyeOutlined /> View
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={() => {
          setDeleteVisible(true);
        }}
      >
        <DeleteOutlined />
        Delete
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <DeleteConfirmation
        deleteVisible={deleteVisible}
        setDeleteVisible={setDeleteVisible}
        text="group"
        buttonLoading={buttonLoading}
        handleDelete={handleDelete}
      />
      {loading && (
        <SkeletonLoader count={3} className="manage-groups__details-card" />
      )}
      {!loading &&
        groups.map((group: any, i) => (
          <div
            className="manage-groups__details-card"
            key={i}
           
          >
            <Dropdown
              overlay={menu}
              placement="bottomCenter"
              trigger={["click"]}
              className="manage-groups__details--action"
              onVisibleChange={(visible) => {
                setGroupId(visible ? group.id : "");
              }}
            >
              <EllipsisOutlined onClick={(e) => e.stopPropagation()} />
            </Dropdown>
            <div
             onClick={() => {
              props.history.push(
                generatePath(appRoutes.GROUP_DETAILS, { id: group?.id })
              );
            }}
            >
              <div className="manage-groups__details-card--name">
                {group.name}
              </div>
              <div className="manage-groups__details-card--count">
                {group.groupMemberCount} people
              </div>
              <div className="manage-groups__details-card--members">
                {group?.groupMembers
                  ?.slice(0, 5)
                  .map((member: any, j: number) => (
                    <img
                      src={member.profilePicThumbUrl || avatarPlaceholder}
                      alt=""
                      key={j}
                    />
                  ))}
                {group.membersCount > 5 && (
                  <div className="image-overlay">
                    <div className="count">+{group.groupMemberCount - 4}</div>
                  </div>
                )}
              </div>
              <div className="manage-groups__details-card--created">
                Created on: {new Date(group.createdAt).toLocaleString() || "-"}
              </div>
            </div>
          </div>
        ))}
    </>
  );
};

export default withRouter(GroupsList);
