import React, { useEffect, useState } from "react";
import "./plans.scss";
import { Button, Tabs } from "antd";
import * as appRoutes from "../../routes/routeConstants/appRoutes";
import { generatePath } from "react-router-dom";
import { DeleteConfirmation } from "../../shared/components/DeleteConfirmation";
import "../../assets/app-icons/style.css";
import PlanService from "../../services/PlanService/plan.service";
import PlansList from "./PlansList";
import PlanForm from "./PlanForm";

const { TabPane } = Tabs;

const Plans = (props: any) => {
  const [upcomingPlans, setUpcomingPlans] = useState<Array<any>>([]);
  const [completedPlans, setCompletedPlans] = useState<Array<any>>([]);
  const [planId, setPlanId] = useState<string>("");
  const [deleteVisible, setDeleteVisible] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const [upcomingLoading, setUpcomingLoading] = useState<boolean>(false);
  const [completedLoading, setCompletedLoading] = useState<boolean>(false);

  useEffect(() => {
    plansListRefresh();
  }, []);

  const fetchPlans = (type: string) => {
    if (type === "upcoming") {
      setUpcomingLoading(true);
    } else {
      setCompletedLoading(true);
    }

    PlanService.fetchPlans(
      type,
      (plans: any) => {
        if (type === "upcoming") {
          setUpcomingPlans(plans);
        } else {
          setCompletedPlans(plans);
        }
      },
      () => {},
      () => {
        if (type === "upcoming") {
          setUpcomingLoading(false);
        } else {
          setCompletedLoading(false);
        }
      }
    ).then();
  };

  const plansListRefresh = () => {
    fetchPlans("upcoming");
    fetchPlans("completed");
  };

  const showPlanDetails = (id: any) => {
    props.history.push(
      generatePath(appRoutes.PLAN_DETAILS, { id: id ?? planId })
    );
  };

  const handleDelete = () => {
    setButtonLoading(true);
    PlanService.deletePlan(
      planId,
      () => {
        fetchPlans("upcoming");
        fetchPlans("completed");
        setDeleteVisible(false);
      },
      () => {},
      () => {
        setButtonLoading(false);
      }
    ).then();
  };

  const formProps = {
    showDrawer: showDrawer,
    setShowDrawer: setShowDrawer,
    plansListRefresh: plansListRefresh,
  };

  return (
    <div className="user-component">
      <PlanForm {...formProps} />
      <div className="landing-card">
        <div className="landing-card__content">
          <div className="landing-card__content-title">My Plans</div>
          <div className="landing-card__content-details">
            Create, view, and edit plans to help you have the Best Disney Day!
          </div>
        </div>
        <div className="landing-card__action">
          <Button
            type="primary"
            key="add"
            className="landing-card__action--button"
            onClick={() => setShowDrawer(true)}
          >
            + Add New
          </Button>
        </div>
      </div>
      <div className="manage-plans">
        <DeleteConfirmation
          deleteVisible={deleteVisible}
          setDeleteVisible={setDeleteVisible}
          text="plan"
          buttonLoading={buttonLoading}
          handleDelete={handleDelete}
        />
        <Tabs defaultActiveKey="1" tabBarGutter={15}>
          <TabPane tab="Upcoming Plans" key="1">
            <PlansList
              plans={upcomingPlans}
              setPlanId={setPlanId}
              setDeleteVisible={setDeleteVisible}
              showPlanDetails={showPlanDetails}
              loading={upcomingLoading}
            />
          </TabPane>
          <TabPane tab="Completed Plans" key="2">
            <PlansList
              plans={completedPlans}
              setPlanId={setPlanId}
              setDeleteVisible={setDeleteVisible}
              showPlanDetails={showPlanDetails}
              loading={completedLoading}
            />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default Plans;
