import React, {useEffect, useState} from "react";
import '../rideDetails.scss';
import {Divider} from "antd";


const PhotopassMap = (props:any) => {

    const {ride} = props;

    return (
        <div className='photopass-map'>
            <div className='photopass-map__block'>
                <div className='photopass-map__title'>
                    Photopass
                </div>
                <div className='photopass-map__content'>
                    <div className='photopass-map__content__title'>{ride?.shortName}</div>
                    <div className='photopass-map__content__desc'>
                        {ride?.photopassDesc}
                    </div>
                </div>
                <div className='photopass-map__image'>
                    <img src={ride?.photopassThumbUrl} alt=''/>
                </div>
            </div>
            <Divider />
        </div>
    )
}

export default PhotopassMap;
