import React, {useEffect, useState} from "react";
import '../groups.scss';
import avatarPlaceholder from "../../../assets/avatar_placeholder.png";
import GroupService from "../../../services/GroupService/group.service";
import SkeletonLoader from "../../../shared/components/SkeletonLoader";

const InvitesSent = () => {

    const [invitesSent,setInvitesSent] = useState<Array<any>>([]);
    const [loading,setLoading] = useState<boolean>(false);

    useEffect(()=>{
        setLoading(true)
        GroupService.fetchInvites('sent',
            (invites: React.SetStateAction<any[]>)=>{
                setInvitesSent(invites)
            },
            ()=>{},
            ()=>{setLoading(false)},
        ).then()
    },[]);

    return (
        <>
            {loading && <SkeletonLoader count={3} className='manage-groups__invites'/>}
            {!loading && invitesSent.map((invite:any,i) =>
                <div className='manage-groups__invites' key={i}>
                    <img src={invite.userProfilePicThumbUrl || avatarPlaceholder}
                         alt='' className='manage-groups__invites--image'/>
                    <div className='manage-groups__invites--user-details'>
                        <div className='name'>{invite.userName}</div>
                        <div className='description'>{invite.createdByName} - {invite.groupName}</div>
                    </div>
                    <div className='manage-groups__invites--status'>{invite.status}</div>
                </div>
            )}
        </>
    )
}

export default InvitesSent;
