import {serializable, alias, primitive, list} from 'serializr';

export class WeathersModel{
    @serializable(alias('id', primitive()))
    id?: string;
    @serializable(alias('date', primitive()))
    date?: string;
    @serializable(alias('temp', primitive()))
    temp?: string;
    @serializable(alias('desc', primitive()))
    desc?: string;
    @serializable(alias('location_id', primitive()))
    locationId?: string;
    @serializable(alias('location_name', primitive()))
    locationName?: string;
    @serializable(alias('wind_speed', primitive()))
    windSpeed?: string;
    @serializable(alias('rain', primitive()))
    rain?: string;
    @serializable(alias('icon_url', primitive()))
    iconUrl?: string;
}

export class RainGearShopsModel{
    @serializable(alias('id', primitive()))
    id?: string;
    @serializable(alias('name', primitive()))
    name?: string;
    @serializable(alias('park_id', primitive()))
    parkId?: string;
    @serializable(alias('park_name', primitive()))
    parkName?: string;
    @serializable(alias('location_name', primitive()))
    locationName?: string;
    @serializable(alias('status', primitive()))
    status?: string;
    @serializable(alias('lat', primitive()))
    lat?: string;
    @serializable(alias('long', primitive()))
    long?: string;
    @serializable(alias('desc', primitive()))
    desc?: string;
    @serializable(alias('purchasable_items', list(primitive())))
    purchasableItems?: string;
}
