import {  HAVE_FAST_PASS } from "../definitions/rideConstants";

export const setFastPass = (fastPass:boolean,fastPassStartTime:string) => {
    return {
        type: HAVE_FAST_PASS,
        payload: {
            haveFastPass: fastPass,
            fastPassStartTime: fastPassStartTime
        }
    }
}
