import React from "react";
import "./features.scss";
import feature1Icon from "../../../assets/landing-page-assets/feature1.png";
import feature2Icon from "../../../assets/landing-page-assets/feature2.png";
import feature3Icon from "../../../assets/landing-page-assets/feature3.png";
import feature4Icon from "../../../assets/landing-page-assets/feature4.png";
import feature5Icon from "../../../assets/landing-page-assets/feature5.png";
import { FC } from "react";
import Lottie from "react-lottie";
import todaysPlanIcon from "../../../assets/animation/todays-plan.json";
import myPlansIcon from "../../../assets/animation/my-plans.json";
import foodIcon from "../../../assets/animation/food.json";
import weatherIcon from "../../../assets/animation/weather.json";
import funAndGamesIcon from "../../../assets/animation/fun-and-games.json";
interface LottieAnimationProps {
  data: any;
}
export const LottieAnimation: FC<LottieAnimationProps> = ({ data }) => {
  return (
    <Lottie
      options={{
        loop: true,
        autoplay: true,
        animationData: data,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      }}
      height={"100%"}
      width={"90%"}
    />
  );
};
const Features = () => {
  return (
    <div className="features">
      <div className="features__title">Features</div>
      <div className="features__list">
        <div className="features__card">
          <div className="feature__icon features__icon1">
            {/* <img src={feature1Icon} alt="" className="features__icon1" /> */}
            <LottieAnimation data={todaysPlanIcon} />
          </div>
          <div className="features__name">Today's Plan</div>
        </div>
        <div className="features__card">
          <div className="feature__icon features__icon2">
            {/* <img src={feature2Icon} alt="" className="features__icon2" /> */}
            <LottieAnimation data={myPlansIcon} />
          </div>
          <div className="features__name">My Plans</div>
        </div>
        <div className="features__card">
          <div className="feature__icon features__icon3">
            {/* <img src={feature3Icon} alt="" className="features__icon3" /> */}
            <LottieAnimation data={foodIcon} />
          </div>
          <div className="features__name">Food</div>
        </div>
        <div className="features__card">
          <div className="feature__icon features__icon4">
            {/* <img src={feature4Icon} alt="" className="features__icon4" /> */}
            <LottieAnimation data={weatherIcon} />
          </div>
          <div className="features__name">Weather</div>
        </div>
        <div className="features__card">
          <div className="feature__icon features__icon5">
            {/* <img src={feature5Icon} alt="" className="features__icon5" /> */}
            <LottieAnimation data={funAndGamesIcon} />
          </div>
          <div className="features__name">Fun + Games</div>
        </div>
      </div>
    </div>
  );
};

export default Features;
