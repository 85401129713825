import {Button, Checkbox, Divider, Input, Modal, Select} from "antd";
import {CloseCircleFilled} from "@ant-design/icons";
import React, {useEffect, useState} from "react";
import ChecklistService from "../../../services/ChecklistService/checklist.service";
import {constructSelectOptions} from "../../../shared/utils/fetchMetaValues";

const ChecklistSubheadingsForm = (props:any)=>{

    const {showModal, setShowModal, checklistId, refreshChecklistDetails} = props;

    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const [newSubheadingName, setNewSubheadingName] = useState<string>('');
    const [templates,setTemplates] = useState<Array<any>>([]);
    const [templateOptions,setTemplateOptions] = useState<Array<any>>([]);
    const [selectedTemplateItems, setSelectedItems] = useState<any>([]);
    const [selectedSubheading, setSelectedSubheading] = useState<any>(null);

    useEffect(()=>{
        ChecklistService.getSubheadingTemplates(
            (templates:any)=>{
                setTemplates([...templates])
                constructSelectOptions(templates,setTemplateOptions)
            },
            ()=>{},
            ()=>{},
        ).then()
    },[])

    const createNewSubheading = () =>{
        let name: string;
        let subheadingItems: any[];

        if(selectedTemplateItems.length > 0 && !newSubheadingName){
            let template = templates.filter(template => template.id === selectedSubheading)[0]
            name = template?.name
            subheadingItems = selectedTemplateItems
        }else{
            name = newSubheadingName;
            subheadingItems= [];
        }

        let data={
            sub_heading:{
                name:name,
                items:subheadingItems
            }
        }

        setButtonLoading(true)
        ChecklistService.createChecklistSubheading(checklistId,data,
            ()=>{
                setShowModal(false)
                setNewSubheadingName('')
                setSelectedItems([])
                setSelectedSubheading(null)
                refreshChecklistDetails();
            },
            ()=>{},
            ()=>{setButtonLoading(false)},
        ).then()
    }

    const handleTemplateAdd = (e:any) =>{
        let items = selectedTemplateItems;
        if(e.target.checked){
            items.push({name:e.target.name})
        }else{
            items = items.filter((item:any) => item.name !== e.target.name)
        }
        setSelectedItems(items)
    }

    return(
        <Modal
            title={null}
            visible={showModal}
            footer={null}
            closable={false}
            className='new-group-modal new-subheading-modal'
        >
            <div className='new-group-modal__title new-subheading-modal__title'>New Subheading</div>
            <div className='new-subheading-modal__field'>
                <div className='label'>Create Own Subheading</div>
                <Input placeholder='Example: Basic Essentials'
                       type='text' className='new-group-modal__input' value={newSubheadingName}
                       onChange={(event)=>{
                           setNewSubheadingName(event.target.value);
                           setSelectedSubheading(null)
                       }}/>
            </div>
            <Divider>or</Divider>
            <div className='new-subheading-modal__field'>
                <div className='label'>Common Lists</div>
                <Select options={templateOptions} placeholder='Select an existing Subheading'
                        className='plan-form__group-selector checklist-plan__select'
                        onChange={(value:any)=>{
                            setSelectedSubheading(value);
                            setNewSubheadingName('')
                        }}
                        value={selectedSubheading}/>
                {selectedSubheading &&
                <div>
                    {templates?.map((template:any)=> {
                            if (template.id === selectedSubheading) {
                                return template?.items?.map((item: any, i: number) =>
                                    <Checkbox key={i} className='template-item' name={item}
                                              onChange={(e:any)=>handleTemplateAdd(e)}>
                                        {item}
                                    </Checkbox>
                                )
                            }
                        }
                    )}
                </div>}
            </div>
            <div className='new-subheading-modal__action'>
                <Button key='cancel' type='text'
                        onClick={()=>setShowModal(false)}>Cancel</Button>
                <Button key='save' type='primary' disabled={!(newSubheadingName || selectedSubheading)}
                        loading={buttonLoading} onClick={createNewSubheading}>Save</Button>
            </div>
            <CloseCircleFilled className='new-group-modal__close' onClick={()=>setShowModal(false)}/>
        </Modal>
    )
}

export default ChecklistSubheadingsForm;
