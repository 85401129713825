import React, {useRef} from "react";
import './landing.scss';
import LandingNavBar from "./LandingNavBar";
import Section1 from "./Section1";
import AboutUs from "./AboutUs";
import Features from "./Features";
import MaximizeTime from "./MaximizeTime";
import Explore from "./Explore";
import BlogsList from "../Blogs/BlogsList";
import FAQ from "./FAQ";
import bddLogo from "../../assets/bdd-logo.png";
import {Button} from "antd";
import {withRouter} from "react-router-dom";
import * as appRoutes from "../../routes/routeConstants/appRoutes";
import facebookIcon from "../../assets/landing-page-assets/facebook.png";
import twitterIcon from "../../assets/landing-page-assets/twitter.png";
import instagramIcon from "../../assets/landing-page-assets/instagram.png";

const Landing = (props:any) => {

    const blogsRef = useRef<HTMLDivElement>(null);
    const appsRef = useRef<HTMLDivElement>(null);
    const faqRef = useRef<HTMLDivElement>(null);
    const aboutUsRef = useRef<HTMLDivElement>(null);

    const handleBlogScroll = () => blogsRef?.current?.scrollIntoView({ behavior: 'smooth' })
    const handleAppScroll = () => appsRef?.current?.scrollIntoView({ behavior: 'smooth' })
    const handleFAQScroll = () => faqRef?.current?.scrollIntoView({ behavior: 'smooth' })
    const handleAboutUsScroll = () => aboutUsRef?.current?.scrollIntoView({ behavior: 'smooth' })

    return(
        <div className='landing-page'>
            <LandingNavBar  appScroll={handleAppScroll}
                            blogScroll={handleBlogScroll}
                            faqScroll={handleFAQScroll}
                            aboutUsScroll={handleAboutUsScroll}
            />
            <Section1 scrollRef={appsRef}/>
            <AboutUs scrollRef={aboutUsRef}/>
            <Features/>
            <MaximizeTime/>
            <Explore/>
            <div className="recent-blogs" ref={blogsRef}>
                <BlogsList title='Recent Blogs' limit={3}/>
            </div>
            <FAQ scrollRef={faqRef}/>
            <div className='landing-page__footer'>
                <div className='landing-page__footer__content'>
                    <div className='landing-page__footer__left'>
                        © 2021 MyBDD, LLC. All Rights Reserved
                    </div>
                    <div className='landing-page__footer__center'>
                        <a href='https://www.facebook.com/BestDisneyDay/' target='_blank'>
                            <img src={facebookIcon} alt=''/>
                        </a>
                        <a href='https://twitter.com/MyBestDisneyDay' target='_blank'>
                            <img src={twitterIcon} alt=''/>
                        </a>
                        <a href='https://www.instagram.com/mybestdisneyday/' target='_blank'>
                            <img src={instagramIcon} alt=''/>
                        </a>
                    </div>
                    <div className='landing-page__footer__right'>
                        <span onClick={()=>props.history.push(appRoutes.PRIVACY_POLICY)}>
                            Privacy Policy
                        </span>
                        <span onClick={()=>props.history.push(appRoutes.TERMS_AND_CONDITIONS)}>
                            Terms Of Service
                        </span>
                    </div>
                </div>
                <div className="landing-page__footer__mobile">
                    <img src={bddLogo} alt='bdd-'/>
                    <Button type='primary' key='download-footer'
                            className='landing-page__footer__download'
                            href='https://bestdisneyday.page.link/tobR'
                    >
                        Download the App
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default withRouter(Landing);
