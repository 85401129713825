import ConstructFilters from "../ConstructFilterOptions";
import { Button, Checkbox } from "antd";
import React, { FC, useEffect, useState } from "react";
import { fetchMetaValues } from "../../../../shared/utils/fetchMetaValues";
import RestaurantService from "../../../../services/RestaurantService/restaurant.service";
import AuthContainer from "../../../../store/container/AuthContainer";
import { deserialize } from "serializr";
import { MetaModel } from "../../../../models/common.model";

interface RestaurantFiltersProps {
  filterRestaurants: Function;
  applyHandler: Function;
  closeHandler: Function;
  submitLoader: boolean;
}

interface AuthProps {
  user: any;
}

const RestaurantFilters: FC<RestaurantFiltersProps & AuthProps> = (props) => {
  const { filterRestaurants, applyHandler, closeHandler, submitLoader, user } = props;

  const [cuisines, setCuisines] = useState<any>([]);
  const [parks, setParks] = useState<any>([]);

  const [selectedCuisine, setSelectedCuisine] = useState<any>([]);
  const [selectedAlcohol, setSelectedAlcohol] = useState<any>(null);
  const [selectedPrice, setSelectedPrice] = useState<any>([]);
  const [selectedParks, setSelectedParks] = useState<any>([]);

  useEffect(() => {
    fetchMetaValues("cuisines", setCuisines);
    RestaurantService.fetchParks(
      user?.defaultLocationId,
      (parks: any) => {
        let response = deserialize(MetaModel, parks)
        setParks(response);
      },
      () => {},
      () => {}
    ).then();
  }, []);

  const alcoholServed = [
    { id: true, name: "Yes" },
    { id: false, name: "No" },
  ];

  const priceOptions = [
    { id: "inexpensive", name: "$" },
    { id: "moderate", name: "$$" },
    { id: "expensive", name: "$$$" },
  ];

  const handleSingleSelection = (e: any, id: string, setState: Function) => {
    setState(e.target.checked ? id : null);
  };

  const applyFilter = () => {
    let filterQuery = "";
    selectedCuisine.map((cuisine: string) => {
      filterQuery = filterQuery + `&cuisine_ids[]=${cuisine}`;
    });
    selectedPrice.map((price: string) => {
      filterQuery = filterQuery + `&cost_codes[]=${price}`;
    });
    if (selectedAlcohol !== null) {
      filterQuery = filterQuery + `&wine_list=${selectedAlcohol}`;
    }
    if (selectedParks !== null) {
      filterQuery = filterQuery + `&park_ids[]=${selectedParks}`;
    }
    filterRestaurants(filterQuery);
  };

  const resetFilters = () => {
    setSelectedCuisine([]);
    setSelectedAlcohol(null);
    setSelectedPrice([]);
    setSelectedParks([]);
    // setSelectedPark(null);
    filterRestaurants("");
  };

  return (
    <div>
      <div className="attraction-filter__block parks1">
        <div className="attraction-filter__block__title">Parks</div>
        <ConstructFilters
          options={parks}
          selectedOptions={selectedParks}
          setSelectedOptions={setSelectedParks}
        />
        {/* {parks.map((park:any,i:number)=>
                    <div className='attraction-filter__options' key={i}>
                        {park.icon_url?<img src={park.icon_url} className="attraction-filter__option-icon"></img>:<></>}
                        {park.name}
                        <span className='checkbox_space'>
                        <Checkbox checked={park.id === selectedPark} onChange={(e:any)=>{
                            handleSingleSelection(e,park.id,setSelectedPark)
                        }}></Checkbox></span>
                    </div>
                )} */}
      </div>
      <div className="attraction-filter__block cuisines">
        <div className="attraction-filter__block__title">Food style</div>
        <ConstructFilters
          options={cuisines}
          selectedOptions={selectedCuisine}
          setSelectedOptions={setSelectedCuisine}
          className="cuisine-options"
        />
      </div>
      <div className="attraction-filter__block themes">
        <div className="attraction-filter__block__title">Alcohol served</div>
        {alcoholServed.map((alcohol: any, i: number) => (
          <div className="attraction-filter__options" key={i}>
            {alcohol.iconUrl ? (
              <img
                src={alcohol.iconUrl}
                className="attraction-filter__option-icon"
              ></img>
            ) : (
              <></>
            )}
            {alcohol.name}
            <span className="checkbox_space">
              <Checkbox
                checked={alcohol.id === selectedAlcohol}
                onChange={(e: any) => {
                  handleSingleSelection(e, alcohol.id, setSelectedAlcohol);
                }}
              ></Checkbox>
            </span>
          </div>
        ))}
      </div>
      <div className="attraction-filter__block themes">
        <div className="attraction-filter__block__title">Price</div>
        <ConstructFilters
          options={priceOptions}
          selectedOptions={selectedPrice}
          setSelectedOptions={setSelectedPrice}
        />
      </div>
      <div className="attraction-filter__actions">
        <Button
          type="text"
          key="clear-filter"
          onClick={() => {
            resetFilters();
          }}
          className="clear-filter__action"
        >
          Clear Filter
        </Button>
        <Button
          type="text"
          onClick={() => closeHandler()}
          key="res-cancel"
        >
          Cancel
        </Button>
        <Button
          type="primary"
          loading={submitLoader}
          key="res-apply"
          onClick={() => applyFilter()}
        >
          Apply
        </Button>
      </div>
    </div>
  );
};

export default AuthContainer(RestaurantFilters);
