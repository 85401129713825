import React from "react";
import './explore.scss';
import section1Image from "../../../assets/landing-page-assets/explore-image1.png";
import section2Image from "../../../assets/landing-page-assets/explore-image2.png";
import section3Image from "../../../assets/landing-page-assets/explore-image3.png";
import section4Image from "../../../assets/landing-page-assets/explore-image4.png";
import section1Icon from "../../../assets/landing-page-assets/explore-section1-icon.png";
import section2Icon from "../../../assets/landing-page-assets/feature1.png";
import footerImage from "../../../assets/landing-page-assets/explore-footer.png";
import backgroundIcon1 from "../../../assets/landing-page-assets/explore-bg-icon1.png";
import backgroundIcon2 from "../../../assets/landing-page-assets/explore-bg-icon2.png";
import backgroundIcon3 from "../../../assets/landing-page-assets/explore-bg-icon3.png";
import backgroundIcon4 from "../../../assets/landing-page-assets/explore-bg-icon4.png";
import backgroundIcon5 from "../../../assets/landing-page-assets/explore-bg-icon3.png";
import backgroundIcon6 from "../../../assets/landing-page-assets/explore-bg-icon4.png";
import backgroundIcon7 from "../../../assets/landing-page-assets/explore-bg-icon2.png";
import icon1 from "../../../assets/landing-page-assets/explore-icon1.png";
import icon2 from "../../../assets/landing-page-assets/explore-icon2.png";
import icon3 from "../../../assets/landing-page-assets/explore-icon3.png";
import icon4 from "../../../assets/landing-page-assets/explore-icon4.png";
import icon5 from "../../../assets/landing-page-assets/explore-icon5.png";
import icon6 from "../../../assets/landing-page-assets/explore-icon6.png";
import icon7 from "../../../assets/landing-page-assets/explore-icon7.png";
import icon8 from "../../../assets/landing-page-assets/explore-icon8.png";
import icon9 from "../../../assets/landing-page-assets/explore-icon9.png";
import icon10 from "../../../assets/landing-page-assets/explore-icon10.png";
import icon11 from "../../../assets/plans-ride-tracker.png";
import icon12 from "../../../assets/landing-page-assets/explore-icon12.png";
import icon13 from "../../../assets/landing-page-assets/explore-icon13.png";
import icon14 from "../../../assets/landing-page-assets/feature5.png";
import icon15 from "../../../assets/All-restaurants.png";

const Explore = () => {
    return(
        <div className='explore'>
            <div className='explore__title'>Explore the App</div>
            <div className='explore__desktop'>
                <div className='explore__row'>
                    <div className='explore__column'>
                        <img src={section1Icon} alt='' className='explore__section__section1-icon'/>
                        <div className="explore__section-header">Plan your Best Disney Day!</div>
                        <div className="explore__section-content">
                            Our full-featured app allows you to
                            easily customize your Best Disney Day at Disney World or
                            Disneyland theme parks! You can plan in advance or build
                            the perfect day while on the go in the parks.
                        </div>
                    </div>
                    <div className='explore__column'>
                        <img src={section1Image} alt='' className='explore__column__section1-image'/>
                    </div>
                </div>
                <div className='explore__row'>
                    <div className='explore__column'>
                        <img src={section2Image} alt='' className='explore__column__section2-image'/>
                    </div>
                    <div className='explore__column plan-column'>
                        <img src={section2Icon} alt='' className='explore__section__section2-icon'/>
                        <div className="explore__section-header">Today's Plan</div>
                        <div className="explore__section-content">
                            Select the attractions and food for your day and
                            the app will configure a day based on wait times and
                            changes in the weather.
                        </div>
                    </div>
                </div>
                <div className='explore__row'>
                    <div className='explore__column theme-column'>
                        <div className="explore__section-header">Select your Theme</div>
                        <div className="explore__section-content">
                            Search for popular themes like Pirates or Princesses and
                            easily add those rides and experiences to your day’s plan
                            with just a tap.
                        </div>
                    </div>
                    <div className='explore__column'>
                        <img src={section3Image} alt='' className='explore__column__section3-image'/>
                    </div>
                </div>
                <div className='explore__row'>
                    <div className='explore__column'>
                        <img src={section4Image} alt='' className='explore__column__section4-image'/>
                    </div>
                    <div className='explore__column preference-column'>
                        <div className="explore__section-header">Your Preferences</div>
                        <div className="explore__section-content">
                            Want the most direct route? Let us plan it out for you and
                            save you extra walking! Prefer to get in more rides?
                            We will track the wait times and notify you to maximize
                            your day in the parks.
                        </div>
                    </div>
                </div>
            </div>
            <div className='explore__mobile'>
                <div className='explore__row'>
                    <div className="explore__section-header">Plan your Best Disney Day!</div>
                    <div className="explore__section-content">
                        Our full-featured app allows you to
                        easily customize your Best Disney Day at Disney World or
                        Disneyland theme parks! You can plan in advance or build
                        the perfect day while on the go in the parks.
                    </div>
                    <img src={section1Icon} alt='' className='explore__section__section1-icon'/>
                    <img src={section1Image} alt='' className='explore__column__section1-image'/>
                </div>
                <div className='explore__row'>
                    <div className="explore__section-header">Today's Plan</div>
                    <div className="explore__section-content">
                        Select the attractions and food for your day and
                        the app will configure a day based on wait times and
                        changes in the weather.
                    </div>
                    <img src={section2Icon} alt='' className='explore__section__section2-icon'/>
                    <img src={section2Image} alt='' className='explore__column__section2-image'/>
                </div>
                <div className='explore__row'>
                    <div className="explore__section-header">Select your Theme</div>
                    <div className="explore__section-content">
                        Search for popular themes like Pirates or Princesses and
                        easily add those rides and experiences to your day’s plan
                        with just a tap.
                    </div>
                    <img src={section3Image} alt='' className='explore__column__section3-image'/>
                </div>
                <div className='explore__row'>
                    <div className="explore__section-header">Your Preferences</div>
                    <div className="explore__section-content">
                        Want the most direct route? Let us plan it out for you and
                        save you extra walking! Prefer to get in more rides?
                        We will track the wait times and notify you to maximize
                        your day in the parks.
                    </div>
                    <img src={section4Image} alt='' className='explore__column__section4-image'/>
                </div>
            </div>
            <div className='explore__footer-image'>
                <img src={footerImage} alt=''/>
            </div>
            <img src={backgroundIcon1} alt='icon-' className='explore__bg-icon1'/>
            <img src={backgroundIcon2} alt='icon-' className='explore__bg-icon2'/>
            <img src={backgroundIcon3} alt='icon-' className='explore__bg-icon3'/>
            <img src={backgroundIcon4} alt='icon-' className='explore__bg-icon4'/>
            <img src={backgroundIcon5} alt='icon-' className='explore__bg-icon5'/>
            <img src={backgroundIcon6} alt='icon-' className='explore__bg-icon6'/>
            <img src={backgroundIcon7} alt='icon-' className='explore__bg-icon7'/>
            <img src={icon1} alt='icon-' className='explore__icon1 explore__icon'/>
            <img src={icon2} alt='icon-' className='explore__icon2 explore__icon'/>
            <img src={icon3} alt='icon-' className='explore__icon3 explore__icon'/>
            <img src={icon4} alt='icon-' className='explore__icon4 explore__icon'/>
            <img src={icon5} alt='icon-' className='explore__icon5 explore__icon'/>
            <img src={icon6} alt='icon-' className='explore__icon6 explore__icon'/>
            <img src={icon7} alt='icon-' className='explore__icon7 explore__icon'/>
            <img src={icon8} alt='icon-' className='explore__icon8 explore__icon'/>
            <img src={icon9} alt='icon-' className='explore__icon9 explore__icon'/>
            <img src={icon10} alt='icon-' className='explore__icon10 explore__icon'/>
            <img src={icon11} alt='icon-' className='explore__icon11 explore__icon'/>
            <img src={icon12} alt='icon-' className='explore__icon12 explore__icon'/>
            <img src={icon13} alt='icon-' className='explore__icon13 explore__icon'/>
            <img src={icon14} alt='icon-' className='explore__icon14 explore__icon'/>
            <img src={icon15} alt='icon-' className='explore__icon15 explore__icon'/>
        </div>
    )
}

export default Explore;
