import React, {useEffect} from "react";
import { Menu } from 'antd';
import "./navbar.scss";
import AuthContainer from "../../../store/container/AuthContainer";
import UserService from "../../../services/AuthService/auth.service";
import * as appRoutes from '../../../routes/routeConstants/appRoutes';
import {withRouter} from "react-router";
import bddLogo from "../../../assets/bdd-logo.png";
import avatarPlaceholder from "../../../assets/avatar_placeholder.png";
import SubMenu from "antd/lib/menu/SubMenu";

const Navbar = (props: any) => {

    useEffect(()=>{
        console.log(props?.user)
    },[props])

  const handleClick = async() => {
    await UserService.logoutUser(
        (res: any) => {
          props.setUnAuthenticated();
          props.history.push(appRoutes.AUTH)
        }, (error: Error) => {
          console.log(error.message);
        }, () => {
        });
  };

  return (
      props.authenticated &&
      !props.location.pathname.includes('auth') && !props.location.pathname.includes('register')?
      <div className='nav-bar'>
          <img src={bddLogo} alt='bdd-logo' className='bdd-logo'
               onClick={()=>{props.history.push(appRoutes.HOME)}}/>
          <Menu mode="horizontal" className='nav-bar-items'>
              <Menu.Item className="nav-bar-item" key="home" onClick={()=>{props.history.push(appRoutes.HOME)}}>
                Home
              </Menu.Item>
              <Menu.Item className="nav-bar-item" key="plans" onClick={()=>{props.history.push(appRoutes.PLANS)}}>
                My Plans
              </Menu.Item>
              <Menu.Item className="nav-bar-item" key="groups" onClick={()=>{props.history.push(appRoutes.GROUP)}}>
                My Groups
              </Menu.Item>
              <Menu.Item className="nav-bar-item" key="blog" onClick={()=>{props.history.push(appRoutes.BLOGS)}}>
                Blog
              </Menu.Item>
              <SubMenu key="SubMenu"
                       icon={<img src={props.user?.profilePicThumbUrl || avatarPlaceholder}
                                  alt='user'
                                  className='user-image'
                            />}
                       title={`${props.user?.username}`}>
                  <Menu.ItemGroup>
                      <Menu.Item className="nav-bar-item logout" key="edit-profile" onClick={()=>{
                          props?.history.push(appRoutes.USER_UPDATE)
                      }}>
                          Edit profile
                      </Menu.Item>
                      <Menu.Item className="nav-bar-item logout" key="logout" onClick={handleClick}>
                           Logout
                      </Menu.Item>
                  </Menu.ItemGroup>
              </SubMenu>
          </Menu>
      </div> : ''
  );
}

export default withRouter(AuthContainer(Navbar));
