import axios from 'axios';
import { store } from "../store";
import { REQUEST_LOGOUT } from "../store/definitions/authConstants";
import * as ApiRoutes from '../routes/routeConstants/apiRoutes';

export const getHeaders = (): any => {
    let headers, user;
    if (localStorage.getItem('user')) {
        user = JSON.parse(localStorage.getItem('user') || '');
    }
    headers = {
        'Content-Type': 'application/json',
        'access-token': user && user?.adminAuthToken ? user.adminAuthToken : '',
        'client': user?.client ? user.client : '',
        'uid': user?.uid ? user.uid : '',
    };

    return headers;
};

const axiosInstance = axios.create({
    baseURL: ApiRoutes.BASE_URL,
    timeout: 20000,
});

axiosInstance.interceptors.request.use(function (config) {
    const headers = getHeaders();
    if(config.headers){
        config.headers['access-token'] = headers['access-token'];
        config.headers['client'] = headers['client'];
        config.headers['uid'] = headers['uid'];
    }else {
        config.headers = headers;
    }
    config.data = config.data ? config.data : {};
    return config;
});

axiosInstance.interceptors.response.use(
    (response): any => {
 
        return {
            headers: response.headers,
            data: response.data,
            message: response.statusText,
            status: response.status
        }
    },
    (error) => {
 
        const { response } = error;

        if (response?.status === 401) {
            store.dispatch({ type: REQUEST_LOGOUT, payload: 'Request for logout' });
        }
        return Promise.reject(response);
    }
);

export default axiosInstance;
