import React, { useEffect, useState} from "react";
import './blogDetails.scss';
import BlogsService from "../../../services/BlogsService/blogs.service";
import {withRouter} from "react-router-dom";
import {LeftCircleFilled} from "@ant-design/icons";
// @ts-ignore
import {CKEditor} from "@ckeditor/ckeditor5-react";
// @ts-ignore
import ClassicEditor from "ckeditor5-build-classic-plus";
import GlobalLoader from "../../../shared/components/GlobalLoader";

const BlogDetail = (props:any) => {

    const [blog,setBlog] = useState<any>();
    const [loading,setLoading] = useState<boolean>(false);

    useEffect(()=>{
        setLoading(true)
        BlogsService.fetchBlogDetail(props.match?.params?.id,
            (blog:any)=>{
                setBlog(blog)
            },
            ()=>{},
            ()=>{setLoading(false)}
        ).then()
    },[])

    return(
        <div className="user-component">
            {loading && <GlobalLoader/>}
            {!loading && <div className='blog-detail'>
                <div className='blog-detail__landing-card'>
                    <div className='blog-detail__landing-content'>
                        <div className='blog-detail__back-button'>
                            <LeftCircleFilled onClick={()=>props.history.goBack()}/>
                        </div>
                        <div className='blog-detail__title'>{blog?.title || '-'}</div>
                        <div className='landing-card__content-count'> {blog?.previewText}</div>
                    </div>
                    <div className='blog-detail__landing-image'>
                        <img src={blog?.imageUrl} alt=''/>
                    </div>
                </div>
                <div className='blog-detail__content'>
                    <CKEditor
                        editor={ClassicEditor}
                        config={{
                            isReadOnly: true
                        }}
                        data={blog?.content || ''}
                        disabled={true}
                    />
                </div>
            </div>}
        </div>
    )
}

export default withRouter(BlogDetail);
