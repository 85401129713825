import React, { useEffect, useState } from "react";
import "./searchResults.scss";
import { Button } from "antd";
import "../../assets/app-icons/style.css";
import Search from "antd/es/input/Search";
import SearchService from "../../services/SearchService/search.service";
import UpdateDefaultPlan from "../../shared/components/UpdateDefaultPlan";
import RidesList from "../Rides/RidesList";
import RestaurantsList from "../Restaurants/RestaurantsList";
import AttractionFilter from "./AttractionsFilter";
import RideService from "../../services/RideService/ride.service";
import AuthContainer from "../../store/container/AuthContainer";
import RestaurantService from "../../services/RestaurantService/restaurant.service";

const SearchResults = (props: any) => {
  const [searchText, setSearchText] = useState<string>("");
  const [rides, setRides] = useState<any>([]);
  const [restaurants, setRestaurants] = useState<any>([]);
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    let searchText = props.location?.state?.value || "";
    let parkFilter = props.location?.state?.parkFilter || "";

    if (searchText) {
      setSearchText(searchText);
      findAttractions(searchText);
    }
    if (props.location?.state?.isFilter) {
      setTimeout(() => {
        setShowDrawer(true);
      }, 200);
    }

    if (parkFilter) {
      let locationId = props.user.defaultLocationId;

      RestaurantService.fetchParks(
        locationId,
        (parks: any) => {
          if (parks.length > 0) {
            let filterQuery = `&park_id=${parks[0].id}`;
            RideService.fetchFilteredRides(
              locationId,
              filterQuery,
              (response: any) => {
                setRides(response);
              },
              () => {},
              () => {
                setLoading(false);
              }
            ).then();
          }
        },
        () => {},
        () => {}
      ).then();
    }
  }, []);

  const findAttractions = (searchText: string) => {
    setLoading(true);
    SearchService.searchAttractions(
      searchText,
      (rides: any, restaurants: any) => {
        setRides(rides);
        setRestaurants(restaurants);
      },
      () => {},
      () => {
        setLoading(false);
      }
    ).then();
  };

  const filterProps = {
    showDrawer: showDrawer,
    applyHandler: () => {
      setShowDrawer(false);
    },
    closeHandler: () => {
      if (props.location?.state?.isFilter) {
        props.history?.goBack();
      }
      setShowDrawer(false);
    },
    rideDisabled: false,
    foodDisabled: false,
    setRestaurantsList: setRestaurants,
    setRidesList: setRides,
  };

  const planProps = {
    refreshList: findAttractions,
  };

  const rideSuccessHandler = (ride: any) => {
    setRides(rides.map((o: any) => (o.id === ride.id ? ride : o)));
  };

  const restaurantSuccessHandler = (restaurant: any) => {
    setRestaurants(
      restaurants.map((o: any) => (o.id === restaurant.id ? restaurant : o))
    );
  };
  return (
    <div className="user-component">
      <AttractionFilter {...filterProps} />
      <div className="search-results__header">
        <div className="search-results_title">Search Results</div>
        <div className="search-results__search">
          <Search
            placeholder="Search"
            onSearch={findAttractions}
            value={searchText}
            onChange={(e: any) => {
              setSearchText(e.target.value);
            }}
            enterButton
            className="details__search"
          />
        </div>
        <Button
          type="primary"
          className="filters-button"
          onClick={() => setShowDrawer(true)}
        >
          <span className="icon-filter" /> Filters
        </Button>
      </div>
      <div className="results">
        <div>
          <div className="results__title" />
          <UpdateDefaultPlan {...planProps} />
        </div>
        <div>
          {(loading || rides?.length > 0) && (
            <div className="results__title">Rides</div>
          )}
          <RidesList
            rides={rides}
            loading={loading}
            successHandler={rideSuccessHandler}
          />
        </div>

        <div>
          {(loading || restaurants?.length > 0) && (
            <div className="results__title">Restaurants</div>
          )}
          <RestaurantsList
            restaurants={restaurants}
            loading={loading}
            successHandler={restaurantSuccessHandler}
          />
        </div>

        {!loading && rides?.length === 0 && restaurants?.length === 0 && (
          <div className="results__title">No results found</div>
        )}
      </div>
    </div>
  );
};

export default AuthContainer(SearchResults);
