import { Dropdown, Menu } from "antd";
import {
  DeleteOutlined,
  EllipsisOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import React, { FC } from "react";
import "../../../assets/app-icons/style.css";
import SkeletonLoader from "../../../shared/components/SkeletonLoader";
import dateIcon from "../../../assets/checklist-date.png";
import groupIcon from "../../../assets/solo.png";
import rideIcon from "../../../assets/plans-ride-tracker.png";
import moment from "moment";

interface PlansListProps {
  plans: Array<any>;
  setPlanId: Function;
  setDeleteVisible: Function;
  showPlanDetails: Function;
  loading: boolean;
}

const PlansList: FC<PlansListProps> = (props) => {
  const { plans, setPlanId, setDeleteVisible, showPlanDetails, loading } =
    props;

  const menu = (
    <Menu>
      <Menu.Item
        key="1"
        onClick={(e) => {
          showPlanDetails();
        }}
      >
        <EyeOutlined /> View
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={(e: any) => {
          setDeleteVisible(true);
        }}
      >
        <DeleteOutlined />
        Delete
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      {loading && <SkeletonLoader count={4} className="manage-plans-card" />}
      {!loading &&
        plans.map((plan: any, i: number) => (
          <div className="manage-plans-card" key={i}>
            <Dropdown
              overlay={menu}
              placement="bottomCenter"
              trigger={["click"]}
              className="manage-plans-card__action"
              onVisibleChange={(visible) => {
                setPlanId(visible ? plan.id : "");
              }}
            >
              <EllipsisOutlined onClick={(e) => e.stopPropagation()} />
            </Dropdown>
            <div
              onClick={() => {
                setPlanId(plan.id);
                showPlanDetails(plan?.id);
              }}
            >
              <div className="manage-plans-card__name">{plan.name}</div>
              <div className="manage-plans-card__field">
                <img src={dateIcon} alt="" className="icon-date" />
                {moment(plan.date).format("MM/DD/YYYY") || "-"}
              </div>
              <div className="manage-plans-card__field">
                <img src={groupIcon} alt="" className="icon-plan-name" />
                {plan.groupName || "Solo"}
              </div>
              <div className="manage-plans-card__field">
                <img src={rideIcon} alt="" className="icon-rides ride" />
                {plan.rideCount} rides
              </div>
            </div>
          </div>
        ))}
    </>
  );
};

export default PlansList;
