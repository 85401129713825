import React, { FC } from "react";
import { Checkbox } from "antd";
import { IconMap } from "antd/lib/result";

interface ConstructFiltersProps {
  options: Array<any>;
  selectedOptions: Array<any>;
  setSelectedOptions: Function;
  className?: string | null;
}

const ConstructFilters: FC<ConstructFiltersProps> = (props) => {
  const { options, selectedOptions, setSelectedOptions, className } = props;
  const handleSelection = (
    e: any,
    setState: Function,
    value: any,
    currentOptions: any
  ) => {
    let options = currentOptions;
    if (e.target.checked) {
      options.push(value);
    } else {
      options = options.filter((option: any) => option !== value);
    }
    setState([...options]);
  };

  return (
    <>
      {options.map((theme: any, i: number) => (
        <div
          className={
            className
              ? className + " attraction-filter__options"
              : "attraction-filter__options"
          }
          key={i}
        >
          <div>
            {theme.iconUrl ? (
              <img
                src={theme.iconUrl}
                className="attraction-filter__option-icon"
              ></img>
            ) : (
              <></>
            )}
            {theme.name}
            <span className="checkbox_space"></span>
          </div>

          <Checkbox
            checked={selectedOptions.includes(theme.id)}
            onChange={(e) => {
              handleSelection(e, setSelectedOptions, theme.id, selectedOptions);
            }}
          ></Checkbox>
        </div>
      ))}
    </>
  );
};

export default ConstructFilters;
