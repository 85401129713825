import React, {FC, useState} from "react";
import './attractionFilter.scss';
import {Drawer, Tabs} from "antd";
import {withRouter, RouteComponentProps} from 'react-router-dom';
import parkSelection from "../../../assets/park-selection.png";
import RideService from "../../../services/RideService/ride.service";
import RestaurantService from "../../../services/RestaurantService/restaurant.service";
import RideFilters from "./RideFilters";
import RestaurantFilters from "./RestaurantFilters";
import AuthContainer from "../../../store/container/AuthContainer";

const {TabPane} = Tabs

interface AttractionFilterProps extends RouteComponentProps{
    showDrawer: boolean;
    closeHandler: Function;
    applyHandler: Function;
    rideDisabled: boolean;
    foodDisabled: boolean;
    setRidesList?:Function | null;
    setRestaurantsList:Function;
    defaultRideFilters: any;
    defaultRestaurantFilters: any;
    // setDefaultFilters: Function
}

const AttractionFilter: FC<AttractionFilterProps> = (props:any) => {

    const {showDrawer, closeHandler, applyHandler, rideDisabled,foodDisabled,
        setRidesList,setRestaurantsList, user, setDefaultFilters, defaultRideFilters, defaultRestaurantFilters} = props;

    const defaultKey = rideDisabled ? '2' : '1';

    const [rideLoader,setRideLoader] = useState<boolean>(false);
    const [restaurantLoader,setRestaurantLoader] = useState<boolean>(false);

    const filterRides = (filterQuery:string) => {
        setRideLoader(true)
        RideService.fetchFilteredRides(user?.defaultLocationId,
            filterQuery,
            (rides:any)=>{
                setRestaurantsList([]);
                setRidesList(rides);
            },
            ()=>{},
            ()=>{
                applyHandler();
                setRideLoader(false)
            },
        ).then()
    }

    const filterRestaurants = (filterQuery:string) => {
        setRestaurantLoader(true)
        RestaurantService.fetchRestaurants(user?.defaultLocationId,
            filterQuery,
            (restaurants:any)=>{
                setRestaurantsList(restaurants);
                setRidesList([]);
            },
            ()=>{},
            ()=>{
                applyHandler();
                setRestaurantLoader(false)
            },
        ).then()
    }

    const rideProps = {
        filterRides: filterRides,
        applyHandler: applyHandler,
        closeHandler: closeHandler,
        submitLoader: rideLoader,
    }

    const restaurantProps = {
        filterRestaurants:filterRestaurants,
        applyHandler: applyHandler,
        closeHandler: closeHandler,
        submitLoader:restaurantLoader,
    }

    return(
        <div className='attraction-filter'>
            <Drawer
                className='attraction-filter__drawer'
                placement='right'
                closable={false}
                onClose={()=>{closeHandler()}}
                visible={showDrawer}
                key='attraction-filter'
            >
                <img src={parkSelection} alt='' className='attraction-filter__bg'/>
                <div className='attraction-filter__title'>Select Filters</div>
                <div className='attraction-filter__tabs'>
                    <Tabs defaultActiveKey={defaultKey} tabBarGutter={15}>
                        <TabPane tab="Attractions" key="1" disabled={rideDisabled}>
                            <RideFilters {...rideProps}/>
                        </TabPane>
                        <TabPane tab="Food" key="2" disabled={foodDisabled}>
                            <RestaurantFilters {...restaurantProps}/>
                        </TabPane>
                    </Tabs>
                </div>
            </Drawer>
        </div>
    )
}

export default withRouter(AuthContainer(AttractionFilter));
