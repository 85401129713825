import {Button} from "antd";
import React from "react";
import {LeftCircleFilled} from "@ant-design/icons";
import {withRouter} from 'react-router-dom';

interface LandingCardProps{
    backButtonEnabled:boolean
    name:string
    desc:string
    landingCardAction:Function
    buttonText?: string | null
}

const LandingCard = (props:any) => {

    const {backButtonEnabled,name,desc,landingCardAction,buttonText} = props;

    return(
        <div className='landing-card'>
            <div className='landing-card__content'>
                {backButtonEnabled &&
                <LeftCircleFilled  className='landing-card__content-back'
                                  onClick={()=>props.history.goBack()}/>}
                <div className='landing-card__content-title'>{name || 'NA'}</div>
                <div className='landing-card__content-details'>{desc || ''}</div>
            </div>
            <div className='landing-card__action'>
                <Button type='primary' key='add' className='landing-card__action--button'
                        onClick={()=>landingCardAction(true)}>+ Add New {buttonText || ''}</Button>
            </div>
        </div>
    )
}

export default withRouter(LandingCard);
