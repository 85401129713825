import React, {useEffect} from "react";
import './privacyPolicy.scss';

const PrivacyPolicy = () => {
    useEffect(()=>{
        window.scrollTo(0,0)
    },[])

    return(
        <div className='privacy-policy'>
            <h1 className='privacy-policy__header'>Privacy Policy</h1>

            <div className='privacy-policy__content'>
                Protecting your private information is our priority.
                This Statement of Privacy applies to BestDisneyDay.com and MyBDD, LLC and
                governs data collection and usage. For the purposes of this Privacy Policy,
                unless otherwise noted, all references to MyBDD, LLC include BestDisneyDay.com and Company.
                The Company website is a BestDisneyDay is a site for planning trips to two popular theme-parks.
                We offer advice, tools, and other information about the parks to help people save time and
                enjoy their trips even more. site. By using the Company website, you consent to the data
                practices described in this statement.
            </div>

            <h2>Collection of your Personal Information</h2>
            <div className='privacy-policy__content'>
                In order to better provide you with products and services offered on our Site,
                Company may collect personally identifiable information, such as your:
            </div>
            <ul>
                <li>First and Last Name</li>
                <li>E-mail Address</li>
            </ul>
            <div className='privacy-policy__content'>
                We do not collect any personal information about you unless you voluntarily provide it to us.
                However, you may be required to provide certain personal information to us when you elect
                to use certain products or services available on the Site. These may include:
                (a) registering for an account on our Site;
                (b) entering a sweepstakes or contest sponsored by us or one of our partners;
                (c) signing up for special offers from selected third parties;
                (d) sending us an email message;
                (e) submitting your credit card or other payment information when ordering and
                purchasing products and services on our Site. To wit, we will use your information for,
                but not limited to, communicating with you in relation to services and/or products you have
                requested from us. We also may gather additional personal or non-personal information in the
                future.
            </div>

            <h2>Use of your Personal Information</h2>
            <div className='privacy-policy__content'>
                Company collects and uses your personal information to operate its website(s) and
                deliver the services you have requested.
            </div>
            <div className='privacy-policy__content'>
                Company may also use your personally identifiable information to inform you of other
                products or services available from Company and its affiliates.
            </div>

            <h2>Sharing Information with Third Parties</h2>
            <div className='privacy-policy__content'>
                Company does not sell, rent or lease its customer lists to third parties.
            </div>
            <div className='privacy-policy__content'>
                Company may, from time to time, contact you on behalf of external business partners about a
                particular offering that may be of interest to you. In those cases, your unique personally
                identifiable information (e-mail, name, address, telephone number) is not transferred to
                the third party. Company may share data with trusted partners to help perform statistical
                analysis, send you email or postal mail, provide customer support, or arrange for deliveries.
                All such third parties are prohibited from using your personal information except to
                provide these services to Company, and they are required to maintain the confidentiality
                of your information.
            </div>
            <div className='privacy-policy__content'>
                Company may disclose your personal information, without notice, if required to do so by
                law or in the good faith belief that such action is necessary to: (a) conform to the
                edicts of the law or comply with legal process served on Company or the site; (b) protect
                and defend the rights or property of Company; and/or (c) act under exigent circumstances to
                protect the personal safety of users of Company, or the public.
            </div>


            <h2>Automatically Collected Information</h2>
            <div className='privacy-policy__content'>
                Information about your computer hardware and software may be automatically collected by
                Company. This information can include: your IP address, browser type, domain names,
                access times and referring website addresses. This information is used for the operation of
                the service, to maintain quality of the service, and to provide general statistics
                regarding use of the Company website.
            </div>

            <h2>Links</h2>
            <div className='privacy-policy__content'>
                This website contains links to other sites. Please be aware that we are not responsible
                for the content or privacy practices of such other sites. We encourage our users to be
                aware when they leave our site and to read the privacy statements of any other site that
                collects personally identifiable information.
            </div>

            <h2>Security of your Personal Information</h2>
            <div className='privacy-policy__content'>
                Company secures your personal information from unauthorized access, use, or disclosure.
                Company uses the following methods for this purpose:
            </div>
            <ul>
                <li>SSL Protocol</li>
            </ul>
            <div className='privacy-policy__content'>
                When personal information (such as a credit card number) is transmitted to other websites,
                it is protected through the use of encryption, such as the Secure Sockets Layer (SSL)
                protocol.
            </div>
            <div className='privacy-policy__content'>
                We strive to take appropriate security measures to protect against unauthorized access
                to or alteration of your personal information. Unfortunately, no data transmission over
                the Internet or any wireless network can be guaranteed to be 100% secure. As a result,
                while we strive to protect your personal information, you acknowledge that:
                (a) there are security and privacy limitations inherent to the Internet which are beyond
                our control; and
                (b) security, integrity, and privacy of any and all information and data exchanged
                between you and us through this Site cannot be guaranteed.
            </div>

            <h2>Right to Deletion</h2>
            <div className='privacy-policy__content'>
                Subject to certain exceptions set out below, on receipt of a verifiable request from you,
                we will:
            </div>
            <ul>
                <li>Delete your personal information from our records; and</li>

                <li>Direct any service providers to delete your personal information from their records.</li>
            </ul>
            <div className='privacy-policy__content'>
                Please note that we may not be able to comply with requests to delete your personal
                information if it is necessary to:
            </div>
            <ul>
                <li>
                    Complete the transaction for which the personal information was collected,
                    fulfill the terms of a written warranty or product recall conducted in accordance
                    with federal law, provide a good or service requested by you, or reasonably
                    anticipated within the context of our ongoing business relationship with you, or
                    otherwise perform a contract between you and us;
                </li>
                <li>
                    Detect security incidents, protect against malicious, deceptive, fraudulent, or
                    illegal activity; or prosecute those responsible for that activity;
                </li>
                <li>
                    Debug to identify and repair errors that impair existing intended functionality;
                </li>
                <li>
                    Exercise free speech, ensure the right of another consumer to exercise his or her
                    right of free speech, or exercise another right provided for by law;
                </li>
                <li>
                    Comply with the California Electronic Communications Privacy Act;
                </li>
                <li>
                    Engage in public or peer-reviewed scientific, historical, or statistical research in
                    the public interest that adheres to all other applicable ethics and privacy laws,
                    when our deletion of the information is likely to render impossible or seriously impair
                    the achievement of such research, provided we have obtained your informed consent;
                </li>
                <li>
                    Enable solely internal uses that are reasonably aligned with your expectations based
                    on your relationship with us;
                </li>
                <li>
                    Comply with an existing legal obligation; or
                </li>
                <li>
                    Otherwise use your personal information, internally, in a lawful manner that is
                    compatible with the context in which you provided the information.
                </li>
            </ul>

            <h2>Children Under Thirteen</h2>
            <div className='privacy-policy__content'>
                Company does not knowingly collect personally identifiable information from children under
                the age of thirteen. If you are under the age of thirteen, you must ask your parent or
                guardian for permission to use this website.
            </div>

            <h2>E-mail Communications</h2>
            <div className='privacy-policy__content'>
                From time to time, Company may contact you via email for the purpose of providing
                announcements, promotional offers, alerts, confirmations, surveys, and/or other general
                communication.
            </div>

            <h2>Changes to this Statement</h2>
            <div className='privacy-policy__content'>
                Company reserves the right to change this Privacy Policy from time to time. We will
                notify you about significant changes in the way we treat personal information by sending
                a notice to the primary email address specified in your account, by placing a prominent
                notice on our site, and/or by updating any privacy information on this page. Your continued
                use of the Site and/or Services available through this Site after such modifications
                will constitute your: (a) acknowledgment of the modified Privacy Policy; and
                (b) agreement to abide and be bound by that Policy.
            </div>

            <h2>Contact Information</h2>
            <div className='privacy-policy__content'>
                Company welcomes your questions or comments regarding this Statement of Privacy.
                If you believe that Company has not adhered to this Statement, please contact Company at:
            </div>
            <div className='privacy-policy__content'>
                <div>MyBDD, LLC</div>
                <div>info@BestDisneyDay.com</div>
            </div>
            <div className='privacy-policy__content'>
                Effective as of December 16, 2020
            </div>
        </div>
    )
}

export default PrivacyPolicy;
