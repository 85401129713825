import axiosInstance from "../../interceptor/axiosInstance";
import {
    CHECKLIST_SUBHEADINGS,
    CHECKLISTS, SUBHEADING_TEMPLATES
} from "../../routes/routeConstants/apiRoutes";
import { deserialize } from "serializr";
import {generatePath} from 'react-router-dom';
import Notification from "../../shared/components/Notification";
import {NotificationTypes} from "../../enums/notificationTypes";
import {ChecklistModel, SubHeadingsTemplateModel} from "../../models/checklist.model";

export default class ChecklistService {

    //fetch checklists
    static fetchChecklists(
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .get(CHECKLISTS)
            .then(response => {
                let checklists = deserialize(ChecklistModel, response.data['checklists']);
                onSuccess(checklists);
            })
            .catch(error => {
                onError(error);
            })
            .finally(onFinal)
    }

    //create checklist
    static createChecklist(
        data:any,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .post(CHECKLISTS,data)
            .then(response => {
                const checklist = deserialize(ChecklistModel, response?.data?.checklist)
                onSuccess(checklist)
                Notification({ message: "Success", description: "Created successfully", type: NotificationTypes.SUCCESS })
            })
            .catch(error => {
                Notification({ message: "Failed", description: error.data?.errors[0] || "Unable to create. Please try again",type: NotificationTypes.ERROR });
                onError(error);
            })
            .finally(onFinal)
    }

    //update checklist
    static updateChecklist(
        checklistId:string,
        data:any,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .put(CHECKLISTS+`/${checklistId}`,data)
            .then(response => {
                const checklist = deserialize(ChecklistModel, response?.data?.checklist)
                onSuccess(checklist)
                Notification({ message: "Success", description: "Updated successfully", type: NotificationTypes.SUCCESS })
            })
            .catch(error => {
                Notification({ message: "Failed", description: "Unable to update. Please try again",type: NotificationTypes.ERROR });
                onError(error);
            })
            .finally(onFinal)
    }

    //delete checklist
    static deleteChecklist(
        checklistId:string,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .delete(CHECKLISTS+`/${checklistId}`)
            .then(response => {
                onSuccess()
                Notification({ message: "Success", description: "Deleted successfully", type: NotificationTypes.SUCCESS })
            })
            .catch(error => {
                Notification({ message: "Failed", description: "Unable to delete. Please try again",type: NotificationTypes.ERROR });
                onError(error);
            })
            .finally(onFinal)
    }


    //get subheading templates
    static getSubheadingTemplates(
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .get(SUBHEADING_TEMPLATES)
            .then(response => {
                let subHeadingTemplates = deserialize(SubHeadingsTemplateModel,response.data['sub_heading_templates'])
                onSuccess(subHeadingTemplates)
            })
            .catch(error => {
                onError(error);
            })
            .finally(onFinal)
    }

    //get checklist details
    static getChecklistDetails(
        checklistId:string,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .get(CHECKLISTS+`/${checklistId}`)
            .then(response => {
                let checklist = deserialize(ChecklistModel,response.data['checklist'])
                onSuccess(checklist)
            })
            .catch(error => {
                onError(error);
            })
            .finally(onFinal)
    }

    //create checklist subheading
    static createChecklistSubheading(
        checklistId:string,
        data:any,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .post(generatePath(CHECKLIST_SUBHEADINGS,{id:checklistId}),data)
            .then(response => {
                onSuccess()
                Notification({ message: "Success", description: "Created successfully", type: NotificationTypes.SUCCESS })
            })
            .catch(error => {
                Notification({ message: "Failed", description: "Unable to create. Please try again",type: NotificationTypes.ERROR });
                onError(error);
            })
            .finally(onFinal)
    }

    //update checklist subheading
    static updateChecklistSubheading(
        checklistId:string,
        subheadingId:string,
        data:any,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .put((generatePath(CHECKLIST_SUBHEADINGS,{id:checklistId}))+`/${subheadingId}`,
                data)
            .then(response => {
                onSuccess()
                Notification({ message: "Success", description: "Updated successfully", type: NotificationTypes.SUCCESS })
            })
            .catch(error => {
                Notification({ message: "Failed", description: "Unable to update. Please try again",type: NotificationTypes.ERROR });
                onError(error);
            })
            .finally(onFinal)
    }

    //delete checklist subheading
    static deleteChecklistSubheading(
        checklistId:string,
        subheadingId:string,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .delete((generatePath(
                CHECKLIST_SUBHEADINGS,{id:checklistId}))+`/${subheadingId}`)
            .then(response => {
                onSuccess()
                Notification({ message: "Success", description: "Deleted successfully", type: NotificationTypes.SUCCESS })
            })
            .catch(error => {
                Notification({ message: "Failed", description: "Unable to delete. Please try again",type: NotificationTypes.ERROR });
                onError(error);
            })
            .finally(onFinal)
    }
}
