import CommonService from "../../services/CommonService/common.service";

export const fetchMetaValues = (metaName:string,setState: Function) =>{
    CommonService.fetchMeta(metaName,
        (response: any) => {
            setState([...response])
        },
        () => {},
        () => {}
    ).then(r =>{})
}

export const constructSelectOptions = (metaData:any, setState:Function) => {
    setState(metaData.map((meta: { name: any; id: any; iconUrl: any })=>{
        return({label:meta.name, value: meta.id, icon: meta?.iconUrl})
    })
    )
}
