import React, {FC, useEffect, useState} from "react";
import './blogsList.scss';
import BlogsService from "../../../services/BlogsService/blogs.service";
import {withRouter,generatePath,RouteComponentProps} from "react-router-dom";
import * as appRoutes from "../../../routes/routeConstants/appRoutes";
import SkeletonLoader from "../../../shared/components/SkeletonLoader";

interface BlogsListProps extends RouteComponentProps{
    title?: string;
    tag?: string;
    limit?: number;
}

const BlogsList: FC<BlogsListProps> = (props) => {

    const {title,tag,limit} = props;

    const [blogs,setBlogs] = useState<any>([]);
    const [loading,setLoading] = useState<boolean>(false)

    useEffect(()=>{
        setLoading(true)
        BlogsService.fetchBlogs(limit,tag,
            (blogs:any)=>{
                setBlogs(blogs)
            },
            ()=>{},
            ()=>{setLoading(false)},
        ).then()

    },[])

    return(
        <div className="blogs-list">
            <div className="blogs__header">{title}</div>
            <div>
                {loading && <SkeletonLoader count={3} className="blog__card"/>}
                {!loading && blogs.map((blog:any,i:number)=>
                    <div className='blog__card' key={i}
                         onClick={()=> props.history.push(
                             generatePath(appRoutes.BLOG_DETAIL,{id:blog.id})
                         )}>
                        <img src={blog?.imageThumbUrl} alt=''/>
                        <div className='blog__card__title'>{blog?.title}</div>
                        <div className='blog__card__author'>{blog?.author}</div>
                        <div className='blog__card__read-more'>Read more</div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default withRouter(BlogsList);
