import React, {useEffect, useState} from "react";
import '../rideTracker.scss';
import {Rate} from "antd";
import {LeftCircleFilled} from "@ant-design/icons";
import RideTrackerService from "../../../services/RideTrackerService/rideTracker.service";
import moment from 'moment';
import GlobalLoader from "../../../shared/components/GlobalLoader";

const RideTrackerDetails = (props: any) => {

    const [rideDetails,setRideDetails] = useState<any>('');
    const [trackRecordCount, setTrackRecordCount] = useState<any>(0);
    const [loading,setLoading] = useState<boolean>(false)

    useEffect(()=>{
        setLoading(true)
        const {id} = props.match.params;
        setTrackRecordCount(props.location.state.trackRecordCount || 0)

        RideTrackerService.fetchRideTrackerDetails(id,
            (rideDetails:any)=>{
                setRideDetails(rideDetails)
            },
            ()=>{},
            ()=>{setLoading(false)},
        ).then()

    },[]);

    return (
        <div className='user-component'>
            {loading && <GlobalLoader/>}
            {!loading &&
            <>
                <div className='landing-card'>
                    <div className='landing-card__content'>
                        <div className='landing-card__content-back'
                             onClick={()=>{props.history.goBack()}}>
                            <LeftCircleFilled />
                        </div>
                        <div className='landing-card__content-title'>{rideDetails?.shortName || '-'}</div>
                        <div className='landing-card__content-details'> {trackRecordCount} times</div>
                    </div>
                    <div className='landing-card__action'/>
                </div>
                <div className='ride-tracker'>
                    {rideDetails?.trackRecord?.map((track:any,i:number)=>
                        <div className='ride-tracker__year' key={i}>
                            <div className='ride-tracker__year__name'>{track?.year || 'NA'}</div>
                            {track?.rideHistory?.map((history:any,j:number)=>
                                <div className='ride-tracker__year__card' key={j}>
                                    <div className='ride-tracker__year__date'>
                                        {moment(history?.createdAt).format('MMMM Do, dddd')}
                                    </div>
                                    <Rate className='ride-tracker__year__rating'
                                          value={history?.rating || 0} disabled allowHalf/>
                                    <div className='ride-tracker__count ride-tracker__year__count'>
                                        {history?.count}
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </>}
        </div>
    )
}

export default RideTrackerDetails;
