import React, { useEffect, useState } from "react";
import "../groups.scss";
import { Button, Dropdown, Menu } from "antd";
import avatarPlaceholder from "../../../assets/avatar_placeholder.png";
import {
  LeftCircleFilled,
  EllipsisOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import * as appRoutes from "../../../routes/routeConstants/appRoutes";
import GroupService from "../../../services/GroupService/group.service";
import AddMembersForm from "../AddMemberForm";
import { DeleteConfirmation } from "../../../shared/components/DeleteConfirmation";
import GlobalLoader from "../../../shared/components/GlobalLoader";
import { ExistingGroupMembers } from "../ExistingGroupMembers";
import { GroupMemberFormModel } from "../../../models/group.model";
import Notification from "../../../shared/components/Notification";
import { NotificationTypes } from "../../../enums/notificationTypes";

const GroupDetails = (props: any) => {
  const [showForm, setShowForm] = useState<"existing" | "form" | "list">();
  const [groupDetails, setGroupDetails] = useState<any>();
  const [deleteVisible, setDeleteVisible] = useState<boolean>(false);
  const [memberId, setMemberId] = useState<string>("");
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentMember, setCurrentMember] = useState<any>();

  useEffect(() => {
    fetchGroupDetails();
  }, []);

  const fetchGroupDetails = () => {
    setLoading(true);
    GroupService.fetchGroupDetails(
      props.match.params.id,
      (group: any) => {
        setGroupDetails(group);
      },
      () => {},
      () => {
        setLoading(false);
      }
    ).then();
  };

  const handleMemberSuccess = () => {
    setShowForm("list");
    fetchGroupDetails();
  };

  const menu = (
    <Menu>
      <Menu.Item
        key="1"
        onClick={() => {
          if (currentMember?.memberUpdate) {
            setShowForm("form");
          } else {
            setCurrentMember(null)
            Notification({
              message: "Unable to edit",
              description: "You cannot edit onboarded member",
              type: NotificationTypes.ERROR,
            });
          }
        }}
      >
        <EditOutlined />
        Edit
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={() => {
          setDeleteVisible(true);
        }}
      >
        <DeleteOutlined />
        Delete
      </Menu.Item>
    </Menu>
  );

  const handleDelete = () => {
    setButtonLoading(true);
    GroupService.removeMember(
      props.match.params.id,
      memberId,
      () => {
        setButtonLoading(false);
        setDeleteVisible(false);
        fetchGroupDetails();
      },
      () => {
        setButtonLoading(false);
      },
      () => {}
    ).then();
  };

  return (
    <div className="user-component">
      {showForm == "form" ? (
        <AddMembersForm
          user={currentMember}
          setShowForm={setShowForm}
          groupId={props.match.params.id}
          handleSuccess={handleMemberSuccess}
        />
      ) : showForm == "existing" ? (
        <ExistingGroupMembers
          data={groupDetails}
          addHandler={() => setShowForm("form")}
          cancelHandler={() => setShowForm("list")}
        />
      ) : (
        <>
          <div className="landing-card">
            <div className="landing-card__content">
              <LeftCircleFilled
                className="landing-card__content-back"
                onClick={() => props.history.push(appRoutes.GROUP)}
              />
              <div className="landing-card__content-title">
                {groupDetails?.name || "-"}
              </div>
              <div className="landing-card__content-count">
                {groupDetails?.groupMemberCount} people
              </div>
            </div>
            <div className="landing-card__action">
              <Button
                type="primary"
                key="add"
                className="landing-card__action--button"
                onClick={() => setShowForm("existing")}
              >
                + Add New
              </Button>
            </div>
          </div>
          {loading && <GlobalLoader className="checklist-item-loader" />}
          {!loading && (
            <div className="manage-groups-users">
              <DeleteConfirmation
                deleteVisible={deleteVisible}
                setDeleteVisible={setDeleteVisible}
                text="member"
                buttonLoading={buttonLoading}
                handleDelete={handleDelete}
              />
              {groupDetails?.groupMembers?.map(
                (
                  member: {
                    id: string;
                    profilePicThumbUrl: string | undefined;
                    username: React.ReactNode;
                    userHeight: React.ReactNode;
                  },
                  i: number
                ) => (
                  <div className="manage-groups__invites" key={i}>
                    <img
                      src={member.profilePicThumbUrl || avatarPlaceholder}
                      alt=""
                      className="manage-groups__invites--image"
                    />
                    <div className="manage-groups__invites--user-details">
                      <div className="name">{member.username || "-"}</div>
                      <div className="description">
                        {member.userHeight || 0} inches
                      </div>
                    </div>
                    <Dropdown
                      overlay={menu}
                      placement="bottomCenter"
                      trigger={["click"]}
                      className="manage-groups__members--action"
                      onVisibleChange={(visible) => {
                        visible && setCurrentMember(member);
                        setMemberId(visible ? member.id : "");
                      }}
                    >
                      <EllipsisOutlined />
                    </Dropdown>
                  </div>
                )
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default GroupDetails;
