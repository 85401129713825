import React, { FC, useState } from "react";
import "./rideList.scss";
import { EnvironmentFilled, HistoryOutlined } from "@ant-design/icons";
import { Button } from "antd";
import "../../../assets/app-icons/style.css";
import ridePlaceHolder from "../../../assets/Base-1.jpg";
import {
  withRouter,
  generatePath,
  RouteComponentProps,
} from "react-router-dom";
import * as appRoutes from "../../../routes/routeConstants/appRoutes";
import PlanService from "../../../services/PlanService/plan.service";
import RideFastPass from "../RideFastPass";
import RideValidation from "../RideValidation";
import Notification from "../../../shared/components/Notification";
import { NotificationTypes } from "../../../enums/notificationTypes";
import SkeletonLoader from "../../../shared/components/SkeletonLoader";
import UpdatePlanItem from "../../../shared/components/UpdatePlanItem";

interface RidesListProps extends RouteComponentProps {
  rides: Array<any>;
  loading: boolean;
  successHandler: Function;
}

const RidesList: FC<RidesListProps> = (props) => {
  const { rides, loading, successHandler } = props;

  const [rideId, setRideId] = useState<string>("");
  const [ride, setRide] = useState<any>([]);
  const [showFastPass, setShowFastPass] = useState<boolean>(false);
  const [showValidation, setShowValidation] = useState<boolean>(false);
  const [validationResult, setValidationResult] = useState<any>();
  const [addLoading, setAddLoading] = useState<boolean>(false);
  console.log(rides);
  const handlePlanAdd = (ride: any) => {
    setRideId(ride.id);
    setRide(ride);
    setAddLoading(true);
    if (ride.fastpassOnly) {
      setShowFastPass(true);
    } else {
      let data = {
        plan_item: {
          attraction_type: "Ride",
          attraction_id: ride?.id,
        },
      };
      addRideToPlan(data);
    }
  };

  const addRideToPlan = (data: any) => {
    PlanService.addPlanItem(
      data,
      (planItem: any, validationResult: any, riderSwapAvailable: boolean) => {
        setShowFastPass(false);
        if (!data.hasOwnProperty("override") && validationResult) {
          setShowValidation(
            validationResult?.height?.status === false ||
              validationResult?.mobility?.status === false ||
              validationResult?.preference?.status === false
          );
          validationResult.riderSwapAvailable = riderSwapAvailable;
          setValidationResult(validationResult);
        }

        if (data.hasOwnProperty("override") || !validationResult) {
          refreshRideList(planItem, "id");
          setShowValidation(false);
          Notification({
            message: "Success",
            description: "Added successfully",
            type: NotificationTypes.SUCCESS,
          });
        }

        if (planItem) {
          refreshRideList(planItem, "id");
        }
      },
      () => {},
      () => {
        setAddLoading(false);
      }
    ).then();
  };

  const refreshRideList = (planItem: any, refreshType: string) => {
    if (refreshType === "id") {
      ride.planItemId = planItem?.id;
    }
    if (refreshType === "planName") {
      ride.defaultPlanName = planItem["plan_name"];
    }
    successHandler(ride);
  };

  const rideFastPassProps = {
    showModal: showFastPass,
    setShowModal: setShowFastPass,
    rideId: rideId,
    closeHandler: () => {
      setShowFastPass(false);
      setAddLoading(false);
    },
    addRideToPlan: addRideToPlan,
    validationResult: validationResult,
  };

  const rideValidationProps = {
    showModal: showValidation,
    setShowModal: setShowValidation,
    rideId: rideId,
    addRideToPlan: addRideToPlan,
    validationResult: validationResult,
  };

  const updatePlanItemProps = {
    planItemId: ride?.planItemId,
    refreshList: refreshRideList,
  };

  return (
    <div>
      <RideFastPass {...rideFastPassProps} />
      <RideValidation {...rideValidationProps} />
      {loading && <SkeletonLoader count={3} className="rides__card" />}
      {!loading &&
        rides.map((ride: any, i: number) => (
          <div
            className="rides__card"
            key={i}
            onClick={() => {
              props.history.push(
                generatePath(appRoutes.RIDE_DETAILS, { id: ride.id })
              );
            }}
          >
            <div className="rides__card__image">
              <img src={ride?.imageUrl || ridePlaceHolder} alt="" />
            </div>
            <div className="rides__card__content">
              <div className="rides__card__name">
                {ride?.shortName || "Not available"}
              </div>
              {/* <div className='rides__card__type'>
                            {ride?.rideType || 'NA'}
                        </div> */}
              <div className="rides__card__location">
                <EnvironmentFilled /> {ride?.parkName || "NA"}
              </div>
              <div className="rides__card__footer">
                <div className="rides__card__wait">
                  <HistoryOutlined />
                  {ride?.waitingStatus === "Operating" ? (
                    <span
                      className={`
                      ride-wait-time__card
                      ${
                        !ride?.currentWaitTime || !ride?.averageWaitTime
                          ? "ride-wait__blue"
                          : ride?.currentWaitTime > ride?.averageWaitTime
                          ? "ride-wait__red"
                          : "ride-wait__green"
                      }`}
                    >
                      {(ride?.averageWaitTime || ride?.currentWaitTime || 0) +
                        " mins wait"}
                    </span>
                  ) : (
                    <span>Temporarily closed</span>
                  )}
               
                </div>
                <div className="rides__card__action">
                  {ride?.planItemId ? (
                    <div
                      onClick={(event) => {
                        event.stopPropagation();
                        setRide(ride);
                      }}
                    >
                      <UpdatePlanItem {...updatePlanItemProps} />
                    </div>
                  ) : (
                    <Button
                      type="primary"
                      loading={addLoading && ride?.id === rideId}
                      onClick={(event) => {
                        handlePlanAdd(ride);
                        event.stopPropagation();
                      }}
                    >
                      Add
                    </Button>
                  )}
                </div>
                {ride?.planItemId && (
                  <div className="default-plan-info">
                    This ride is added to plan <b>{ride?.defaultPlanName}</b>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default withRouter(RidesList);
