import React, { FC, useEffect, useState } from "react";
import { Modal, Input, Button, TimePicker } from "antd";
import { CloseCircleFilled, ClockCircleFilled } from "@ant-design/icons";
import "./restaurantReservationForm.scss";
import moment from "moment";
import PlanService from "../../../../services/PlanService/plan.service";

interface RestaurantReservationFormProps {
  data: any;
  visible: boolean;
  handleOk: (payload: any) => void;
  closeHandler: () => void;
  loading: boolean;
}
export const RestaurantReservationForm: FC<RestaurantReservationFormProps> = ({
  visible,
  closeHandler,
  handleOk,
  data,
  loading = false,
}) => {
  const [time, setTime] = useState<any>("");
  const [haveReservation, setHaveReservation] = useState(true);
  const addReservation = () => {
    let payload: any = {
      attraction_type: "Restaurant",
      attraction_id: data?.id,
      fast_pass: haveReservation,
    };
    if (haveReservation) {
  
      payload["fast_pass_slot"] = moment(time)?.format();
    }
    handleOk(payload);

    // PlanService.addPlanItem(
    //   payload,
    //   () => {
    //       setButtonLoading(false)
    //   },
    //   () => {},
    //   () => {}
    // );
  };

  return (
    <Modal
      title={null}
      footer={null}
      visible={visible}
      centered
      closable={false}
      className="reservation-form__container"
      // maskStyle={{ backgroundColor: "rgba(0,0,0,0.98)" }}
    >
      <div className="reservation-form__content">
        <div className="reservation-form__title">
          Do you have a reservation for this restuarant?
        </div>
        <div className="reservation-have-btn__container">
          <Button
            className={!haveReservation ? "active__btn" : ""}
            onClick={() => setHaveReservation(false)}
          >
            No
          </Button>
          <Button
            className={haveReservation ? "active__btn" : ""}
            onClick={() => setHaveReservation(true)}
          >
            Yes
          </Button>
        </div>
        {haveReservation && (
          <div className="reservation-form__field">
            <div className="label">Select Time Slot</div>
            <TimePicker
              defaultValue={moment(moment(), "h:mm a")}
              format={"h:mm a"}
              value={time}
              onChange={(value) => {
                setTime(value)
              }}
              className="reservation-slot__timepicker"
              suffixIcon={<ClockCircleFilled />}
            />
          </div>
        )}

        <div className="reservation-form-btn__container">
          <Button
            className="form__save"
            onClick={addReservation}
            loading={loading}
            disabled={(haveReservation && !time)}
          >
            Ok
          </Button>
        </div>
        <div className="reservation-form-bottom__text">
          Many restaurants require reservations.
          <br /> To book a reservation, use your Disney app
          <br /> or call (407) 939-3463
        </div>
      </div>

      <CloseCircleFilled
        className="reservation-modal__close"
        onClick={closeHandler}
      />
    </Modal>
  );
};
