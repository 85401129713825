import React, {FC} from "react";
import {Skeleton} from "antd";

interface SkeletonLoaderProps{
    count:number
    className:string
}

const SkeletonLoader:FC<SkeletonLoaderProps> = (props) => <>
    {
        [...new Array(props.count)].map((value,i)=>
            <div className={props.className} key={i}>
                <Skeleton active/>
            </div>
        )
    }
    </>

export default SkeletonLoader;
