import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { setFastPass } from "../actions/ride";

export interface RideContainerProps{
    haveFastPass: boolean
    fastPassStartTime: string
    setFastPass: Function
}

const mapStateToProps = (state: any) => {
    return {
        haveFastPass: state.ride.haveFastPass,
        fastPassStartTime: state.ride.fastPassStartTime
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
    setFastPass
}, dispatch);

const RideContainer = (component: any) => {
    return connect(mapStateToProps, mapDispatchToProps)(component);
}

export default RideContainer;
