import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import {setUnAuthenticated, updateUser} from "../actions/auth";

const mapStateToProps = (state: any) => {
    return {
        authenticated: state.auth.authenticated,
        user: state.auth.user
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
    updateUser, setUnAuthenticated
}, dispatch);

const AuthContainer = (component: any) => {
    return connect(mapStateToProps, mapDispatchToProps)(component);
}

export default AuthContainer;
