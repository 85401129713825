import React, {useEffect, useState} from "react";
import './restaurants.scss';
import {Button} from "antd";
import '../../assets/app-icons/style.css';
import Search from "antd/es/input/Search";
import {LeftCircleFilled} from "@ant-design/icons";
import RestaurantService from "../../services/RestaurantService/restaurant.service";
import UpdateDefaultPlan from "../../shared/components/UpdateDefaultPlan";
import SearchService from "../../services/SearchService/search.service";
import AttractionFilter from "../SearchResults/AttractionsFilter";
import PreferredRestaurants from "./PreferredRestaurants";
import ParkRestaurants from "./ParkRestaurants";
import AuthContainer from "../../store/container/AuthContainer";

const Restaurants = (props:any) => {

    const {user} = props;

    const [preferredRestaurants, setPreferred] = useState<any>([]);
    const [showDrawer,setShowDrawer] = useState<boolean>(false);
    const [loading,setLoading] = useState<boolean>(false);

    useEffect(()=>{
        fetchPreferredRestaurants(null)
    },[]);

    const fetchPreferredRestaurants = (filterQuery:any) => {
        setLoading(true)
        RestaurantService.fetchRestaurants(user?.defaultLocationId,
            filterQuery,
            (restaurants:any)=>{
                setPreferred(restaurants)
            },
            ()=>{},
            ()=>{setLoading(false)},
        ).then()
    }

    const onSearch=(value:any)=>{
        if(value) {
            SearchService.searchAttractions(value,
                (rides: any, restaurants: any) => {
                    setPreferred(restaurants)
                },
                () => {},
                () => {},
            ).then()
        }else{
            fetchPreferredRestaurants(null)
        }
    };

    const preferredRestaurantProps = {
        preferredRestaurants: preferredRestaurants,
        loading: loading
    }

    const filterProps = {
        foodDisabled: false,
        rideDisabled: true,
        showDrawer: showDrawer,
        setShowDrawer: setShowDrawer,
        setRestaurantsList: setPreferred
    }

    const planProps = {
        refreshList: fetchPreferredRestaurants
    }

    return (
        <div className='user-component'>
            <AttractionFilter {...filterProps}/>
            <div className='search-results__header restaurant-list__header'>
                <div className='back-button'>
                    <LeftCircleFilled onClick={()=>{props.history.goBack()}}/>
                    <span className='back-button__title'>Restaurants</span>
                </div>
                <div className='search-results__search'>
                    <Search placeholder="Search" onSearch={onSearch} enterButton className='details__search'/>
                </div>
                <Button type='primary' className='filters-button'
                    onClick={()=>setShowDrawer(true)}>
                    <span className='icon-filter'/> Filters
                </Button>
            </div>
            <div className="preferred-restaurants">
                <div className="preferred-restaurants__title">Preferred restaurants</div>
                <UpdateDefaultPlan {...planProps}/>
                <PreferredRestaurants {...preferredRestaurantProps}/>
        </div>
           <ParkRestaurants/>
        </div>
    )
}

export default AuthContainer(Restaurants);
