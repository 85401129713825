import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import * as routes from "../../routes/routeConstants/appRoutes";
import LoginForm from "./LoginForm";
import SignUpForm from "./SignupForm";
import ForgotPasswordForm from "./ForgotPassword";
import ResetPasswordForm from "./ResetPassword";

const authRouter = () => {
    return (
        <Switch>
            <Redirect exact strict from={routes.AUTH} to={routes.LOGIN} />
            <Route exact path={routes.SIGNUP} component={() => <SignUpForm />} />
            <Route exact path={routes.LOGIN} component={() => <LoginForm />} />
            <Route exact path={routes.FORGOT_PASSWORD} component={() => <ForgotPasswordForm />} />
            <Route exact path={routes.RESET_PASSWORD} component={() => <ResetPasswordForm />} />
        </Switch>
    )
}

export default authRouter;
