import React, {FC} from 'react';
import './globalLoader.scss'
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

interface GlobalLoaderProps{
    className?: string | null
}

const GlobalLoader:FC<GlobalLoaderProps> = (props) =>
    <Spin indicator={
        <LoadingOutlined spin className={props.className? props.className+' global-loader-icon' :
             'global-loader-icon'}/>
        }
    />

export default GlobalLoader;
