import {Button, Checkbox, Dropdown, Input, Menu} from "antd";
import {EllipsisOutlined} from "@ant-design/icons";
import React, {FC, useState} from "react";
import {serialize} from "serializr";
import {SubHeadingsModel} from "../../../models/checklist.model";
import ChecklistService from "../../../services/ChecklistService/checklist.service";

interface ChecklistItemProps{
    subheading: any
    setSubheadingId: Function
    subheadingId: string
    checklistDetails: any
    refreshChecklistDetails: Function
    listLoading: boolean
}

const ChecklistItems:FC<ChecklistItemProps> = (props) => {
    const {subheading,setSubheadingId,subheadingId,
        checklistDetails,refreshChecklistDetails,listLoading} = props;

    const [itemName,setItemName] = useState<any>('')
    const [itemId, setItemId] = useState<string>('');
    const [showAddItemInput, setShowAddItemInput] = useState<boolean>(false);
    const [updateLoading, setUpdateLoading] = useState<boolean>(false);


    const updateSubheading = (subheadingId:any,name:any,items:any) => {
        setUpdateLoading(true)
        let subHeading = {
            name:name,
            items:items
        }
        let data={sub_heading: serialize(SubHeadingsModel,subHeading)}

        ChecklistService.updateChecklistSubheading(checklistDetails.id,subheadingId,data,
            ()=>{
                refreshChecklistDetails()
                setShowAddItemInput(false)
                setItemName('')
            },
            ()=>{},
            ()=>{
                setUpdateLoading(false)
                setSubheadingId('')
            },
        ).then()
    }


    const handleItemAction = (action:string) => {
        let currentSubheading = checklistDetails?.subHeadings?.filter((subheading:any) =>
            subheading.id === subheadingId);
        let currentSubheadingItems = currentSubheading.length > 0 ? currentSubheading[0].items : [];
        if(action === 'addItem'){
            currentSubheadingItems.push({name:itemName})
            updateSubheading(subheadingId,currentSubheading[0].name,currentSubheadingItems);
        }
        if(action === 'removeItem') {
            currentSubheadingItems = currentSubheadingItems.filter((item: any) => item.id !== itemId)
            updateSubheading(subheadingId, currentSubheading[0].name, currentSubheadingItems);
        }
    }

    const handleUpdateItem = (e:any,currentHeading:any,index:any) => {
        let currentSubheadingItems = currentHeading.items;
        currentSubheadingItems[index] = {
            name: currentSubheadingItems[index].name,
            isChecked: e.target.checked
        }
        updateSubheading(currentHeading.id,currentHeading.name,currentSubheadingItems);
    }

    const itemMenu = (
        <Menu>
            <Menu.Item key="1" onClick={()=>{handleItemAction('removeItem')}}>
                Remove
            </Menu.Item>
        </Menu>
    );

    return(
        <div>
            {subheading.items.map((item:any,j:number)=>
                <div className='checklist-subheading__card' key={j}>
                    <Checkbox checked={item.isChecked}
                              className={item.isChecked ?
                                  'checklist-subheading__value strike-through'
                                  : 'checklist-subheading__value' }
                              onChange={(e:any)=>{handleUpdateItem(e,subheading,j)}}
                    >
                        {item.name}
                    </Checkbox>
                    <Dropdown overlay={itemMenu}
                              placement="bottomCenter" trigger={['click']}
                              className='checklist-item__options'
                              onVisibleChange={(visible)=>{
                                  setSubheadingId(visible ? subheading.id : '');
                                  setItemId(visible ? item.id : '');
                              }}>
                        <EllipsisOutlined/>
                    </Dropdown>
                </div>
            )}
            <div className='checklist-subheading__action'>
                {!showAddItemInput && subheadingId !== subheading.id &&
                <Button type='primary' key='add-item'
                        onClick={()=>{setShowAddItemInput(true);setSubheadingId(subheading.id)}}>
                    Add an item
                </Button>}
                {showAddItemInput && subheadingId === subheading.id &&
                <>
                    <Input value={itemName} placeholder='Enter item name'
                           className='subheading-item-input'
                           onChange={(e:any)=>{setItemName(e.target.value)}}/>
                    <Button type='text' key='cancel-input' onClick={()=>{
                        setShowAddItemInput(false);setSubheadingId('');
                    }}>
                        Cancel
                    </Button>
                    <Button type='primary' key='add-input' loading={updateLoading}
                            onClick={()=>{handleItemAction('addItem')}}>
                        Add
                    </Button>
                </>}
            </div>
        </div>
    )
}

export default ChecklistItems;
