import React, { useEffect, useState } from "react";
import "./rideDetails.scss";
import { LeftCircleFilled } from "@ant-design/icons";
import { Button, Carousel, Divider } from "antd";
import "../../../assets/app-icons/style.css";
import PlanService from "../../../services/PlanService/plan.service";
import ridePlaceholder from "../../../assets/Base-1.jpg";
import PhotopassMap from "./PhotopassMap";
import RideService from "../../../services/RideService/ride.service";
import AttractionLocation from "../../../shared/components/AttractionLocation";
import Notification from "../../../shared/components/Notification";
import { NotificationTypes } from "../../../enums/notificationTypes";
import RideFastPass from "../RideFastPass";
import RideValidation from "../RideValidation";
import GlobalLoader from "../../../shared/components/GlobalLoader";
import UpdatePlanItem from "../../../shared/components/UpdatePlanItem";

const RideDetails = (props: any) => {
  const [ride, setRide] = useState<any>("");
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [showFastPass, setShowFastPass] = useState<boolean>(false);
  const [showValidation, setShowValidation] = useState<boolean>(false);
  const [validationResult, setValidationResult] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    const { id } = props.match.params;
    if (id) {
      RideService.fetchRideDetails(
        id,
        (data: any) => {
          setRide(data);
        },
        () => {},
        () => {
          setLoading(false);
        }
      ).then();
    }
  }, [props.match.params]);

  const handlePlanAdd = () => {
    if (ride.fastpassOnly) {
      setShowFastPass(true);
    } else {
      let data = {
        plan_item: {
          attraction_type: "Ride",
          attraction_id: ride?.id,
        },
      };
      addRideToPlan(data);
    }
  };

  const addRideToPlan = (data: any) => {
    setButtonLoading(true);
    PlanService.addPlanItem(
      data,
      (planItem: any, validationResult: any, riderSwapAvailable: boolean) => {
        setShowFastPass(false);
        if (!data.hasOwnProperty("override") && validationResult) {
          setShowValidation(
            validationResult?.height?.status === false ||
              validationResult?.mobility?.status === false ||
              validationResult?.preference?.status === false
          );
          validationResult.riderSwapAvailable = riderSwapAvailable;
          setValidationResult(validationResult);
        }

        if (data.hasOwnProperty("override") || !validationResult) {
          refreshRide(planItem, "id");
          setShowValidation(false);
          Notification({
            message: "Success",
            description: "Added successfully",
            type: NotificationTypes.SUCCESS,
          });
        }

        if (planItem) {
          refreshRide(planItem, "id");
        }
      },
      () => {},
      () => {
        setButtonLoading(false);
      }
    ).then();
  };

  const refreshRide = (planItem: any, refreshType: string) => {
    if (refreshType === "id") {
      ride.planItemId = planItem?.id;
    }
    if (refreshType === "planName") {
      ride.defaultPlanName = planItem["plan_name"];
    }
    setRide({ ...ride });
  };

  const rideFastPassProps = {
    showModal: showFastPass,
    setShowModal: setShowFastPass,
    rideId: ride.id,
    addRideToPlan: addRideToPlan,
    validationResult: validationResult,
  };

  const rideValidationProps = {
    showModal: showValidation,
    setShowModal: setShowValidation,
    rideId: ride.id,
    addRideToPlan: addRideToPlan,
    validationResult: validationResult,
  };

  const updatePlanItemProps = {
    planItemId: ride?.planItemId,
    refreshList: refreshRide,
  };

  return (
    <div className="user-component">
      <RideFastPass {...rideFastPassProps} />
      <RideValidation {...rideValidationProps} />
      {loading && <GlobalLoader />}
      {!loading && (
        <>
          <div className="ride-details">
            <div className="ride-details__basic">
              <div className="back-button">
                <LeftCircleFilled
                  onClick={() => {
                    props.history.goBack();
                  }}
                />
              </div>
              <div className="ride-details__header">
                <div className="ride-details__name">
                  {ride?.shortName || "NA"}
                </div>

                <div
                  className={`
                                    ride-details__wait
                                    ${
                                      !ride?.currentWaitTime ||
                                      !ride?.averageWaitTime
                                        ? "ride-wait__blue"
                                        : ride?.currentWaitTime >
                                          ride?.averageWaitTime
                                        ? "ride-wait__red"
                                        : "ride-wait__green"
                                    }`}
                >
                  {ride?.waitingStatus === "Operating" && (
                    <span className="icon-wait-time" />
                  )}
                  {ride?.waitingStatus === "Operating" ? (
                    <span>
                      {(ride?.averageWaitTime || ride?.currentWaitTime || 0) +
                        " mins wait"}
                    </span>
                  ) : (
                    <span>Temporarily closed</span>
                  )}
                </div>
              </div>
              <div className="ride-details__description">
                {ride?.whatItIs || ""}
              </div>
              <div className="ride-details__restrictions">
                <div className="ride-details__restriction">
                  <span className="icon-ride-time duration" />{" "}
                  {ride?.duration || "-"} mins ride
                </div>
                {ride?.heightRestriction && (
                  <div className="ride-details__restriction">
                    <span className="icon-height height" /> Height restriction
                  </div>
                )}
                <div className="ride-details__restriction">
                  <span className="icon-location park-name" />{" "}
                  {ride?.parkName || "-"}
                </div>
                {ride?.singleRider && (
                  <div className="ride-details__restriction">
                    <span className="icon-single-rider single" /> Single Rider
                  </div>
                )}
                {ride?.fastpassOnly && (
                  <div className="ride-details__restriction">
                    <span className="icon-fast-pass fastpass" /> FastPass
                    Available
                  </div>
                )}
                {ride?.riderSwap && (
                  <div className="ride-details__restriction">
                    <span className="icon-rider-swap swap" /> Rider Swap
                  </div>
                )}

                {(ride?.openEmhMorning || ride?.openEmhEvening) && (
                  <div className="ride-details__restriction">
                    <span className="icon-extra-magic-hours when" />
                    {ride?.openEmhMorning && "Morning "}
                    {ride?.openEmhMorning && ride?.openEmhEvening && "& "}
                    {ride?.openEmhEvening && "Evening"}
                  </div>
                )}
              </div>
              <div className="ride-details__action">
                {ride?.planItemId ? (
                  <div
                    onClick={(event) => {
                      event.stopPropagation();
                      setRide(ride);
                    }}
                  >
                    <UpdatePlanItem {...updatePlanItemProps} />
                  </div>
                ) : (
                  <Button
                    type="primary"
                    loading={buttonLoading}
                    onClick={() => handlePlanAdd()}
                  >
                    Add to plan
                  </Button>
                )}
              </div>
              {ride?.planItemId && (
                <div className="default-plan-info">
                  This ride is added to plan <b>{ride?.defaultPlanName}</b>
                </div>
              )}
            </div>
            <div className="ride-details__carousel">
              <Carousel>
                {ride?.attachments?.map((attachment: any, i: number) => (
                  <div key={i}>
                    <img src={attachment.imageUrl || ridePlaceholder} alt="" />
                  </div>
                ))}
                {ride?.attachments?.length === 0 && (
                  <div>
                    <img src={ridePlaceholder} alt="" />
                  </div>
                )}
              </Carousel>
            </div>
            <Divider />
          </div>

          {ride?.lat && ride?.long && (
            <AttractionLocation attraction={ride} showTitle={true} />
          )}

          {ride?.photopassDesc && <PhotopassMap ride={ride} />}

          {ride?.hiddenMickeyAssociated && (
            <div className="hidden-mickeys">
              <div className="hidden-mickeys__header">Hidden Mickeys</div>
              {ride?.hiddenMickeys.map((hiddenMickey: any, i: number) => (
                <div key={i}>
                  <div className="hidden-mickeys__content">
                    <div className="hidden-mickeys__title">
                      {hiddenMickey?.rideName}
                    </div>
                    <div className="hidden-mickeys__description">
                      {hiddenMickey?.desc}
                    </div>
                  </div>
                  <div className="hidden-mickeys__content image">
                    <img src={hiddenMickey?.imageUrl} alt="" />
                  </div>
                </div>
              ))}
              <Divider />
            </div>
          )}
          <div className="accessibility">
            {ride?.wheelChairTransferCode && (
              <>
                <div className="accessibility__header">Accessibility</div>
                <div className="accessibility__list">
                  <ul>
                    <li>{ride?.wheelChairTransferCode?.replace(/_/g, " ")}</li>
                  </ul>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default RideDetails;
