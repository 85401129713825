import {UNAUTHENTICATED, UPDATE_USER} from "../definitions/authConstants";

export const updateUser = (user:any) => {
    return {
        type: UPDATE_USER,
        payload: {
            user: user
        }
    }
}
export const setUnAuthenticated = () => {
    return {
        type: UNAUTHENTICATED,
        payload: {
            authenticated: false,
            user: null
        }
    }
}
