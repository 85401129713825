import axiosInstance from "../../interceptor/axiosInstance";
import {
  GROUP_MEMBERS,
  GROUPS,
  INVITES,
  GROUP_CONTACTS,
  USER,
  SINGLE_USER,
} from "../../routes/routeConstants/apiRoutes";
import { deserialize } from "serializr";
import {
  groupMembersModel,
  groupModel,
  invitesModel,
} from "../../models/group.model";
import { generatePath } from "react-router-dom";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";

export default class GroupService {
  //fetch groups
  static fetchGroups(
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    return axiosInstance
      .get(GROUPS)
      .then((response) => {
        console.log(response["data"]["groups"]);
        const groups = deserialize(groupModel, response.data["groups"]);
        onSuccess(groups);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(onFinal);
  }

  //group details
  static fetchGroupDetails(
    id: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    return axiosInstance
      .get(GROUPS + `/${id}`)
      .then((response) => {
        const group = deserialize(groupModel, response.data["group"]);
        onSuccess(group);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(onFinal);
  }

  //fetch sent and received invites
  static fetchInvites(
    type: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    return axiosInstance
      .get(INVITES + `?type=${type}`)
      .then((response) => {
        const invites = deserialize(invitesModel, response.data["invites"]);
        onSuccess(invites);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(onFinal);
  }

  //create new group
  static createGroup(
    data: any,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    return axiosInstance
      .post(GROUPS, data)
      .then((response) => {
        const group = deserialize(groupModel, response.data["group"]);
        Notification({
          message: "Success",
          description: "Group created successfully",
          type: NotificationTypes.SUCCESS,
        });
        onSuccess(group);
      })
      .catch((error) => {
        onError(error);
        Notification({
          message: "Failed",
          description: "Unable to create the group",
          type: NotificationTypes.ERROR,
        });
      })
      .finally(onFinal);
  }

  //add group member
  static addMember(
    data: any,
    groupId: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    return axiosInstance
      .post(generatePath(GROUP_MEMBERS, { groupId: groupId }), data)
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(onFinal);
  }

  //remove group member
  static removeMember(
    groupId: string,
    memberId: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    return axiosInstance
      .delete(
        generatePath(GROUP_MEMBERS, { groupId: groupId }) + `/${memberId}`
      )
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(onFinal);
  }
  //remove group member
  static updateMember(
    data: any,
    groupId: string,
    memberId: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    return axiosInstance
      .put(generatePath(SINGLE_USER, { id: memberId }), data)
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(onFinal);
  }

  //remove group
  static removeGroup(
    groupId: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    return axiosInstance
      .delete(GROUPS + `/${groupId}`)
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(onFinal);
  }

  //update invites
  static inviteUpdate(
    inviteId: string,
    data: any,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    return axiosInstance
      .put(INVITES + `/${inviteId}`, data)
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(onFinal);
  }

  static fetchExistingContacts(
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    return axiosInstance
      .get(GROUP_CONTACTS)
      .then((response) => {
        const contacts = deserialize(
          groupMembersModel,
          response.data["group_members"]
        );
        onSuccess(contacts);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(onFinal);
  }
}
