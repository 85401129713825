import React, { FC, useEffect, useState } from "react";
import { Modal, Input } from "antd";
import { CloseCircleFilled } from "@ant-design/icons";
import "./planEditForm.scss";
import Button from "antd/es/button";
import PlanService from "../../../../services/PlanService/plan.service";

interface PlanEditFormProps {
  visible: boolean;
  data: any;
  closeHandler: () => void;
  refreshList: () => void;
}
export const PlanEditForm: FC<PlanEditFormProps> = ({
  visible,
  data,
  closeHandler,
  refreshList,
}) => {
  const [planName, setPlanName] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  useEffect(() => {
    setPlanName(data?.name);
  }, [data]);

  const updatePlanDetails = () => {
    setButtonLoading(true);
    PlanService.updatePlan(
      data?.id,
      {
        name: planName,
      },
      () => {
        refreshList();
        closeHandler();
      },
      () => {},
      () => {
        setButtonLoading(false);
      }
    );
  };
  return (
    <Modal
      title={null}
      footer={null}
      visible={visible}
      centered
      closable={false}
      className="plan-edit-form__container"
    >
      <div className="plan-edit-form__content">
        <div className="plan-edit-form__title">My Plan</div>
        <div className="plan-edit-form__field">
          <div className="label">Name</div>
          <Input
            type="text"
            value={planName}
            placeholder="Enter plan name"
            onChange={(e) => setPlanName(e?.target?.value)}
          />
        </div>
        <div className="plan-edit-form-btn__container">
          <Button
            className="form__save"
            onClick={updatePlanDetails}
            disabled={!planName}
            loading={buttonLoading}
          >
            Save
          </Button>
          <Button className="form__cancel" onClick={closeHandler}>
            Cancel
          </Button>
        </div>
      </div>

      <CloseCircleFilled
        className="plan-edit-modal__close"
        onClick={closeHandler}
      />
    </Modal>
  );
};
