import axiosInstance from "../../interceptor/axiosInstance";
import {deserialize} from "serializr";
import {MetaModel} from "../../models/common.model";

export default class CommonService {

    //fetch meta
    static fetchMeta(
        metaName: string,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .get('/'+metaName)
            .then(response => {
                metaName = metaName.includes('?') ?
                    metaName.substr(0,metaName.indexOf('?')) : metaName; //if url has search string
                let metaData = deserialize(MetaModel,response.data[metaName])
                onSuccess(metaData);
            })
            .catch(error => {
                onError(error);
            })
            .finally(onFinal)
    }
}
