import React, { useEffect, useState } from "react";
import "./planOptions.scss";
import RidesList from "../../Rides/RidesList";
import RideService from "../../../services/RideService/ride.service";
import UpdateDefaultPlan from "../../../shared/components/UpdateDefaultPlan";
import RestaurantService from "../../../services/RestaurantService/restaurant.service";
import RestaurantsList from "../../Restaurants/RestaurantsList";
import { LeftCircleFilled } from "@ant-design/icons";
import AuthContainer from "../../../store/container/AuthContainer";

const PlanOptions = (props: any) => {
  const { type, id } = props.match.params;
  const { user } = props;

  const [rides, setRides] = useState<any>([]);
  const [restaurants, setRestaurants] = useState<any>([]);
  const [themeName, setThemeName] = useState<any>("");
  const [themeColor, setThemeColor] = useState<any>("");
  const [themeDesc, setThemeDesc] = useState<any>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);

  useEffect(() => {
    setThemeName(props.location?.state?.name);
    setThemeColor(props.location?.state?.colorCode);
    setThemeDesc(props.location?.state?.desc);

    let filterQuery = "";

    if (type === "theme") {
      filterQuery = `&theme_ids[]=${id}`;
    }
    if (type === "ride") {
      filterQuery = `&ride_type_ids[]=${id}`;
    }

    if (type === "theme" || type === "ride") {
      setLoading(true);
      RideService.fetchFilteredRides(
        user?.defaultLocationId,
        filterQuery,
        (rides: any) => {
          setRides(rides);
          setRestaurants([]);
        },
        () => {},
        () => {
          setLoading(false);
        }
      ).then();
    }

    if (type === "food") {
      setLoading(true);
      filterQuery =
        id === "1"
          ? "&notable_snack=true"
          : id === "2"
          ? "&wine_list=true"
          : id === "4"
          ? "&is_quick_service_available=true"
          : "";

      RestaurantService.fetchRestaurants(
        user?.defaultLocationId,
        filterQuery,
        (restaurants: any) => {
          setRides([]);
          setRestaurants(restaurants);
        },
        () => {},
        () => {
          setLoading(false);
        }
      ).then();
    }
  }, [reload]);

  const refreshList = () => setReload(!reload);

  const planProps = {
    refreshList: refreshList,
  };

  const rideSuccessHandler = (ride: any) => {
    setRides(rides.map((o: any) => (o.id === ride.id ? ride : o)));
  };

  const restaurantSuccessHandler = (restaurant: any) => {
    setRestaurants(
      restaurants.map((o: any) => (o.id === restaurant.id ? restaurant : o))
    );
  };

  return (
    <div className="user-component">
      <div
        className="landing-card"
        style={{ background: `linear-gradient(101deg,${themeColor})` }}
      >
        <div className="landing-card__content plan-categories__content">
          <LeftCircleFilled
            className="landing-card__content-back"
            onClick={() => props.history.goBack()}
          />
          <div className="landing-card__content-title">{themeName}</div>
          <div className="landing-card__content-count">{themeDesc}</div>
        </div>
      </div>
      <div className="results plan-options">
        <UpdateDefaultPlan {...planProps} />
        {(type === "ride" || type === "theme") && (
          <RidesList
            rides={rides}
            loading={loading}
            successHandler={rideSuccessHandler}
          />
        )}
        {type === "food" && (
          <RestaurantsList
            restaurants={restaurants}
            loading={loading}
            successHandler={restaurantSuccessHandler}
          />
        )}
      </div>
    </div>
  );
};

export default AuthContainer(PlanOptions);
