import React from "react";
import './maximizeTime.scss';
import maximizeTimeImage from "../../../assets/landing-page-assets/maximize_park_time.png";
import maximizeIcon1 from "../../../assets/landing-page-assets/feature2.png";
import maximizeIcon2 from "../../../assets/plans-ride-tracker.png";
import maximizeIcon3 from "../../../assets/plans-checklist.png";
import maximizeIcon4 from "../../../assets/landing-page-assets/feature4.png";
import maximizeIcon5 from "../../../assets/landing-page-assets/maximize5.png";

const MaximizeTime = () => {
    return(
        <div className='maximize-time'>
            <div className='maximize-time__section'>
                <img src={maximizeTimeImage} alt='' className='maximize-time__image'/>
            </div>
            <div className='maximize-time__section'>
                <div className='maximize-time__title'>Maximize your park time</div>
                <div className='maximize-time__content'>
                    The Best Disney Day app is designed to help you create the ultimate day in the parks!
                </div>
                <div className='maximize-time__cards'>
                    <div className='maximize-time__card'>
                        <div className='maximize-time__card__image'>
                            <img src={maximizeIcon1} alt='' className='maximize-time__icon1'/>
                        </div>
                        <div className='maximize-time__card__content'>
                            Easy to build plans for the perfect day in the parks!
                        </div>
                    </div>
                    <div className='maximize-time__card'>
                        <div className='maximize-time__card__image'>
                            <img src={maximizeIcon2} alt='' className='maximize-time__icon2'/>
                        </div>
                        <div className='maximize-time__card__content'>
                            Easily track and rate all the rides you go on!
                        </div>
                    </div>
                    <div className='maximize-time__card'>
                        <div className='maximize-time__card__image'>
                            <img src={maximizeIcon3} alt='' className='maximize-time__icon3'/>
                        </div>
                        <div className='maximize-time__card__content'>
                            Create checklists for what you need at the park!
                        </div>
                    </div>
                    <div className='maximize-time__card'>
                        <div className='maximize-time__card__image'>
                            <img src={maximizeIcon4} alt='' className='maximize-time__icon4'/>
                        </div>
                        <div className='maximize-time__card__content'>
                            Your day is automatically updated based on wait times and weather!
                        </div>
                    </div>
                    <div className='maximize-time__card'>
                        <div className='maximize-time__card__image'>
                            <img src={maximizeIcon5} alt='' className='maximize-time__icon5'/>
                        </div>
                        <div className='maximize-time__card__content'>
                            Entertainment while you wait with Jokes, Trivia, and more!
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MaximizeTime;
