import {serializable, alias, primitive, list, object} from 'serializr';
import {AttachmentsModel} from "./common.model";

class RideThemesModel{
    @serializable(alias('id', primitive()))
    id?: string;
    @serializable(alias('ride_id', primitive()))
    rideId?: string;
    @serializable(alias('theme_id', primitive()))
    themeId?: string;
    @serializable(alias('theme_icon_url', primitive()))
    themeIconUrl?: string;
    @serializable(alias('theme_name', primitive()))
    themeName?: string;
    @serializable(alias('theme_color_code', primitive()))
    themeColorCode?: string;
}

export class RidesListModel {
    @serializable(alias('id', primitive()))
    id?: string;
    @serializable(alias('short_name', primitive()))
    shortName?: string;
    @serializable(alias('park_name', primitive()))
    parkName?: string;
    @serializable(alias('location_name', primitive()))
    locationName?: string;
    @serializable(alias('average_wait_time', primitive()))
    averageWaitTime?: string;
    @serializable(alias('image_url', primitive()))
    imageUrl?: string;
    @serializable(alias('image_thumb_url', primitive()))
    imageThumbUrl?: string;
    @serializable(alias('fastpass_only', primitive()))
    fastpassOnly?: string;
    @serializable(alias('track_record_count', primitive()))
    trackRecordCount?: string;
    @serializable(alias('ride_theme_maps', list(object(RideThemesModel))))
    rideThemeMaps?: string;
    @serializable(alias('default_plan_name', primitive()))
    defaultPlanName?: string;
    @serializable(alias('plan_item_id', primitive()))
    planItemId?: string;
    @serializable(alias('park_icon_url', primitive()))
    parkIconUrl?: string;
    @serializable(alias('waiting_status', primitive()))
    waitingStatus?: string;
    @serializable(alias('current_wait_time', primitive()))
    currentWaitTime?: string;
}
export class RideMetaModel {
    @serializable(alias('id', primitive()))
    value?: string;
    @serializable(alias('shortName', primitive()))
    label?: string;
}

class HiddenMickeysModel{
    @serializable(alias('id', primitive()))
    id?: string;
    @serializable(alias('ride_name', primitive()))
    rideName?: string;
    @serializable(alias('park_name', primitive()))
    parkName?: string;
    @serializable(alias('location_name', primitive()))
    locationName?: string;
    @serializable(alias('lat', primitive()))
    lat?: number;
    @serializable(alias('long', primitive()))
    long?: number;
    @serializable(alias('desc', primitive()))
    desc?: string;
    @serializable(alias('image_url', primitive()))
    imageUrl?: string;
    @serializable(alias('image_thumb_url', primitive()))
    imageThumbUrl?: string;
}

class RideTypesModel{
    @serializable(alias('id', primitive()))
    id?: string;
    @serializable(alias('ride_id', primitive()))
    rideId?: string;
    @serializable(alias('ride_type_id', primitive()))
    rideTypeId?: string;
}

class RideHistoryModel{
    @serializable(alias('id', primitive()))
    id?: string;
    @serializable(alias('rating', primitive()))
    rating?: string;
    @serializable(alias('no_of_times', primitive()))
    count?: string;
    @serializable(alias('created_at', primitive()))
    createdAt?: string;
    @serializable(alias('year', primitive()))
    year?: string;
}

class TrackRecordModel{
    @serializable(alias('year', primitive()))
    year?: string;
    @serializable(alias('ride_history', list(object(RideHistoryModel))))
    rideHistory?: string;
}

export class RideDetailsModel {
    @serializable(alias('id', primitive()))
    id?: string;
    @serializable(alias('park_id', primitive()))
    parkId?: string;
    @serializable(alias('short_name', primitive()))
    shortName?: string;
    @serializable(alias('park_name', primitive()))
    parkName?: string;
    @serializable(alias('location_name', primitive()))
    locationName?: string;
    @serializable(alias('open_emh_morning', primitive()))
    openEmhMorning?: boolean;
    @serializable(alias('open_emh_evening', primitive()))
    openEmhEvening?: boolean;
    @serializable(alias('single_rider', primitive()))
    singleRider?: boolean;
    @serializable(alias('time_zone', primitive()))
    timeZone?: string;
    @serializable(alias('open_very_merry', primitive()))
    openVeryMerry?: boolean;
    @serializable(alias('open_not_so_scary', primitive()))
    openNotSoScary?: boolean;
    @serializable(alias('category_code', primitive()))
    categoryCode?: string;
    @serializable(alias('duration', primitive()))
    duration?: string;
    @serializable(alias('what_it_is', primitive()))
    whatItIs?: string;
    @serializable(alias('when_to_go', primitive()))
    whenToGo?: string;
    @serializable(alias('probable_wait_time', primitive()))
    probableWaitTime?: string;
    @serializable(alias('height_restriction', primitive()))
    heightRestriction?: string;
    @serializable(alias('wheelchair_transfer_code', primitive()))
    wheelChairTransferCode?: string;
    @serializable(alias('sign_language', primitive()))
    signLanguage?: boolean;
    @serializable(alias('rider_swap', primitive()))
    riderSwap?: boolean;
    @serializable(alias('fastpass_only', primitive()))
    fastpassOnly?: string;
    @serializable(alias('operator_type', primitive()))
    operatorType?: string;
    @serializable(alias('hidden_mickey_associated', primitive()))
    hiddenMickeyAssociated?: string;
    @serializable(alias('lat', primitive()))
    lat?: number;
    @serializable(alias('long', primitive()))
    long?: number;
    @serializable(alias('status', primitive()))
    status?: string;
    @serializable(alias('average_wait_time', primitive()))
    averageWaitTime?: string;
    @serializable(alias('photo_pass_desc', primitive()))
    photopassDesc?: string;
    @serializable(alias('photo_pass_thumb_url', primitive()))
    photopassThumbUrl?: string;
    @serializable(alias('attachments', list(object(AttachmentsModel))))
    attachments?: string;
    @serializable(alias('hidden_mickeys', list(object(HiddenMickeysModel))))
    hiddenMickeys?: string;
    @serializable(alias('ride_theme_maps', list(object(RideThemesModel))))
    rideThemeMaps?: string;
    @serializable(alias('ride_type_maps', list(object(RideTypesModel))))
    rideTypeMaps?: string;
    @serializable(alias('track_record', list(object(TrackRecordModel))))
    trackRecord?: string;
    @serializable(alias('default_plan_name', primitive()))
    defaultPlanName?: string;
    @serializable(alias('plan_item_id', primitive()))
    planItemId?: string;
    @serializable(alias('waiting_status', primitive()))
    waitingStatus?: string;
    @serializable(alias('current_wait_time', primitive()))
    currentWaitTime?: string;
}
