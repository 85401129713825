import React, {useEffect, useState} from "react";
import './checklistDetails.scss';
import {Dropdown, Menu} from "antd";
import '../../../assets/app-icons/style.css';
import {EllipsisOutlined} from "@ant-design/icons";
import ChecklistService from "../../../services/ChecklistService/checklist.service";
import ChecklistSubheadingsForm from "../ChecklistSubheadingForm";
import {DeleteConfirmation} from "../../../shared/components/DeleteConfirmation";
import ChecklistItems from "../ChecklistItemsList";
import LandingCard from "../../../shared/components/LandingCard";
import GlobalLoader from "../../../shared/components/GlobalLoader";

const ChecklistDetails = (props: any) => {

    const [checklistDetails,setChecklistDetails] = useState<any>('');
    const [checklistId,setChecklistId] = useState<string>('')
    const [showModal, setShowModal] = useState<boolean>(false);
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const [deleteVisible, setDeleteVisible] = useState<boolean>(false);
    const [subheadingId, setSubheadingId] = useState<string>('');
    const [listLoading,setListLoading] = useState<boolean>(false)

    useEffect(()=>{
        setChecklistId(props.match.params.id);
        refreshChecklistDetails()
    },[])

    const refreshChecklistDetails = () => {
        setListLoading(true)
        ChecklistService.getChecklistDetails(props.match.params.id,
            (checklist:any)=>{
                setChecklistDetails(checklist)
            },
            ()=>{},
            ()=>{setListLoading(false)},
        ).then()
    }


    const handleSubheadingDelete = () => {
        setButtonLoading(true)
        ChecklistService.deleteChecklistSubheading(checklistId,subheadingId,
            ()=>{
                refreshChecklistDetails()
                setSubheadingId('')
                setDeleteVisible(false)
            },
            ()=>{},
            ()=>{
                setButtonLoading(false)
            },
        ).then()
    }


    const subheadingMenu = (
        <Menu>
            <Menu.Item key="1" onClick={()=>{setDeleteVisible(true)}}>
                Remove
            </Menu.Item>
        </Menu>
    );

    return (
        <div className='user-component'>
            <DeleteConfirmation deleteVisible={deleteVisible} setDeleteVisible={setDeleteVisible}
                                buttonLoading={buttonLoading} text={'Subheading'}
                                handleDelete={handleSubheadingDelete}/>
            <ChecklistSubheadingsForm showModal={showModal} checklistId={checklistId}
                setShowModal={setShowModal} refreshChecklistDetails={refreshChecklistDetails}/>
            <LandingCard backButtonEnabled={true}
                         name={checklistDetails?.name}
                         desc={'Let us help you plan for what to bring to the parks. Make a check, check it twice, and then have a ton of fun!'}
                         landingCardAction={setShowModal}
            />
            {listLoading&&<GlobalLoader className='checklist-item-loader'/>}
            {!listLoading &&
                <div className='checklist-details'>
                {checklistDetails?.subHeadings?.map((subheading:any,i:number)=>
                <div className='checklist-subheading' key={i}>
                    <div>
                        <div className='checklist-subheading__title'>{subheading.name}</div>
                        <Dropdown overlay={subheadingMenu}
                                  placement="bottomCenter" trigger={['click']}
                                  className='checklist-subheading__options'
                                  onVisibleChange={(visible)=>{
                                      setSubheadingId(visible ? subheading.id : '');
                                  }}>
                            <EllipsisOutlined/>
                        </Dropdown>
                    </div>
                    <ChecklistItems subheading={subheading}
                                    setSubheadingId={setSubheadingId}
                                    subheadingId={subheadingId}
                                    checklistDetails={checklistDetails}
                                    refreshChecklistDetails={refreshChecklistDetails}
                                    listLoading={listLoading}
                    />
                </div>
                )}
            </div>
            }
        </div>
    )
}

export default ChecklistDetails;
