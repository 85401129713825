import React from "react";
import AuthRouter from "../authRouters";
import loginImageLogo from "../../../assets/bdd-logo.png";
import loginImageDisneyland from '../../../assets/bdd-disneyland.png'

const AuthWrapper = () => {
    return (
        <>
            <div className="login-image">
                <img src={loginImageLogo} alt='' className="login-image-logo"/>
                <img src={loginImageDisneyland} alt=''  className="login-image-disneyland"/>
            </div>
            <AuthRouter />
        </>
    )
}

export default AuthWrapper;
