import {CheckCircleFilled} from "@ant-design/icons";
import soloImage from "../../../../assets/solo.png";
import createImage from "../../../../assets/create-group.png";
import existingImage from "../../../../assets/existing-group.png";
import {Button, Input, Select} from "antd";
import React, {FC, useEffect, useState} from "react";
import GroupService from "../../../../services/GroupService/group.service";
import {constructSelectOptions} from "../../../../shared/utils/fetchMetaValues";

interface PlanFormGroupProps{
    setGroupId:Function
}

const PlanFormGroup:FC<PlanFormGroupProps> = (props) => {

    const {setGroupId} = props;

    const [newGroupName,setNewGroupName] = useState<string>('');
    const [createLoader,setCreateLoader] = useState<boolean>(false);
    const [groupOptions,setGroupOptions] = useState<any>([]);
    const [groupType,setGroupType] = useState<string>('solo')

    useEffect(()=>{
        GroupService.fetchGroups(
            (groups:any)=>{
                constructSelectOptions(groups,setGroupOptions)
            },
            ()=>{},
            ()=>{},
        ).then()
    },[])

    const handleGroupCreation = () => {
        if(newGroupName) {
            setCreateLoader(true)
            let data = {group: {name: newGroupName}};
            GroupService.createGroup(data,
                (group:any)=>{
                    setGroupId(group.id)
                },
                ()=>{},
                ()=>{setCreateLoader(false)},
            ).then()
        }
    }

    return(
        <div className='plan-form__field'>
            <div className='plan-form__label--bold'>Who's going?</div>
            <div>
                <div className={groupType === 'solo' ? 'plan-form__group-card active' : 'plan-form__group-card'}
                     onClick={()=>{setGroupType('solo');setGroupId('')}}>
                    {groupType === 'solo' && <CheckCircleFilled/>}
                    <img src={soloImage} alt=''/>
                    <div className='plan-form__group-card__name'>Solo</div>
                </div>
                <div className={groupType === 'create' ? 'plan-form__group-card active' : 'plan-form__group-card'}
                     onClick={()=>{setGroupType('create')}}>
                    {groupType === 'create' && <CheckCircleFilled/>}
                    <img src={createImage} alt=''/>
                    <div className='plan-form__group-card__name'>Create group</div>
                </div>
                <div className={groupType === 'exist' ? 'plan-form__group-card active' : 'plan-form__group-card'}
                     onClick={()=>{setGroupType('exist')}}>
                    {groupType === 'exist' && <CheckCircleFilled/>}
                    <img src={existingImage} alt=''/>
                    <div className='plan-form__group-card__name'>Existing group</div>
                </div>
            </div>
            {groupType === 'exist' &&
            <div className='plan-form-add__group-selector'>
                <Select options={groupOptions} onChange={(value:any)=>{setGroupId(value)}}
                        placeholder='Select an existing group'/>
            </div>}
            {groupType === 'create' &&
            <div className='plan-form__group-selector plan-form__creator'>
                <Input placeholder='New group name' className='plan-form__input'
                       onChange={e => {setNewGroupName(e.target.value)}}/>
                <Button type='primary' className='plan-form__create-button' loading={createLoader}
                        onClick={()=>{handleGroupCreation()}} disabled={!newGroupName}>
                    Create
                </Button>
            </div>}
        </div>
    )
}

export default PlanFormGroup;
