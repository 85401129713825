import React, { FC, useState } from "react";
import "./rideFastPass.scss";
import { CloseCircleFilled } from "@ant-design/icons";
import { Button, Modal, TimePicker } from "antd";
import moment from "moment";
import RideContainer, {
  RideContainerProps,
} from "../../../store/container/RideContainer";

interface RideFastPassProps {
  showModal: boolean;
  setShowModal: Function;
  rideId: string;
  addRideToPlan: Function;
  closeHandler: Function;
}

const RideFastPass: FC<RideFastPassProps & RideContainerProps> = (props) => {
  const { showModal, setShowModal, rideId, addRideToPlan, closeHandler } = props;
  const timeFormat = "h:mm a";
  const [time, setTime] = useState<any>(moment(moment(), timeFormat));
  const [haveFastPass, setHaveFastPass] = useState<boolean>(true);

  const handleAddFastPass = () => {
    let fastPassStart = time.format(timeFormat);
    let fastPassEnd = time.add(1, "hours").format(timeFormat);
    let data: { [k: string]: any } = {
      plan_item: {
        attraction_type: "Ride",
        attraction_id: rideId,
      },
    };
    if (haveFastPass) {
      data["plan_item"]["fast_pass_start_time"] = fastPassStart;
      data["plan_item"]["fast_pass_end_time"] = fastPassEnd;
    }
    props.setFastPass(haveFastPass, fastPassStart);
    addRideToPlan(data);
  };

  return (
    <Modal
      title={null}
      visible={showModal}
      footer={null}
      closable={false}
      className="new-group-modal"
    >
      <div className="ride-fast-pass__title">
        Do you have fast pass for this ride?
      </div>
      <div className="ride-fast-pass__selection">
        <Button
          type="text"
          className={haveFastPass ? "" : "active"}
          key="fast-pass-no"
          onClick={() => setHaveFastPass(false)}
        >
          No
        </Button>
        <Button
          type="text"
          className={haveFastPass ? "active" : ""}
          key="fast-pass-yes"
          onClick={() => setHaveFastPass(true)}
        >
          Yes
        </Button>
      </div>
      {haveFastPass && (
        <div className="ride-fast-pass__time-slot">
          <div className="ride-fast-pass__label">Select time slot</div>
          <TimePicker
            defaultValue={moment(moment(), timeFormat)}
            format={timeFormat}
            value={time}
            onChange={(value) => setTime(value)}
            className="ride-fast-pass__timepicker"
          />
        </div>
      )}
      <div className="ride-fast-pass__action">
        <Button
          type="primary"
          key="ride-fast-pass-ok"
          onClick={() => handleAddFastPass()}
        >
          Ok
        </Button>
      </div>
      <CloseCircleFilled
        className="new-group-modal__close"
        onClick={() => {
          closeHandler();
        }}
      />
    </Modal>
  );
};

export default RideContainer(RideFastPass);
