import axiosInstance from "../../interceptor/axiosInstance";
import {BLOGS} from "../../routes/routeConstants/apiRoutes";
import {deserialize} from "serializr";
import Notification from "../../shared/components/Notification";
import {NotificationTypes} from "../../enums/notificationTypes";
import {BlogsModel} from "../../models/blogs.model";

export default class BlogsService{
    static fetchBlogs(
        limit:any,
        tag:any,
        onSuccess:Function,
        onError:Function,
        onFinal:()=>void
    ){
        let URL = tag ? BLOGS+`?tag=${tag}` :
                  limit ? BLOGS+`?limit=${limit}` : BLOGS;
        return axiosInstance
            .get(URL)
            .then(response => {
                const blogs = deserialize(BlogsModel, response.data["blogs"]);
                onSuccess(blogs)
            })
            .catch(error => {
                Notification({
                    message: "Failed",
                    description: "Unable to fetch the blogs",
                    type: NotificationTypes.ERROR
                });
                onError(error);
            })
            .finally(onFinal)
    }

    static fetchBlogDetail(
        blogId:string,
        onSuccess:Function,
        onError:Function,
        onFinal:()=>void
    ){
        return axiosInstance
            .get(BLOGS+`/${blogId}`)
            .then(response => {
                const blog = deserialize(BlogsModel, response.data["blog"]);
                onSuccess(blog)
            })
            .catch(error => {
                Notification({
                    message: "Failed",
                    description: "Unable to fetch the blog details",
                    type: NotificationTypes.ERROR
                });
                onError(error);
            })
            .finally(onFinal)
    }
}
