import React, { useEffect, useState } from "react";
import "./home.scss";
import Search from "antd/es/input/Search";
import { Button, Select } from "antd";
import myPlansIcon from "../../assets/my-plans-icon.png";
import plansFoodIcon from "../../assets/plans-food.png";
import plansWeatherIcon from "../../assets/plans-weather.png";
import plansRideIcon from "../../assets/plans-ride-tracker.png";
import plansChecklistIcon from "../../assets/plans-checklist.png";
import plansHelpIcon from "../../assets/plans-help.png";
import {
  constructSelectOptions,
  fetchMetaValues,
} from "../../shared/utils/fetchMetaValues";
import * as appRoutes from "../../routes/routeConstants/appRoutes";
import moment from "moment";
import AuthContainer from "../../store/container/AuthContainer";
import GlobalLoader from "../../shared/components/GlobalLoader";
import { convertJSONToFormData } from "../../shared/utils/dataFormatConverter";
import UserService from "../../services/AuthService/auth.service";
import "../../assets/app-icons/style.css";
import RestaurantService from "../../services/RestaurantService/restaurant.service";
import SearchService from "../../services/SearchService/search.service";
import UpdateDefaultPlan from "../../shared/components/UpdateDefaultPlan";
import RidesList from "../Rides/RidesList";
import RestaurantsList from "../Restaurants/RestaurantsList";
import RideService from "../../services/RideService/ride.service";
import AttractionFilter from "../SearchResults/AttractionsFilter";
import rideTrackerIcon from "../../assets/animation/ride-tracker.json";
import checklistIcon from "../../assets/animation/checklist.json";
import foodIcon from "../../assets/animation/food.json";
import weatherIcon from "../../assets/animation/weather.json";
import helpIcon from "../../assets/animation/help.json";
import { LottieAnimation } from "../Landing/Features";
const Home = (props: any) => {
  const { user } = props;

  const [locations, setLocations] = useState<any>([]);
  const [locationsMeta, setLocationsMeta] = useState<any>([]);
  const [selectedLocation, setSelectedLocation] = useState<number>();
  const [userName, setUserName] = useState<any>("");
  const [greetings, setGreetings] = useState<any>("");
  const [preferredRestaurants, setPreferred] = useState<any>([]);
  const [rides, setRides] = useState<any>([]);
  const [restaurants, setRestaurants] = useState<any>([]);
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    fetchMetaValues("locations", setLocations);
    setSelectedLocation(props.user?.defaultLocationId);
    setUserName(props.user?.lastName);
    greeting();
    setLoading(false);
  }, []);

  useEffect(() => {
    let parkFilter = props.location?.state?.parkFilter || "";

    if (parkFilter) {
      let locationId = props.user.defaultLocationId;

      RestaurantService.fetchParks(
        locationId,
        (parks: any) => {
          if (parks.length > 0) {
            let filterQuery = `&park_id=${parks[0].id}`;
            RideService.fetchFilteredRides(
              locationId,
              filterQuery,
              (response: any) => {
                setRides(response);
              },
              () => {},
              () => {
                setLoading(false);
              }
            ).then();
          }
        },
        () => {},
        () => {}
      ).then();
    }
  }, []);

  useEffect(() => {
    constructSelectOptions(locations, setLocationsMeta);
  }, [locations]);

  const findAttractions = (searchText: string) => {
    setLoading(true);
    SearchService.searchAttractions(
      searchText,
      (rides: any, restaurants: any) => {
        setRides(rides);
        setRestaurants(restaurants);
      },
      () => {},
      () => {
        setLoading(false);
      }
    ).then();
  };

  const onSearch = (value: any) => {
    console.log(value);
    if (value) {
      props.history.push(appRoutes.SEARCH_RESULTS, { value: value });
    }
  };

  const onChange = (value: any) => {
    setSelectedLocation(value);

    let user = { user: { default_location_id: value } };
    let userFormData = convertJSONToFormData(user);

    UserService.userUpdate(
      props.user?.id,
      userFormData,
      () => {},
      () => {},
      () => {}
    ).then();
  };

  const preferredRestaurantProps = {
    preferredRestaurants: preferredRestaurants,
    loading: loading,
  };

  const filterProps = {
    showDrawer: showDrawer,
    setShowDrawer: setShowDrawer,
    rideDisabled: false,
    foodDisabled: false,
    setRestaurantsList: setRestaurants,
    setRidesList: setRides,
  };

  const planProps = {
    refreshList: findAttractions,
  };

  const rideSuccessHandler = (ride: any) => {
    setRides(rides.map((o: any) => (o.id === ride.id ? ride : o)));
  };

  const restaurantSuccessHandler = (restaurant: any) => {
    setRestaurants(
      restaurants.map((o: any) => (o.id === restaurant.id ? restaurant : o))
    );
  };

  const greeting = () => {
    const hour = moment().hour();

    setGreetings(
      hour > 16 ? "Good Evening" : hour > 11 ? "Good Afternoon" : "Good Morning"
    );
  };

  return (
    <>
      <div className="user-component">
        {loading && <GlobalLoader />}
        {!loading && (
          <>
            <AttractionFilter {...filterProps} />
            <div className="details">
              <div className="details__greetings">
                {greetings}
                {userName ? `, ${userName}` : " "}
              </div>
              <Select
                onChange={(value) => {
                  onChange(value);
                }}
                placeholder="Select location"
                value={selectedLocation}
                options={locationsMeta}
                className="details__location"
              />
              <Button
                type="primary"
                className="filters-button details__filter"
                onClick={() => {
                  props.history.push(appRoutes.SEARCH_RESULTS, {
                    isFilter: true,
                  });

                  //   setShowDrawer(true);
                }}
              >
                <span className="icon-filter" /> Filters
              </Button>
              <Search
                placeholder="Search"
                onSearch={onSearch}
                enterButton
                className="details__search"
              />
            </div>
            <div className="plans">
              <div className="plans__title">
                Let's plan your Best Disney Day!
              </div>
              <div className="card-bg">
                <div
                  className="plans__card--main"
                  onClick={() => props.history.push(appRoutes.PLANS)}
                >
                  {/* <div className='plans__card--main-title'>My Plans</div> */}
                  {/* <div className='plans__card--main-icon'>
                                <img src={myPlansIcon} alt=''/>
                            </div> */}
                  <div className="plans__card--main-action">
                    <Button
                      type="primary"
                      key="plan"
                      className="create-plan"
                      /*   */
                    >
                      Create your plan
                    </Button>
                  </div>
                </div>
              </div>
              <div
                className="card-bg__category"
                onClick={() => {
                  props.history.push(appRoutes.RESTAURANTS);
                }}
              >
                <div className="plans__card--category food">
                  <LottieAnimation data={foodIcon} />
                  {/* <img src={plansFoodIcon} alt="food" /> */}
                </div>
                <div className="plans__card--category-title">Food</div>
              </div>
              <div
                className="card-bg__category"
                onClick={() => props.history.push(appRoutes.WEATHER)}
              >
                <div className="plans__card--category weather-card">
                  <LottieAnimation data={weatherIcon} />

                  {/* <img src={plansWeatherIcon} alt="weather" /> */}
                </div>
                <div className="plans__card--category-title">Weather</div>
              </div>
              <div
                className="card-bg__category"
                onClick={() => props.history.push(appRoutes.RIDE_TRACKER)}
              >
                <div className="plans__card--category ride">
                  {/* <img src={plansRideIcon} alt="ride" /> */}
                  <LottieAnimation data={rideTrackerIcon} />
                </div>
                <div className="plans__card--category-title">Ride tracker</div>
              </div>
              <div
                className="card-bg__category"
                onClick={() => props.history.push(appRoutes.CHECKLISTS)}
              >
                <div className="plans__card--category checklist">
                  {/* <img src={plansChecklistIcon} alt="checklist" /> */}
                  <LottieAnimation data={checklistIcon} />
                </div>
                <div className="plans__card--category-title">Checklist</div>
              </div>
              <div
                className="card-bg__category"
                onClick={() =>
                  (window.location.href = "mailto:info@bestdisneyday.com")
                }
              >
                <div className="plans__card--category help">
                  <LottieAnimation data={helpIcon} />

                  {/* <img src={plansHelpIcon} alt="help" /> */}
                </div>
                <div className="plans__card--category-title">Help</div>
              </div>
            </div>

            {/* <div className="results">
              <div>
                <div className="results__title" />
                <UpdateDefaultPlan {...planProps} />
              </div>
              <div>
                {(loading || rides?.length > 0) && (
                  <div className="results__title">Rides</div>
                )}
                <RidesList
                  rides={rides}
                  loading={loading}
                  successHandler={rideSuccessHandler}
                />
              </div>
              <div>
                {(loading || restaurants?.length > 0) && (
                  <div className="results__title">Restaurants</div>
                )}
                <RestaurantsList
                  restaurants={restaurants}
                  loading={loading}
                  successHandler={restaurantSuccessHandler}
                />
              </div>
              {!loading && rides?.length === 0 && restaurants?.length === 0 && (
                <div className="results__title">No results found</div>
              )}
            </div> */}
          </>
        )}
      </div>
    </>
  );
};

export default AuthContainer(Home);
