import axiosInstance from "../../interceptor/axiosInstance";
import {
    APPLE_OAUTH,
    FB_OAUTH,
    GOOGLE_OAUTH,
    RESET_PASSWORD,
    USER,
    USER_LOGIN,
    USER_LOGOUT
} from "../../routes/routeConstants/apiRoutes";
import { deserialize } from "serializr";
import { User } from "../../models/user.model";
import { store } from "../../store";
import {AUTHENTICATED, UNAUTHENTICATED} from "../../store/definitions/authConstants";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";

export default class UserService {

    //user login
    static loginUser(
        data: Object,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .post(USER_LOGIN, data)
            .then(response => {
                const userDetails = deserialize(User, response.data["user"]);

                setLocalStorage(userDetails,response);

                onSuccess(userDetails)

                Notification({ message: "Login", description: "Logged in successfully", type: NotificationTypes.SUCCESS });
            })
            .catch(error => {
                Notification({ message: "Login failed", description: "incorrect email or password",type: NotificationTypes.ERROR });
                onError(error);
            })
            .finally(onFinal)
    }

    //user signup
    static signupUser(
        data: Object,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .post(USER, data)
            .then(response => {
                const userDetails = deserialize(User, response.data["user"]);

                setLocalStorage(userDetails,response);

                Notification({ message: "Signup", description: "Signup successful", type: NotificationTypes.SUCCESS });
                onSuccess(userDetails);
            })
            .catch(error => {
                Notification({ message: "Signup failed", description: "Invalid details",type: NotificationTypes.ERROR });
                onError(error);
            })
            .finally(onFinal)
    }

    //user logout
    static logoutUser(
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .delete(USER_LOGOUT)
            .then(response => {
                store.dispatch({
                    type: UNAUTHENTICATED,
                    payload: {
                        authenticated: false,
                        user: null
                    }
                })

                localStorage.removeItem('user');
                Notification({ message: "Logout", description: "You have successfully logged out", type: NotificationTypes.SUCCESS })
                onSuccess(response)
            })
            .catch(error => {
                Notification({ message: "Logout failed", description: "Unable to logout. Please try again",type: NotificationTypes.ERROR });
                onError(error);
            })
            .finally(onFinal)
    }

    //user forgot password
    static submitEmailForOtp(
        data: Object,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .post(RESET_PASSWORD, data)
            .then(response => {
                onSuccess(response);
            })
            .catch(error => {
                Notification({ message: "Submit failed", description: "Please try again",type: NotificationTypes.ERROR });
                onError(error);
            })
            .finally(onFinal)
    }

    //user reset password
    static resetPassword(
        data: Object,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .put(RESET_PASSWORD, data)
            .then(response => {
                onSuccess(response);
            })
            .catch(error => {
                Notification({ message: "Submit failed", description: "Please try again",type: NotificationTypes.ERROR });
                onError(error);
            })
            .finally(onFinal)
    }

    //user update
    static userUpdate(
        userId: string,
        data: Object,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .put(USER+`/${userId}`, data)
            .then(response => {
                const userDetails = deserialize(User, response.data["user"]);

                setLocalStorage(userDetails,response);

                onSuccess(userDetails);
            })
            .catch(error => {
                Notification({
                    message: "Update failed",
                    description: "Unable to update",
                    type: NotificationTypes.ERROR
                });
                onError(error);
            })
            .finally(onFinal)
    }

    //google oauth
    static googleOauth(
        data: Object,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .post(GOOGLE_OAUTH, data)
            .then(response => {
                const userDetails = deserialize(User, response.data["user"]);
                setLocalStorage(userDetails,response);
                onSuccess(userDetails);
            })
            .catch(error => {
                Notification({
                    message: "Auth failed",
                    description: "Unable to continue",
                    type: NotificationTypes.ERROR
                });
                onError(error);
            })
            .finally(onFinal)
    }

    //fb oauth
    static fbOauth(
        data: Object,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .post(FB_OAUTH, data)
            .then(response => {
                const userDetails = deserialize(User, response.data["user"]);
                setLocalStorage(userDetails,response);
                onSuccess(userDetails);
            })
            .catch(error => {
                Notification({
                    message: "Auth failed",
                    description: "Unable to continue",
                    type: NotificationTypes.ERROR
                });
                onError(error);
            })
            .finally(onFinal)
    }

    //apple oauth
    static appleOauth(
        data: Object,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .post(APPLE_OAUTH, data)
            .then(response => {
                const userDetails = deserialize(User, response.data["user"]);
                setLocalStorage(userDetails,response);
                onSuccess(userDetails);
            })
            .catch(error => {
                Notification({
                    message: "Auth failed",
                    description: "Unable to continue",
                    type: NotificationTypes.ERROR
                });
                onError(error);
            })
            .finally(onFinal)
    }
}


const setLocalStorage = (userDetails:any,response:any) =>{

    localStorage.removeItem('user');

    let user ={
        userDetails: userDetails,
        adminAuthToken: response.headers['access-token'],
        client: response.headers['client'],
        uid: response.headers['uid'],
        id: response.headers['id']
    };

    store.dispatch({
        type: AUTHENTICATED,
        payload: {
            authenticated: true,
            user: userDetails
        }
    })

    if(user) {
        localStorage.setItem('user', JSON.stringify(user));
    }
}
