import React, {useEffect, useState} from "react";
import {Button} from "antd";
import WeatherService from "../../../services/WeatherService/weather.service";
import moment from "moment";
import './planWeather.scss';
import * as appRoutes from "../../../routes/routeConstants/appRoutes";
import {withRouter} from "react-router-dom"

const PlanWeather = (props:any) =>{

    useEffect(()=>{
        WeatherService.fetchWeather(
            (response:any)=>{
                setTodayWeather(
                    response.filter((el:any)=>el.date === moment().format('YYYY-MM-DD'))[0]
                )
            },
            ()=>{},
            ()=>{}
        ).then()
    },[])

    const [todayWeather,setTodayWeather] = useState<any>('');

    return(
        <div className='plan-weather__drawer'>
            <div className='plan-weather'>
                <div className='plan-weather__section'>
                    <div className='plan-weather__title'>Today's Weather</div>
                    <div className='plan-weather__icon'>
                        <img src={todayWeather?.iconUrl || ''} alt=''/>
                    </div>
                    <div className='plan-weather__place'>{todayWeather?.locationName || '-'}</div>
                    <div className='plan-weather__date'>{moment().format("dddd - MMMM Do, YYYY")}</div>
                    <div className='plan-weather__details'>
                        <div className='plan-weather__detail temp'>
                            <span className='plan-weather__detail__data'>{todayWeather?.temp || '-'}</span>
                            <sup>.</sup>
                            <span className='plan-weather__detail__desc'>{todayWeather?.desc || '-'}</span>
                        </div>
                        <div className='plan-weather__detail'>
                            <div>Wind</div>
                            <div className='plan-weather__value'>{todayWeather?.windSpeed || '-'} mph</div>
                        </div>
                        <div className='plan-weather__detail'>
                            <div>Rain</div>
                            <div className='plan-weather__value'>{todayWeather?.rain || '-'} %</div>
                        </div>
                        <div className='plan-weather__indoor'>
                            <Button type='text' onClick={
                                ()=>props.history.push(appRoutes.RIDES_INDOOR)
                            }>
                                View list of indoor attractions
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='plan-weather'>
                <div className='plan-weather__action' onClick={
                    ()=>props.history.push(appRoutes.PLAN_CATEGORIES)
                }>
                    Let's create your Best Disney Day
                </div>
                <div className='plan-weather__action' onClick={
                    ()=>props.history.push(appRoutes.SEARCH_RESULTS, {parkFilter: 'filter'})
                }>
                    Explore the parks
                </div>
            </div>
        </div>
    )
}

export default withRouter(PlanWeather);
