import React, {useEffect, useState} from "react";
import '../../auth.scss';
import parkSelection from "../../../../assets/park-selection.png";
import * as appRoutes from "../../../../routes/routeConstants/appRoutes";
import {withRouter} from "react-router-dom";
import {convertJSONToFormData} from "../../../../shared/utils/dataFormatConverter";
import UserService from "../../../../services/AuthService/auth.service";
import {fetchMetaValues} from "../../../../shared/utils/fetchMetaValues";
import '../../../../assets/app-icons/style.css';
import ShowPendingInvite from "../ShowPendingInvites";
import GroupService from "../../../../services/GroupService/group.service";
import GlobalLoader from "../../../../shared/components/GlobalLoader";

const LocationSelection = (props: any) => {

    const {userId} = props;

    const [loading,setLoading] = useState<boolean>(false)

    useEffect(()=>{
        fetchMetaValues('locations',setLocations);
    },[]);


    const handleLocationSelection = (id: number) =>{

        setLoading(true)
        let user = {user:{default_location_id: id}}
        let userFormData = convertJSONToFormData(user);

        UserService.userUpdate(userId,userFormData,
            (user:any)=>{
                setLoading(false)
                GroupService.fetchInvites('received',
                    (invites:any)=>{
                        if(invites.length > 0){
                            setShowInviteModal(true)
                            setInvites(invites)
                            setUpdatedUser(user)
                        }else{
                            props.history.push(appRoutes.HOME,{user:user})
                        }
                    },
                    ()=>{},
                    ()=>{},
                ).then()
            },
            ()=>{setLoading(false)},
            ()=>{},
        ).then()
    }

    const [locations,setLocations] = useState<any>([]);
    const [showInviteModal,setShowInviteModal] = useState<boolean>(false);
    const [invites,setInvites] = useState<any>([]);
    const [updatedUser,setUpdatedUser] = useState<any>();

    return (
        <>
            {loading && <GlobalLoader/>}
            <ShowPendingInvite showModal={showInviteModal}
                               setShowModal={setShowInviteModal}
                               invites={invites}
                               user={updatedUser}
            />
            {!loading && <div className='park-selection'>
                <img src={parkSelection} alt='' className='park-selection__bg'/>
                <div className='park-selection__action'>
                    <div className='park-selection__action--title'>
                        <div>Almost done!!!</div>
                        <div>Select your theme park.</div>
                    </div>
                    <div className='park-selection__action--cards'>
                        {locations.map((location:any,i:number)=>
                            <div className='card' key={i}
                                 onClick={()=>{handleLocationSelection(location.id)}}>
                                <img src={location.iconUrl} alt='park location'/>
                                <div className='card__park-name'>{location.name}</div>
                            </div>
                        )}
                    </div>
                </div>
            </div>}
        </>
    )
}

export default withRouter(LocationSelection);
