import ConstructFilters from "../ConstructFilterOptions";
import { Button, Checkbox } from "antd";
import React, { FC, useEffect, useState } from "react";
import CommonService from "../../../../services/CommonService/common.service";
import { fetchMetaValues } from "../../../../shared/utils/fetchMetaValues";
import RestaurantService from "../../../../services/RestaurantService/restaurant.service";
import AuthContainer from "../../../../store/container/AuthContainer";
import { deserialize } from "serializr";
import { MetaModel } from "../../../../models/common.model";

interface RideFiltersProps {
  filterRides: Function;
  applyHandler: Function;
  closeHandler: Function;
  submitLoader: boolean;
}

interface AuthProps {
  user: any;
}

const RideFilters: FC<RideFiltersProps & AuthProps> = (props) => {
  const { filterRides, applyHandler, closeHandler, submitLoader, user } = props;

  //options to select
  const [themes, setThemes] = useState<any>([]);
  const [experiences, setExperiences] = useState<any>([]);
  const [rideTypes, setRideTypes] = useState<any>([]);
  const [mobilityOptions, setMobilityOptions] = useState<any>([]);
  const [parks, setParks] = useState<any>([]);

  //selected values
  const [selectedParks, setSelectedParks] = useState<any>([]);
  const [selectedThemes, setSelectedThemes] = useState<any>([]);
  const [selectedRideTypes, setSelectedRideTypes] = useState<any>([]);
  const [selectedMobility, setSelectedMobility] = useState<any>([]);

  useEffect(() => {
    CommonService.fetchMeta(
      "themes",
      (metaData: any) => {
        setThemes(metaData.filter((item: any) => item.category === "themes"));
        setExperiences(
          metaData.filter((item: any) => item.category === "experiences")
        );
      },
      () => {},
      () => {}
    ).then();

    fetchMetaValues("ride_types", setRideTypes);
    fetchMetaValues("mobility_types", setMobilityOptions);
    RestaurantService.fetchParks(
      user?.defaultLocationId,  
      (parks: any) => {
        let response = deserialize(MetaModel, parks)
        setParks(response);
      },
      () => {},
      () => {}
    ).then();
  }, []);

  const applyFilter = () => {
    let filterQuery = "";
    selectedThemes.map((theme: string) => {
      filterQuery = filterQuery + `&theme_ids[]=${theme}`;
    });
    selectedRideTypes.map((rideType: string) => {
      filterQuery = filterQuery + `&ride_type_ids[]=${rideType}`;
    });
    selectedMobility.map((mobility: string) => {
      filterQuery = filterQuery + `&wheelchair_transfer_codes[]=${mobility}`;
    });
    selectedParks.map((park: string) => {
      filterQuery = filterQuery + `&park_ids[]=${park}`;
    });
    // if (selectedPark !== null) {
    //   filterQuery = filterQuery + `&park_id=${selectedPark}`;
    // }
    filterRides(filterQuery);
  };

  const handleSingleSelection = (e: any, id: string, setState: Function) => {
    setState(e.target.checked ? id : null);
  };

  const resetFilters = () => {
    setSelectedThemes([]);
    setSelectedRideTypes([]);
    setSelectedMobility([]);
    setSelectedParks([]);
    // setSelectedPark(null);
    filterRides("");
  };

  return (
    <div>
      <div className="attraction-filter__block parks">
        <div className="attraction-filter__block__title">Parks</div>
        <ConstructFilters
          options={parks}
          selectedOptions={selectedParks}
          setSelectedOptions={setSelectedParks}
        />
        {/* {parks.map((park: any, i: number) => (
          <div className="attraction-filter__options" key={i}>
            <div>
              {park.icon_url ? (
                <img
                  src={park.icon_url}
                  className="attraction-filter__option-icon"
                ></img>
              ) : (
                <></>
              )}
              {park.name}
            </div>
            <span className="checkbox_space"></span>
            <Checkbox
              checked={park.id === selectedPark}
              onChange={(e: any) => {
                handleSingleSelection(e, park.id, setSelectedPark);
              }}
            ></Checkbox>
          </div>
        ))} */}
      </div>
      <div className="attraction-filter__block themes">
        <div className="attraction-filter__block__title">Themes</div>
        <ConstructFilters
          options={themes}
          selectedOptions={selectedThemes}
          setSelectedOptions={setSelectedThemes}
        />
      </div>
      <div className="attraction-filter__block themes">
        <div className="attraction-filter__block__title">Ride types</div>
        <ConstructFilters
          options={rideTypes}
          selectedOptions={selectedRideTypes}
          setSelectedOptions={setSelectedRideTypes}
        />
      </div>
      <div className="attraction-filter__block experiences">
        <div className="attraction-filter__block__title">Experiences</div>
        <ConstructFilters
          options={experiences}
          selectedOptions={selectedThemes}
          setSelectedOptions={setSelectedThemes}
        />
      </div>
      <div className="attraction-filter__block mobility">
        <div className="attraction-filter__block__title">Mobility</div>
        <ConstructFilters
          options={mobilityOptions}
          selectedOptions={selectedMobility}
          setSelectedOptions={setSelectedMobility}
        />
      </div>
      <div className="attraction-filter__actions">
        <Button
          type="text"
          key="clear-filter"
          onClick={() => {
            resetFilters();
          }}
          className="clear-filter__action"
        >
          Clear Filter
        </Button>
        <Button
          type="text"
          onClick={() => closeHandler()}
          key="ride-cancel"
        >
          Cancel
        </Button>
        <Button
          type="primary"
          loading={submitLoader}
          key="ride-apply"
          onClick={() => applyFilter()}
        >
          Apply
        </Button>
      </div>
    </div>
  );
};

export default AuthContainer(RideFilters);
