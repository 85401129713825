import RideService from "../../../services/RideService/ride.service";
import React, {useEffect, useState} from "react";
import RidesList from "../RidesList";
import AuthContainer from "../../../store/container/AuthContainer";
import './ridesIndoor.scss';
import {LeftCircleFilled} from "@ant-design/icons";
import * as appRoutes from "../../../routes/routeConstants/appRoutes";
import {withRouter} from "react-router-dom";
import UpdateDefaultPlan from "../../../shared/components/UpdateDefaultPlan";

const RidesIndoor = (props:any) =>{

    const [rides,setRides] = useState<any>([])
    const [loading,setLoading] = useState<boolean>(false)
    const [reload,setReload] = useState<boolean>(false)

    useEffect(()=>{
        setLoading(true)
        let locationId = props.user.defaultLocationId;
        let filterQuery = '&climate_controller=true';

        RideService.fetchFilteredRides(locationId,filterQuery,
            (response:any)=>{
                setRides(response)
            },
            ()=>{},
            ()=>{setLoading(false)},
        ).then()
    },[reload])

    const rideSuccessHandler = (ride:any) => {
        setRides(rides.map((o:any) => (o.id === ride.id) ? ride : o))
    }

    const refreshList = () => setReload(!reload)

    const planProps = {
        refreshList: refreshList
    }

    return(
        <div className='user-component'>
            <div className='indoor-rides'>
                <div className='back-button'>
                    <LeftCircleFilled  onClick={()=>{props.history.push(appRoutes.WEATHER)}}/>
                    <UpdateDefaultPlan {...planProps}/>
                </div>

                <RidesList rides={rides} loading={loading} successHandler={rideSuccessHandler}/>
            </div>
        </div>
    )
}

export default withRouter(AuthContainer(RidesIndoor));
