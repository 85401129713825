// Base URL
export const BASE_URL = process.env.REACT_APP_BASE_URL + "/api/v1";

// Login URL
export const USER = "/users";
export const USER_LOGIN = "/users/sign_in";
export const USER_LOGOUT = "/users/sign_out";
export const RESET_PASSWORD = "/passwords";
export const GOOGLE_OAUTH = '/users/omniauth/google';
export const FB_OAUTH = '/users/omniauth/facebook';
export const APPLE_OAUTH = '/users/omniauth/apple';

//Groups
export const GROUPS = '/groups';
export const GROUP_MEMBERS = '/groups/:groupId/group_members';
export const INVITES = '/invites';
export const GROUP_CONTACTS = "/group_contacts"

export const SINGLE_USER = USER + "/:id"
//plans
export const PLANS = '/plans';
export const PLAN_ITEMS= '/plan_items';

//crown calendar
export const CROWD_CALENDAR = '/crowd_calendars'

//rides
export const RIDES = '/rides';

//ride tracker
export const ACTUALS = '/actuals';
export const ACTUALS_TRACK = '/actuals/track_ride';
export const ADHOC_RIDES = ACTUALS + '/adhoc_ride'

//restaurants
export const RESTAURANTS = '/restaurants';

//parks
export const PARKS = '/parks';

//search
export const ATTRACTIONS = '/attractions';

//checklist
export const CHECKLISTS = '/checklists';
export const CHECKLIST_SUBHEADINGS = '/checklists/:id/sub_headings';
export const SUBHEADING_TEMPLATES = '/sub_heading_templates';

//weather
export const WEATHERS = '/weathers';

//rain gear shops
export const RAIN_GEAR_SHOPS = '/rain_gear_shops';

//blogs
export const BLOGS = '/blogs';
