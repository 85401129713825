import React, {FC, useEffect, useState} from "react";
import './rideValidation.scss';
import {CloseCircleFilled} from "@ant-design/icons";
import {Button, Modal} from "antd";
import moment from 'moment';
import RideContainer,{RideContainerProps} from "../../../store/container/RideContainer";
import avatarPlaceholder from "../../../assets/avatar_placeholder.png";

interface RideFastPassProps{
    showModal: boolean;
    setShowModal: Function;
    rideId: string;
    addRideToPlan: Function;
    validationResult: any;
}

const RideValidation:FC<RideFastPassProps & RideContainerProps> = (props) => {

    const {showModal,setShowModal,rideId,addRideToPlan,
        haveFastPass,fastPassStartTime,validationResult} = props;

    const [validationText,setValidationText] = useState<string>('');
    const [users,setUsers] = useState<any>([]);
    const [riderSwap,setRiderSwap] = useState<boolean>(false);
    const [showRiderSwap,setShowRiderSwap] = useState<boolean>(false);

    useEffect(()=>{
        let validationText = '';
        if(validationResult?.height?.status === false){
            validationText = 'height'
        }
        if(validationResult?.mobility?.status === false){
            validationText = validationText.length > 0 ? validationText + ' and mobility' : 'mobility'
        }
        if(validationResult?.preference?.status === false){
            validationText = validationText.length > 0 ? validationText + ' and preferences' : 'preferences'
        }

        setValidationText(validationText)

        let users: any[] = [];

        if(validationResult?.height?.status === false){
            users = [...validationResult?.height?.users]
        }
        if(validationResult?.mobility?.status === false){
            users = [...validationResult?.mobility?.users]
        }
        if(validationResult?.preference?.status === false){
            users = [...validationResult?.preference?.users]
        }

        setUsers(users)

        setRiderSwap(validationResult?.riderSwapAvailable)
    },[validationResult])

    const handleOverride = () =>{
        let data:{[k: string]: any} = {
            plan_item: {
                attraction_type: "Ride",
                attraction_id: rideId,
                override: true
            }
        }
        if(haveFastPass) {
            data['plan_item']['fast_pass_start_time'] = fastPassStartTime
            data['plan_item']['fast_pass_end_time'] = (moment(fastPassStartTime,'HH:mm')
                .add(1,'hours')).format('HH:mm')
        }
        addRideToPlan(data)
    }

    const handleRiderSwap = () => {
        setRiderSwap(false)
        setShowRiderSwap(true)
    }

    return (
        <Modal
            title={null}
            visible={showModal}
            footer={null}
            closable={false}
            className='new-group-modal'
        >
            <div className='ride-validation__members'>
                {users.map((user:any,i:number)=>
                    <img src={user?.profilePicThumbUrl || avatarPlaceholder} alt='' key={i}/>
                )}
            </div>
            {!showRiderSwap && <div className='ride-validation__content'>
                It looks like people in your group might have a hard time getting onto
                this ride based on their {validationText}. The above people might like to find an
                alternate ride at this time or want to sit this one out.
            </div>}
            {showRiderSwap && <div className='ride-validation__content'>
                Rider Swap means adults can take turns waiting with youngsters or people
                in your group who are unable or not wanting to ride. Visit a cast member at the
                ride entrance to ask if you can Rider Swap. The above people might like to find
                an alternate ride at this time or want to sit this one out.
            </div>}
            <div className='ride-fast-pass__action'>
                <Button type='primary' key='ride-validation-override'
                        onClick={()=>handleOverride()}
                >
                    Add to Plan
                </Button>
            </div>
            {riderSwap && <div className='ride-fast-pass__action'>
                <Button type='text' key='rider-swap-info' className='ride-fast-pass__swap'
                        onClick={()=>handleRiderSwap()}
                >
                    Rider Swap
                </Button>
            </div>}
            <div className='ride-fast-pass__action'>
                <Button type='text' key='ride-validation-alternate' className='alternate-button'
                    onClick={()=>setShowModal(false)}>
                    Find Alternate Plans
                </Button>
            </div>
            <CloseCircleFilled className='new-group-modal__close' onClick={()=>setShowModal(false)}/>
        </Modal>
    )
}

export default RideContainer(RideValidation);
